import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import { Error } from "./modules/Error";
import { Toast } from "./modules/Toast";
import { Drawer } from "./modules/Drawer";

/* API */
import { CmnMst } from "./modules/api/CmnMst.js";
import { Login } from "./modules/api/Login";
import { Sites } from "./modules/api/Sites.js";
import { GlobalHeader } from './modules/GlobalHeader.js';
import { Company } from "./modules/api/Company.js";
import { Users } from "./modules/api/Users.js";
import { Machines } from "./modules/api/Machines";
import { FieldUser } from "./modules/api/FieldUser";
import { MachineSites } from "./modules/api/MachineSites";
import { Inspections } from "./modules/api/Inspections.js";
import { File } from "./modules/File";

Vue.use(Vuex);

export const Store = new Vuex.Store({
  modules: {
    Error,
    Toast,
    Drawer,
    CmnMst,
    Login,
    Sites,
    GlobalHeader,
    Company,
    Users,
    Machines,
    FieldUser,
    MachineSites,
    Inspections,
    File,
  },
  strict: true,
  plugins: [
    createPersistedState({
      key: "GRN",
      paths: ["Login.api_token"],
      storage: window.sessionStorage,
    }),
    createPersistedState({
      key: "COMPANY_USER",
      paths: ["Login.company_user"],
      storage: window.sessionStorage,
    }),
  ],
});
