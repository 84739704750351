import Api, { Mock } from "../api";
import mock_inspections_result from "./mock/inspections.json";
import mock_inspections_other_result from "./mock/inspections_other.json";
import mock_inspection_results_by_ids from "./mock/inspections_by_ids.json";
import mock_result_detail from "./mock/mock_result_detail.json";

const INSPECTION_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/inspections`; 
const GET_LIST_INSPECTION_RESULTS_URL = `${INSPECTION_BASE_URL}/get_list_Inspection_results`;
const GET_LIST_INSPECTION_OTHER_RESULTS_URL = `${INSPECTION_BASE_URL}/get_list_Inspection_results_for_leading`;
const GET_LIST_INSPECTION_RESULTS_BY_IDS_URL = `${INSPECTION_BASE_URL}/get_inspection_result_by_ids`;
const RESULT_DETAIL_URL = `${INSPECTION_BASE_URL}/get_inspection_result_detail`;
const UPDATE_STATUS_BY_GROUP_URL = `${INSPECTION_BASE_URL}/update_status_by_group`;
const APPROVAL_SERIES_URL = `${INSPECTION_BASE_URL}/approval_series`;
const APPROVAL_LEAD_URL = `${INSPECTION_BASE_URL}/add_lead_approved`;
const DELETE_INSPECTOR_URL = `${INSPECTION_BASE_URL}/delete_inspection_results`;

if (Mock) {
  Mock.onGet(GET_LIST_INSPECTION_RESULTS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_inspections_result,
  });
  
  Mock.onGet(GET_LIST_INSPECTION_OTHER_RESULTS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_inspections_other_result,
  });
  
  Mock.onGet(GET_LIST_INSPECTION_RESULTS_BY_IDS_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_inspection_results_by_ids,
  });

  Mock.onGet(new RegExp(`${RESULT_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_result_detail,
  });

  Mock.onPost(UPDATE_STATUS_BY_GROUP_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });

  Mock.onPost(APPROVAL_SERIES_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(DELETE_INSPECTOR_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const inspections = {
  getResultDetail:(params) =>{
    return Api.get(RESULT_DETAIL_URL, { params });
  },
  get: (params) => {
    return Api.get(GET_LIST_INSPECTION_RESULTS_URL, { params });
  },
  getListOtherCompany: (params) => {
    return Api.get(GET_LIST_INSPECTION_OTHER_RESULTS_URL, { params });
  },
  getByIds: (params) => {
    return Api.get(GET_LIST_INSPECTION_RESULTS_BY_IDS_URL, { params });
  },
  updateStatusByGroup: (params) => {
    return Api.post(UPDATE_STATUS_BY_GROUP_URL, params);
  },
  approvalSeries: (params) => {
    return Api.post(APPROVAL_SERIES_URL, params);
  },
  addLeadApproved: (params) => {
    return Api.post(APPROVAL_LEAD_URL, params);
  },
  deleteInspector: (params) => {
    return Api.post(DELETE_INSPECTOR_URL, params);
  }
};
