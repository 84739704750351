<template>
    <div id="table-1">
        <template v-for="page in compRows">
          <div class="container-fluid spacer-large page-item">
            <div class="row">
              <div class="col-lg-6">
                <div class="p-title d-flex">
                  <div class="col-md-2 pr-0">
                    <h3>NO {{ idMapping.majorClassificationId }}-{{ idMapping.middleClassificationId }}</h3>
                  </div>
                  <div  class="col-md-10 pl-0 pr-0 text-center">
                    <h3>{{ relateText(objectMapping.middleTitle, 22) }}作業開始前点検表</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 padding-title">
                    <table class="w-100 spacer-large">
                      <tr>
                        <td class="text-center w-50 p-text">点検者名</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center w-50 p-text">機械所有者名</td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-6 padding-title">
                    <table class="w-100 spacer-large">
                      <tr>
                        <td class="text-center w-50 p-text">型式能力</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center w-50 p-text">持込機械受理番号</td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row spacer-large separate">
                  <div class="col-md-6 padding-title">
                    <p>取扱注意事項</p>
                    <ol>
                      <li v-for="(item, key) in listPrecaution" v-if="key < 10">
                        <div class="text-li">
                          <span>{{ item.precautions }}</span>
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div class="col-md-6 padding-title">
                    <table class="w-60 ml-auto">
                      <tr>
                        <td class="p-text w-50">
                          機電管理
                          責任者印
                        </td>
                        <td></td>
                      </tr>
                    </table>
                    <p>調整・修理記録</p>
                    <div class="box"></div>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 image-container">
                <img v-bind:src="viewImage" alt="" class="image-view">
              </div>
            </div>

            <div class="table">
              <table class="spacer-large w-100">
                <thead>
                <tr>
                  <th colspan="3" class="cross"></th>
                  <th v-bind:colspan="getCurrentDay"><br></th>
                </tr>
                <tr>
                  <th>NO</th>
                  <th>点 検 事 項</th>
                  <th>主   眼   点</th>
                  <th v-for="day in getCurrentDay">{{ day }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in page">
                  <td class="text-center">{{ item.stt }}</td>
                  <td class="text-td">
                    <div class="text-td">
                      <span>{{ item.inspection_item }}</span>
                    </div>
                  </td>
                  <td class="text-td">
                    <div class="text-td">
                      <span>{{ item.main_point }}</span>
                    </div>
                  </td>
                  <td v-for="day in getCurrentDay"></td>
                </tr>
                <tr>
                  <td rowspan="2" colspan="2">点 検 実 施 状 況</td>
                  <td >点検担当者</td>
                  <td v-for="day in getCurrentDay"></td>
                </tr>
                <tr>
                  <td >承認担当者</td>
                  <td v-for="day in getCurrentDay"></td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="fw-bold mb-0 text-bold">作業開始前点検の結果は次の記号で記入する＿＿良好＿＿レ＿＿調整及び要修理＿＿×＿＿調整または補修したとき＿＿○＿＿該当なし</p>
          </div>
        </template>
        <template v-if="compRows.length == 0">
          <div class="container-fluid spacer-large page-item">
            <div class="row">
              <div class="col-lg-6">
                <div class="p-title d-flex">
                  <div class="col-md-2">
                    <h3>NO {{ idMapping.majorClassificationId }}-{{ idMapping.middleClassificationId }}</h3>
                  </div>
                  <div  class="col-md-10 text-center">
                    <h3>{{ relateText(objectMapping.middleTitle, 22) }}作業開始前点検表</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 padding-title">
                    <table class="w-100 spacer-large">
                      <tr>
                        <td class="text-center w-50 p-text">点  検  者  名</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center w-50 p-text">機械所有者名</td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-md-6 padding-title">
                    <table class="w-100 spacer-large">
                      <tr>
                        <td class="text-center w-50 p-text">型 式 能 力</td>
                        <td></td>
                      </tr>
                      <tr>
                        <td class="text-center w-50 p-text">持込機械受理番号</td>
                        <td></td>
                      </tr>
                    </table>
                  </div>
                </div>

                <div class="row spacer-large separate">
                  <div class="col-md-6 padding-title">
                    <p>取扱注意事項</p>
                    <ol>
                      <li v-for="(item, key) in listPrecaution" v-if="key < 10">
                        <div class="text-li">
                          <span>{{ item.precautions }}</span>
                        </div>
                      </li>
                    </ol>
                  </div>
                  <div class="col-md-6 padding-title">
                    <table class="w-60 ml-auto">
                      <tr>
                        <td class="p-text w-50">
                          機電管理
                          責任者印
                        </td>
                        <td></td>
                      </tr>
                    </table>
                    <p>調整・修理記録</p>
                    <div class="box"></div>
                  </div>
                </div>

              </div>
              <div class="col-lg-6 image-container">
                <img v-bind:src="viewImage" alt="" class="image-view">
              </div>
            </div>

            <div class="table">
              <table class="spacer-large w-100">
                <thead>
                <tr>
                  <th colspan="3" class="cross"></th>
                  <th v-bind:colspan="getCurrentDay"><br></th>
                </tr>
                <tr>
                  <th>NO</th>
                  <th>点 検 事 項</th>
                  <th>主   眼   点</th>
                  <th v-for="day in getCurrentDay">{{ day }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="i in 12">
                  <td class="text-center">
                    {{ i }}
                  </td>
                  <td class="text-td">
                    <br>
                  </td>
                  <td class="text-td">
                    <br>
                  </td>
                  <td v-for="day in getCurrentDay"></td>
                </tr>
                <tr>
                  <td rowspan="2" colspan="2">点 検 実 施 状 況</td>
                  <td >点検担当者</td>
                  <td v-for="day in getCurrentDay"></td>
                </tr>
                <tr>
                  <td >承認担当者</td>
                  <td v-for="day in getCurrentDay"></td>
                </tr>
                </tbody>
              </table>
            </div>
            <p class="fw-bold mb-0 text-bold">作業開始前点検の結果は次の記号で記入する＿＿良好＿＿レ＿＿調整及び要修理＿＿×＿＿調整または補修したとき＿＿○＿＿該当なし</p>
          </div>
        </template>
    </div>
</template>

<script>
  export default {
    name: "TemplateTableOne",
    props: {
        imageUrl: {
            type: String,
            default: null
        },
        listDailyItem: {
            type: Array,
            default: []
        },
        listPrecaution: {
            type: Array,
            default: []
        },
        objectMapping: {
            type: Object,
            default: null
        },
        idMapping: {
          type: Object,
          default: null
        },
    },
    mounted() {
      this.$nextTick(()=>{
        this.resize('text-td');
        this.resize('text-li');
      })
    },
    computed: {
        viewImage(){
            let beUrl = process.env.VUE_APP_API_BASE_URL;
            let currentIndex = beUrl.lastIndexOf("/api");
            return beUrl.substring(0, currentIndex) + this.imageUrl;
        },
        getCurrentDay(){
            return 30;
        },
        compRows() {
          let limit = 12;
          let index = 0;
          let grouped = [];
          let arr = [];
          let data = this.dataTran();
          data.forEach(value => {
            arr.push(value);
            index ++;
            if (index === limit){
              index = 0;
              grouped.push(arr);
              arr = [];
            }
          });
          if (arr.length > 0){
            let row = limit - arr.length;
            let count = data.length + 1;
            for (let index = 0; index < row; index++) {
              arr.push({
                stt: count ++,
                inspection_item_id: null,
                inspection_item: null,
                main_point_id: null,
                main_point: null,
              })
            }
            grouped.push(arr);
          }
          return grouped;
        },
    },
    methods: {
        relateText(str, length = 30){
          if (str.length <= length){
            return str;
          }
          str = str.slice(0, length);
          return `${str}...`
        },
        resize(className) {
          let tds = document.querySelectorAll(`.${className}`);
          tds.forEach((td) => {
            let span = td.querySelector('span');
            while (span.offsetWidth > td.offsetWidth) {
              let currentFontSize = window.getComputedStyle(span).fontSize;
              currentFontSize = parseInt(currentFontSize.replace("px", ""));
              td.querySelector('span').style.fontSize = `${currentFontSize - 1}px`;
            }
          });
        },
        getDays(year, month){
            return new Date(year, month, 0).getDate();
        },
        dataTran() {
          let index = 1;
          const grouped = this.listDailyItem.map((item) => {
            item['stt'] = index ++;
            return item;
          });
          return grouped;
        }
    }
  };
</script>

<style type="text/css" scoped>
    .f-22{
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 10px;
    }
    .f-20 {
      font-size: 20px;
      font-weight: 400;
      stroke: 1px;
    }
    .f-18 {
      font-size: 14px;
      font-weight: 400;
    }
    .table tr{
      height: 25px;
    }
    .table th {
      font-weight: 400;
    }
    .text-bold{
      font-weight: bold;
      text-align: right;
      padding-right: 60px;
      font-size: 11px;
    }
    .image-container{
      display: flex;
      align-items: center;
    }
    .image-container .image-view{
      max-height: 360px;
      margin: 0 auto;
    }
    .padding-title{
      padding: 12px;
    }
    .p-text{
      padding: 10px;
    }
    .w-50{
      width: 50%;
    }
    .text-td {
      width: 150px;
      overflow: hidden;
    }

    .text-td span {
      white-space: nowrap;
    }
    .text-li{
      width: 220px;
      overflow: hidden;
    }
    .text-li span {
      white-space: nowrap;
    }
    .page-item{
      padding-bottom:  25px !important;
      border-bottom: 20px solid;
      border-color: rgba(111,111,111,0.2) transparent;
    }
    .page-item:last-child{
      border: none !important;
    }
    .scrollbar::-webkit-scrollbar {
        width: 6px;
        background-color: #F8F8F8;
        border-radius: 5px;
    }
    .scrollbar::-webkit-scrollbar-thumb {
        background: #C6C6C6;
        border-radius: 10px;
    }
    .scrollbar-x::-webkit-scrollbar {
        width: 6px;
        background-color: #F8F8F8;
        border-radius: 5px;
        height: 6px;
    }
    .scrollbar-x::-webkit-scrollbar-thumb {
        background: #C6C6C6;
        border-radius: 10px;
    }
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    .container-fluid{
        padding: 0 30px;
    }
    .container{
        width: 1000px;
        padding: 0 15px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .row{
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }
    .col-12{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .col-6{
        flex: 0 0 50%;
        max-width: 50%;
    }
    @media (min-width: 768px){
        .row.separate::after{
            content: "";
            width: 1px;
            height: 100%;
            background-color: #000;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
        .col-md-6{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    @media (min-width: 1200px){
        .col-lg-6{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .p-title{
        text-decoration: underline;
    }
    .p-title span{
        margin-left: 50px;
    }
    table{
        border-collapse: collapse;
    }
    table td, table th{
        border: 1px solid;
        min-width: 15px;
    }
    .spacer{
        margin-top: 20px;
    }
    .spacer-large{
        margin-top: 13px;
    }
    .ml-auto{
        margin-left: auto;
    }
    .mr--15{
        margin-right: -15px;
    }
    .ml--15{
        margin-left: -15px;
    }
    .pl-15{
        padding-left: 15px;
    }
    .pr-15{
        padding-right: 15px;
    }
    .w-100{
        width: 100%;
    }
    .w-60{
        width: 60%;
    }
    img{
        max-width: 100%;
    }
    ol{
        padding-left: 20px;
        font-size: 80%;
    }
    .box{
        border: 1px solid;
        width: 100%;
        height: 130px;
    }
    .table{
        width: 100%;
        overflow: hidden;
    }
    table .cross{
        background-image: linear-gradient(to top right, transparent calc(50% - 1px), #000, transparent calc(50% + 1px));
    }
    .text-right{
        text-align: right;
    }
    .fw-bold{
        font-weight: 700;
    }
    .ws-nowrap{
        white-space: nowrap;
    }

    /* table2 */
    #table-1{
      width: 297mm;
      margin: 0 auto;
    }
    #wrap .title {
        text-align: center;
    }
    #wrap .content {
        /* width: 1110px; */
        margin: auto;
    }
    #wrap .w-100 {
        width: 100%;
    }
    #wrap .text-end {
        text-align: end;
        margin-top: -5px;
    }
    #wrap .text-center {
        text-align: center !important;
    }
    #wrap .content ul {
        display: flex;
        gap: 300px;
        border: 1px solid;
    }
    #wrap .content ul li {
        list-style: none;
        position: relative;
    }
    #wrap .content ul li::after {
        content: "";
        background: black;
        width: 1px;
        height: 100%;
        position: absolute;
        right: -32px;
        transform: translateY(-50%);
        top: 50%;
    }
    #wrap .content ul li::before {
        content: "";
        background: black;
        width: 1px;
        height: 100%;
        position: absolute;
        left: -32px;
        transform: translateY(-50%);
        top: 50%;
    }
    #wrap .content ul li:nth-child(1)::before {
        opacity: 0;
    }
    #wrap .content .titleContent {
        border: none;
        gap: 50px;
        font-weight: bold;
        padding: 0;
    }
    #wrap .content .titleContent li::after {
        display: none;
    }
    #wrap .content .titleContent li::before {
        display: none;
    }
    #warp .content table,
    th,
    td {
        border: 1px solid black;
        border-collapse: collapse;
    }
    #warp .content .point {
        position: relative;
    }
    #wrap .point {
        position: relative;
        width: 300px;
    }
    #wrap .point {
        background-image: linear-gradient(to top right, transparent calc(50% - 1px), #000, transparent calc(50% + 1px));
    }
    #wrap .point-1 {
        position: absolute;
        bottom: -15px;
    }
    #wrap .custom {
        display: block;
        width: 27px;
        text-align: center;
        margin: auto;
    }
    #wrap table {
        border-spacing: 0;
    }
    #wrap .footer-table {
        padding-top: 20px;
    }
    #wrap .footer-table-ul {
        border: none !important;
        display: block !important;
        padding: 0;
    }
    #wrap .footer-table-ul li::after {
        display: none;
    }
    #wrap .footer-table-ul li::before {
        display: none;
    }
</style>
