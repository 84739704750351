import Api, { Mock } from "../api";

const PATH = "reset_request";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;

if (Mock) {
  Mock.onPost(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  // Mock.onPost(URL).reply(200, {
  //   status_code: 500,
  //   message: "メールの送信ができませんでした",
  //   result: false,
  //   contents: {},
  // });
}

export const resetMail = {
  post: (params) => {
    return Api.post(URL, params);
  },
};
