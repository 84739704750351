<template >
    <div>
        <v-card class="dialog dialog-setting-machine">
            <v-card-title class="header-dialog">
                <div class="title">点検表情報</div>
                <v-spacer></v-spacer>
                <v-btn small icon text @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="body">
                <div class="container">
                    <div class="w-20">
                        <div class="header-list border-right-none">
                            <div class="space-between">
                                <h1 class="txt-color f-18">大分類</h1>
                                <v-btn
                                        class="mx-2 TableHeader__menu__icon"
                                        small
                                        icon
                                        @click="openDialogAddData()"
                                >
                                    <v-icon class="plus-icon"> mdi-plus </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="body-list scrollbar border-right-none">
                            <div class="item" v-for="(item, key) in listMajor" :key="key"
                                :class="{ 'active-item' : key == itemActive.tabOne }"
                                @click="activeItem(key, levelTab.One, item.major_classification_id)"
                                >
                                <div class="space-between">
                                    <p>{{ relateText(item.major_classification) }}</p>
                                    <v-btn
                                            class="mx-2 TableHeader__menu__icon "
                                            small
                                            icon
                                            @click="openDialogEditMachine(1, item.major_classification_id, key)"
                                    >
                                        <v-icon

                                        > mdi-pencil-outline </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-20">
                        <div class="header-list ">
                            <div class="space-between">
                                <h1 class="txt-color f-18">機械名</h1>
                                <v-btn
                                        class="mx-2 TableHeader__menu__icon"
                                        small
                                        icon
                                        @click="openFormDialogTabTwo()"
                                >
                                    <v-icon class="plus-icon"> mdi-plus </v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="body-list scrollbar">
                            <div class="item" v-for="(item, key) in listMiddle" :key="key"
                                :class="{ 'active-item' : key == itemActive.tabTwo }"
                                @click="activeItem(key, levelTab.Two, item.middle_classification_id)"
                                >
                                <div class="space-between">
                                    <p>{{ relateText(item.middle_classification) }}</p>
                                    <div class="d-flex action-icon">
                                        <v-btn
                                                class="icon mx-2 TableHeader__menu__icon"
                                                small
                                                icon
                                                @click="openDialogEditMachine(2, item.middle_classification_id, key)"
                                        >
                                            <v-icon > mdi-pencil-outline </v-icon>
                                        </v-btn>
                                        <v-btn
                                                class="icon mx-2 TableHeader__menu__icon"
                                                small
                                                icon
                                                @click="copyMiddle(item.middle_classification_id)"
                                        >
                                            <v-icon> mdi-content-copy </v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-60">
                        <div class="tab-content">
                            <div class="tab-header space-between">
                                <div class="left txt-color f-14">
                                    <p class="major-title">{{ relateText(objectMapping.majorTitle) }}</p>
                                    <p class="middle-title">{{ relateText(objectMapping.middleTitle) }}</p>
                                </div>
                                <div class="right">
                                    <button class="tab-item" :class="[
                                      { active: this.isShowTab.tabOne },
                                      { 'noDrop': this.listMiddle.length === 0 }
                                    ]" @click="activeTab(1)">
                                        画像
                                    </button>
                                    <button class="tab-item" :class="[
                                      { active: this.isShowTab.tabTwo },
                                      { 'noDrop': this.listMiddle.length === 0 }
                                    ]" @click="activeTab(2)">
                                        作業前点検
                                    </button>
                                    <button class="tab-item" :class="[
                                      { active: this.isShowTab.tabThree },
                                      { 'noDrop': this.listMiddle.length === 0 }
                                    ]" @click="activeTab(3)">
                                        月例点検
                                    </button>
                                </div>
                            </div>
                            <div class="tab-body">
                                <div class="action space-between">
                                    <button v-if="actions.isShowDelete">
                                        <v-btn
                                                class="mx-2 TableHeader__menu__icon"
                                                small
                                                icon
                                                :disabled="disableActions.disableDelete"
                                                @click="onDeleteTab"
                                        >
                                            <v-icon> mdi-delete-outline </v-icon>
                                        </v-btn>
                                    </button>
                                    <button
                                            v-if="actions.isShowAddNew"
                                            :disabled="disableActions.disableAddNew"
                                            class="btn-submit"
                                            @click="openDialogAddNew"
                                    >
                                        項目追加
                                    </button>
                                    <button
                                            v-if="actions.isShowTemplateOne"
                                            :disabled="disableActions.disableTemplateOne"
                                            class="btn-submit" @click="openDialogTemplate(1)">
                                        プレビュー
                                    </button>
                                    <button
                                            v-if="actions.isShowTemplateTwo"
                                            :disabled="disableActions.disableTemplateTwo"
                                            class="btn-submit" @click="openDialogTemplate(2)">
                                        プレビュー
                                    </button>
                                    <button
                                            v-if="actions.isReset"
                                            :disabled="disableActions.disableReset"
                                            @click="uploadImage"
                                            class="btn-submit pt-px">
                                        保存
                                    </button>
                                </div>
                                <div class="content-tab">
                                    <TabOneContent
                                            :filePreview="imageUrl"
                                            ref="tabOneContent"
                                            @changeFile="changeFile"
                                            v-if="isShowTab.tabOne" />
                                    <TabTwoContent
                                        v-if="isShowTab.tabTwo"
                                        ref="tabTwoContent"
                                        :listPrecaution="listPrecaution"
                                        :listDailyItem="listDailyItem"
                                        @changeAllValue="changeAllValueCheckBox"
                                        @changeValuePrecaution="changeValuePrecautionCheckBox"
                                        @changeValueDailyItemItem="changeValueDailyItemItemCheckBox"
                                        @addPrecaution="addPrecaution"
                                        @addDailyItem="addDailyItem"
                                    />
                                    <TabThreeContent
                                        v-if="isShowTab.tabThree"
                                        :listMonthlyItem="listMonthlyItem"
                                        @deleteTestItem="deleteTestItemDetail"
                                        @plusData="openMonthlyItemDialog"
                                        @deleteInspectionDetails="deleteInspectionDetails"
                                    />
                                </div>
                                <div class="blank-page" v-if="listMiddle.length === 0">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <Popup width="450px" :dialog="popups.isShowDialogAddNew" >
            <v-card>
                <AddDataSettingMachine
                        :titleHeader="titleHeader"
                        :isBtnContinue="false"
                        :isDelete="false"
                        :disableDelete="true"
                        :textBtn="{
                          btnSubmit: '保存'
                        }"
                        @submit="addTestItem"
                        @clone="cloneFormDialog()">
                    <template #form-data>
                        <form action="" v-on:submit="resetEventForm($event)" class="form-group">
                            <div class="form-item">
                                <input @change="checkBlank($event)" @keyup="changeValueInput($event, 30)" type="text" name="test_item_add" class="txt-add-data" >
                                <label class="label-item">項目 *</label>
                            </div>
                        </form>
                    </template>
                </AddDataSettingMachine>
            </v-card>
        </Popup>
        <Popup width="450px" :dialog="popups.isShowFormDialog" :isShowFormEdit="true">
            <v-card>
                <AddDataSettingMachine
                        :titleHeader="titleHeader"
                        :isDelete="true"
                        :disableDelete="true"
                        :textBtn="{
                          btnSubmit: '保存'
                        }"
                        @submit="addMajor"
                        @clone="cloneFormDialog()">
                    <template #form-data>
                        <form class="form-group" v-on:submit="resetEventForm($event)" action="">
                            <div class="form-item ">
                                <input @change="checkBlank($event)" @keyup="changeValueInput($event, 255)" type="text" name="major_classification_add" class="txt-add-data">
                                <label class="label-item">大分類 *</label>
                            </div>
                            <div class="mb-form-machine"></div>
                        </form>
                    </template>
                </AddDataSettingMachine>
            </v-card>
        </Popup>
        <Popup width="450px" :dialog="popups.isShowFormDialogTabTwo" >
            <v-card>
                <AddDataSettingMachine
                        :titleHeader="titleHeader"
                        :isDelete="true"
                        :disableDelete="true"
                        :textBtn="{
                          btnSubmit: '保存'
                        }"
                        @submit="addMiddle"
                        @clone="cloneFormDialog()">
                    <template #form-data>
                        <form action="" class="form-group" v-on:submit="resetEventForm($event)">
                            <div class="form-item">
                                <input @change="checkBlank($event)" @keyup="changeValueInput($event, 255)" type="text" name="middle_classification_add" class="txt-add-data" >
                                <label class="label-item">機械名 *</label>
                            </div>
                            <div class="form-item">
                                <input @change="checkBlank($event)" @keyup="changeValueInput($event, 255)" type="text" name="middle_classification_furigana_add" class="txt-add-data" >
                                <label class="label-item">ふりがな *</label>
                            </div>
                        </form>
                    </template>
                </AddDataSettingMachine>
            </v-card>
        </Popup>
        <Popup width="302mm" :dialog="popups.isShowDialogTemplateOne" class="dialog-template">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon text @click="cloneDialogTemplate()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <TemplateTableOne
                  :idMapping="{
                    'majorClassificationId': majorClassificationId,
                    'middleClassificationId': middleClassificationId
                  }"
                  :imageUrl="imageUrl"
                  :listPrecaution="listPrecaution"
                  :listDailyItem="listDailyItem"
                  :objectMapping="objectMapping"
                ></TemplateTableOne>
            </v-card>
        </Popup>
        <Popup width="215mm" :dialog="popups.isShowDialogTemplateTwo" class="dialog-template">
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon text @click="cloneDialogTemplate()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <TemplateTableTwo
                  :listMonthlyItem="listMonthlyItem"
                  :objectMapping="objectMapping"
                ></TemplateTableTwo>
            </v-card>
        </Popup>
        <Popup width="480px" :dialog="popups.isShowDialogEditMachineLevelOne" >
            <AddDataSettingMachine
                :titleHeader="titleHeader"
                :textBtn="{
                      btnSubmit: '保存'
                }"
                @clone="cloneFormDialog()"
                @delete="openDialogConfirm(confirmAction.deleteMajor)"
                @submit="editMajor()"
            >
                <template #form-data>
                    <form action="" v-on:submit="resetEventForm($event)" class="form-group">
                        <div class="form-item">
                            <input @change="checkBlank($event)" @keyup="changeValueInput($event, 255)" type="text" name="major_classification_edit" class="txt-add-data" >
                            <label class="label-item">大分類 *</label>
                        </div>
                        <div class="mb-form-machine"></div>
                    </form>
                </template>
            </AddDataSettingMachine>
        </Popup>
        <Popup width="480px" :dialog="popups.isShowDialogEditMachineLevelTwo" >
            <AddDataSettingMachine
                    :titleHeader="titleHeader"
                    @submit="editMiddle"
                    :textBtn="{
                      btnSubmit: '保存'
                    }"
                    @delete="openDialogConfirm(confirmAction.middle)"
                    @clone="cloneFormDialog()">
                <template #form-data>
                    <form action="" v-on:submit="resetEventForm($event)" class="form-group">
                        <div class="form-item">
                            <input @change="checkBlank($event)" @keyup="changeValueInput($event, 255)" type="text" name="middle_classification_edit" class="txt-add-data" >
                            <label class="label-item">機械名 *</label>
                        </div>
                        <div class="form-item">
                            <input @change="checkBlank($event)" @keyup="changeValueInput($event, 255)" type="text" name="middle_classification_furigana_edit" class="txt-add-data">
                            <label class="label-item">ふりがな *</label>
                        </div>
                    </form>
                </template>
            </AddDataSettingMachine>
        </Popup>
        <Popup width="450px" :dialog="popups.isShowDialogConfirm">
            <ConfirmDialog
                @close="closeDialogConfirm"
                @yes="actionDialogConfirm"
                :items="popupConfirm.items"
                :title="popupConfirm.titleDialog"
                :text="popupConfirm.messageDialog"
            />
        </Popup>
        <Popup width="480px" :dialog="popups.isShowDialogAddMonthlyItem" >
            <AddDataSettingMachine
                    :titleHeader="titleHeader"
                    :isDelete="false"
                    :isBtnContinue="true"
                    :disableDelete="true"
                    :textBtn="{
                      btnSubmit: '追加'
                    }"
                    @continue="addMonthlyItem(true)"
                    @submit="addMonthlyItem"
                    @clone="cloneFormDialog()">
                <template #form-data>
                    <h2 class="su-title-form-add-data">機械特有の点検項目を追加します。</h2>
                    <form action="" v-on:submit="resetEventForm($event)" class="form-group">
                        <div class="form-item-select">
                          <select name="test_item_id" class="text-overflow">
                            <template v-for="item in listMonthlyItem">
                              <option  :selected="item.test_item_id === testItemId" v-bind:value=item.test_item_id>
                                {{ relateText(item.test_item, 25) }}
                              </option>
                            </template>
                          </select>
                          <label class="label-item">項目 *</label>
                        </div>
                        <div class="form-item">
                            <input @change="checkBlank($event)" @keyup="changeValueInput($event, 100)" type="text" name="inspection_item_add" class="txt-add-data" >
                            <label class="label-item">点検事項 *</label>
                        </div>
                        <div class="form-item">
                            <input @change="checkBlank($event)" @keyup="changeValueInput($event, 100)" type="text" name="main_point_add" class="h-textarea txt-add-data">
                            <label class="label-item">主眼点 *</label>
                        </div>
                    </form>
                </template>
            </AddDataSettingMachine>
        </Popup>
    </div>
</template>

<script>
  import { Store } from "@/store/Store.js";
  import ConfirmDialog from "@/components/dialog/ConfirmDialog";
  import AddDataSettingMachine from "@/components/dialog/AddDataSettingMachine";
  import Popup from "@/components/common/Popup";
  import InputText from "@/components/forms/elements/InputText";
  import TabOneContent from "@/views/master/machines/dialog-content/TabOneContent";
  import TabTwoContent from "@/views/master/machines/dialog-content/TabTwoContent";
  import TabThreeContent from "@/views/master/machines/dialog-content/TabThreeContent";
  import TemplateTableOne from "@/views/master/machines/templates/TemplateTableOne";
  import TemplateTableTwo from "@/views/master/machines/templates/TemplateTableTwo";

  // STORE
  // const STORE = "Machines";

  export default {
    name: "DialogSettingMachine",
    components: {
      AddDataSettingMachine,
      Popup,
      InputText,
      TabOneContent,
      TabTwoContent,
      TabThreeContent,
      TemplateTableOne,
      TemplateTableTwo,
      ConfirmDialog
    },
    data(){
      return {
        popups: {
          isShowFormDialog : false,
          isShowFormDialogTabTwo: false,
          isShowDialogTemplateOne: false,
          isShowDialogTemplateTwo: false,
          isShowDialogEditMachineLevelOne: false,
          isShowDialogEditMachineLevelTwo: false,
          isShowDialogAddNew: false,
          isShowDialogConfirm: false,
          isShowDialogAddMonthlyItem: false
        },
        popupConfirm: {
          titleDialog: '点検項目削除',
          messageDialog: '点検項目を削除しますか？',
          items: []
        },
        actions : {
          isShowDelete : true,
          isShowTemplateOne : false,
          isShowTemplateTwo : false,
          isShowAddNew : false,
          isReset: true
        },
        disableActions : {
          disableDelete : false,
          disableTemplateOne : false,
          disableTemplateTwo : false,
          disableAddNew : false,
          disableReset: true
        },
        isShowTab: {
          tabOne: true,
          tabTwo: false,
          tabThree: false,
        },
        titleHeader: '月例点検項目',
        itemActive: {
          tabOne: 0,
          tabTwo: 0
        },
        levelTab: {
          One: 1,
          Two: 2
        },
        statusAction: {
          isMajor: false,
          deleteMajor: false,
          isMiddle: false,
          deleteInspectionDetails: false,
          deletePrecautions: false,
          deleteDailyItemItem: false,
          deletePrecautionAndDailyItem: false,
          deleteTestItem: false,
          deleteImage: false,
        },
        confirmAction: {
          major: 'MAJOR',
          middle: 'MIDDLE',
          deleteMajor: 'DELETE_MAJOR',
          deleteInspectionDetails: 'DELETE_INSPECTION_DETAILS',
          deletePrecautions: 'DELETE_PRECAUTIONS',
          deleteDailyItemItem: 'DELETE_DAILY_ITEM',
          deletePrecautionAndDailyItem: 'DELETE_PRECAUTION_AND_DAILY_ITEM',
          deleteTestItem: 'DELETE_TEST_ITEM',
          deleteImage: 'DELETE_IMAGE'
        },
        idMachineEdit :  null,
        listMajor: [],
        listMiddle: [],
        listPrecaution: [],
        listDailyItem: [],
        listMonthlyItem: [],
        imageUrl: null,
        majorClassificationId: null,
        middleClassificationId: null,
        testItemId: null,
        inspectionItemId: null,
        mainPointId: null,
        objectMapping: {
          majorTitle: '',
          middleTitle: ''
        },
        precautionsIds: [],
        dailyItemIds: [],
        changeValueCheckBox: {
          precautions: false,
          dailyItem: false,
          precautionAndDailyItem: false
        },
      }
    },
    mounted() {
      this.getListMajor();
      this.disableActions.disableDelete = !this.$refs.tabOneContent.hasImage;
    },
    methods: {
      relateText(str, length = 30){
        if (str.length <= length){
          return str;
        }
        str = str.slice(0, length);
        return `${str}...`
      },
      resetEventForm(event){
        event.preventDefault();
      },
      resetTabActive(type){
        let id;
        if(type === this.levelTab.One){
          id = this.listMajor[0]['major_classification_id'];
        }
        if(type === this.levelTab.Two){
          id = this.listMiddle[0]['middle_classification_id'];
        }
        this.activeItem(0, type, id);
      },
      resetCheckBox(){
        this.changeValueCheckBox.precautions = false;
        this.changeValueCheckBox.dailyItem = false;
        this.changeValueCheckBox.precautionAndDailyItem = false;
      },
      resetForm(){
        document.querySelector(`form`).reset();
      },
      changeFile(hasImage){
        this.disableActions.disableDelete = !hasImage;
        this.disableActions.disableReset = !hasImage;
        this.disableActions.disableReset = this.$refs.tabOneContent.getValidateStatus();
      },
      checkBlank(event){
        if (event.target.value !== ''){
          let element = event.target;
          element.style.borderColor = "rgba(0, 0, 0, 0.12)";
          if(element.closest('div').querySelector('div') != null){
            element.closest('div').querySelector('div').remove();
          }
        }
      },
      changeValueInput(event, max = 255){
        if (event.target.value !== ''){
          let element = event.target;
          if (element.value.length > max){
            element.value = element.value.slice(0, max);
          }
          element.style.borderColor = "#00C3AC";
          if(element.closest('div').querySelector('div') != null){
            element.closest('div').querySelector('div').remove();
          }
        }
        this.lastValue = event.target.value;
      },
      getValueInput(name, type = 'input'){
        let element = document.querySelector(`${type}[name=${name}]`);
        if(element.value == null || element.value == ''){
          let label = element.closest('div').querySelector('label').innerText;
          let html =`${label.replace('*','')}は必須項目です`;
          let div = document.createElement('div');
          div.style.color = 'red';
          div.innerHTML = html;
          
          element.style.borderColor = "red";
          if(!element.closest('div').querySelector('div')){
            element.closest('div').appendChild(div);
          }
          throw '';
        }else{
          if(element.closest('div').querySelector('div') != null){
            element.closest('div').querySelector('div').remove();
          }
          element.style.borderColor = "rgba(0, 0, 0, 0.12)";
          return document.querySelector(`${type}[name=${name}]`).value;
        }
      },
      setValueInput(name, value, type = 'input'){
        this.$nextTick(()=>{
          document.querySelector(`${type}[name=${name}]`).value = value;
        })
      },
      changeAllValueCheckBox(data){
        this.resetCheckBox();
        this.changeValueCheckBox.precautionAndDailyItem = true;
        this.precautionsIds = data['checked_precaution_item'];
        this.dailyItemIds = data['checked_daily_item_item'];
      },
      changeValuePrecautionCheckBox(listItem){
        this.resetCheckBox();
        this.changeValueCheckBox.precautions = listItem.length == 0 ? false : true;
        this.precautionsIds = listItem;
        this.disableActions.disableDelete = listItem.length <= 0;
      },
      changeValueDailyItemItemCheckBox(listItem){
        this.resetCheckBox();
        this.changeValueCheckBox.dailyItem = listItem.length == 0 ? false : true;
        this.dailyItemIds = listItem;
        this.disableActions.disableDelete = listItem.length <= 0;
      },
      addMajor(){
        let params = {
          "major_classification": this.getValueInput('major_classification_add'),
        };
        Store.dispatch("Machines/addMajor", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMajor();
            this.cloneFormDialog();
            let idRes = response.data.contents.entries.major_classification_id;
            if (this.listMajor.length === 1){
              this.majorClassificationId = idRes;
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      editMajor(){
        let params = {
          "major_classification_id": this.idMachineEdit,
          "major_classification": this.getValueInput('major_classification_edit'),
        }
        Store.dispatch("Machines/editMajor", params).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMajor()
            this.objectMapping.majorTitle = this.getValueInput('major_classification_edit');
            this.cloneFormDialog()
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      deleteMajor(){
        let params = {
          "major_classification_id": this.idMachineEdit,
        }
        Store.dispatch("Machines/deleteMajor", params).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMajor();
            if (this.listMajor.length === 0){
              this.objectMapping.majorTitle = '';
            }
            this.cloneFormDialog()
            this.resetTabActive(this.levelTab.One);
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      getListMajor(){
        Store.dispatch("Machines/getListMajor", null).then((response)=> {
          this.listMajor = response.data.contents.entries;
          this.majorClassificationId = this.listMajor[0]['major_classification_id']
          this.activeItem(0, this.levelTab.One, this.majorClassificationId);
        }).catch((error) => {
          console.log(error);
        });
      },
      addMiddle(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification": this.getValueInput('middle_classification_add'),
          "middle_classification_furigana": this.getValueInput('middle_classification_furigana_add')
        };
        Store.dispatch("Machines/addMiddle", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMiddle(this.majorClassificationId);
            let idRes = response.data.contents.entries.middle_classification_id;
            if (this.listMiddle.length === 1){
              this.middleClassificationId = idRes;
              this.objectMapping.middleTitle = this.getValueInput('middle_classification_add');
            }
            this.cloneFormDialog();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      editMiddle(){
        let params = 	{
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "middle_classification": this.getValueInput('middle_classification_edit'),
          "middle_classification_furigana": this.getValueInput('middle_classification_furigana_edit'),
        }
        Store.dispatch("Machines/editMiddle", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMiddle(this.majorClassificationId, this.itemActive.tabTwo);
            this.activeItem(this.itemActive.tabTwo, this.levelTab.Two, this.middleClassificationId);
            this.objectMapping.middleTitle = this.getValueInput('middle_classification_edit');
            this.cloneFormDialog();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      copyMiddle(middle_classification_id){
        this.middleClassificationId = middle_classification_id;
        let params = 	{
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
        }
        Store.dispatch("Machines/copyMiddle", params ).then((response)=> {
          if (response.data.status_code == 200){
            let index = this.listMiddle.length;
            this.activeItem(index, this.levelTab.Two, middle_classification_id);
            this.getListMiddle(this.majorClassificationId, index);
            this.cloneFormDialog();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      deleteMiddle(){
        let params = 	{
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
        }
        Store.dispatch("Machines/deleteMiddle", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMiddle(this.majorClassificationId);
            this.cloneFormDialog();
            if (this.listMiddle.length === 1){
              this.objectMapping.middleTitle = '';
            }else{
              this.resetTabActive(this.levelTab.Two);
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      getListMiddle(major_id, index = 0){
        let params = {
          "major_classification_id": major_id
        };
        Store.dispatch("Machines/getListMiddle", { params }).then((response)=> {
          if (response.data.status_code === 200){
            this.listMiddle = response.data.contents.entries;
            this.middleClassificationId = this.listMiddle[index]['middle_classification_id'];
            this.objectMapping.middleTitle = this.listMiddle[index]['middle_classification'];
            if (response.data.contents.entries.length !== 0){
              this.getImageUrl();
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      getListPrecaution(){
        let count = this.listMiddle.filter(x => {
          return x.middle_classification_id === this.middleClassificationId
        }).length;
        if (count > 0){
          let params = {
            "major_classification_id": this.majorClassificationId,
            "middle_classification_id": this.middleClassificationId,
          }
          console.log(params);
          Store.dispatch("Machines/getListPrecaution", { params }).then((response)=> {
            this.listPrecaution = response.data.contents.entries;
          }).catch((error) => {
            console.log(error);
          });
        }
      },
      addPrecaution(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "precautions": this.getValueInput('precautions_add'),
        }
        Store.dispatch("Machines/addPrecaution", params ).then((response)=> {
          if (response.data.status_code === 200){
            this.getListPrecaution()
            this.resetForm();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      deletePrecaution(){
        if (this.precautionsIds.length > 0){
          let params = {
            "major_classification_id": this.majorClassificationId,
            "middle_classification_id": this.middleClassificationId,
            "precautions_ids": this.precautionsIds,
          }
          Store.dispatch("Machines/deletePrecaution", params ).then((response)=> {
            if (response.data.status_code === 200){
              this.getListPrecaution();
              this.resetForm();
            }
            this.$refs.tabTwoContent.resetCheckBox();
            this.disableActions.disableDelete = true;
            this.precautionsIds = [];
          }).catch((error) => {
            console.log(error);
            this.$refs.tabTwoContent.resetCheckBox();
          });
        }
      },
      deleteDailyItem(){
        if (this.dailyItemIds.length > 0){
          let params = {
            "major_classification_id": this.majorClassificationId,
            "middle_classification_id": this.middleClassificationId,
            "daily_item_ids": this.dailyItemIds,
          }
          Store.dispatch("Machines/deleteDailyItem", params ).then((response)=> {
            if (response.data.status_code === 200){
              this.getListDailyItem();
              this.resetForm();
            }
            this.$refs.tabTwoContent.resetCheckBox();
            this.disableActions.disableDelete = true;
            this.dailyItemIds = [];
          }).catch((error) => {
            console.log(error);
            this.$refs.tabTwoContent.resetCheckBox();
          });
        }
      },
      addDailyItem(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "inspection_item": this.getValueInput('inspection_item_add'),
          "main_point": this.getValueInput('main_point_add'),
        }
        Store.dispatch("Machines/addDailyItem", params ).then((response)=> {
          if (response.data.status_code === 200){
            this.getListDailyItem();
            this.resetForm();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      getListDailyItem(){
        let count = this.listMiddle.filter(x => {
          return x.middle_classification_id === this.middleClassificationId
        }).length;
        if (count > 0){
          let params = {
            "major_classification_id": this.majorClassificationId,
            "middle_classification_id": this.middleClassificationId,
          }
          Store.dispatch("Machines/getListDailyItem", { params }).then((response)=> {
            this.listDailyItem = response.data.contents.entries;
          }).catch((error) => {
            console.log(error);
          });
        }
      },
      getListMonthlyItem(){
        let count = this.listMiddle.filter(x => {
          return x.middle_classification_id === this.middleClassificationId
        }).length;
        if (count > 0){
          let params = {
            "major_classification_id": this.majorClassificationId,
            "middle_classification_id": this.middleClassificationId,
          }
          Store.dispatch("Machines/getListMonthlyItem", { params }).then((response)=> {
            this.listMonthlyItem = response.data.contents.entries;
          }).catch((error) => {
            console.log(error);
          });
        }
      },
      getImageUrl(){
        let count = this.listMiddle.filter(x => {
          return x.middle_classification_id === this.middleClassificationId
        }).length;
        if (count > 0){
          let params = {
            "major_classification_id": this.majorClassificationId,
            "middle_classification_id": this.middleClassificationId,
          };
          Store.dispatch("Machines/getImageUrl", { params }).then((response)=> {
            if (response.data.status_code == 200){
              this.imageUrl = response.data.contents.image_url;
              this.disableActions.disableDelete = this.imageUrl === null;
            }
          }).catch((error) => {
            console.log(error);
          });
        }else{
          this.disableActions.disableDelete = true;
        }
      },
      uploadImage(){
        this.$refs.tabOneContent.setLoading(true);
        let dataFile = this.$refs.tabOneContent.getInfoFile();
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "image_name": dataFile.fileName,
          "image": dataFile.fileBase64,
        };
        Store.dispatch("Machines/uploadImage", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.$refs.tabOneContent.cleanImage();
            this.$refs.tabOneContent.setLoading(false);
            this.getImageUrl();
            this.disableActions.disableReset = true;
          }
        }).catch((error) => {
          this.$refs.tabOneContent.cleanImage();
          this.$refs.tabOneContent.setLoading(false);
          this.disableActions.disableReset = true;
          console.log(error);
        });
      },
      deleteImage(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
        };
        Store.dispatch("Machines/deleteImage", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.disableActions.disableDelete = true;
            this.imageUrl = null;
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      addTestItem(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "test_item": this.getValueInput('test_item_add'),
        };
        Store.dispatch("Machines/addTestItem", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMonthlyItem();
            this.cloneFormDialog();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      deleteTestItem(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "test_item_id": this.testItemId
        };
        Store.dispatch("Machines/deleteTestItem", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMonthlyItem();
            this.cloneFormDialog();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      addMonthlyItem(isContinue = false){
        let testItemId = this.getValueInput('test_item_id', 'select');
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "test_item_id": testItemId,
          "inspection_item": this.getValueInput('inspection_item_add'),
          "main_point": this.getValueInput('main_point_add'),
        };
        Store.dispatch("Machines/addMonthlyItem", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMonthlyItem();
            if (isContinue){
              this.setValueInput('test_item_id', this.testItemId, 'select')
              this.setValueInput('inspection_item_add', '')
              this.setValueInput('main_point_add', '')
            }else{
              this.cloneFormDialog();
            }
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      deleteMonthlyItem(){
        let params = {
          "major_classification_id": this.majorClassificationId,
          "middle_classification_id": this.middleClassificationId,
          "test_item_id": this.testItemId,
          "inspection_item_id": this.inspectionItemId,
          "main_point_id": this.mainPointId,
        };
        Store.dispatch("Machines/deleteMonthlyItem", params ).then((response)=> {
          if (response.data.status_code == 200){
            this.getListMonthlyItem();
            this.cloneFormDialog();
          }
        }).catch((error) => {
          console.log(error);
        });
      },
      openDialogAddData(){
        this.titleHeader = '大分類';
        this.popups.isShowFormDialog = true;
      },
      openFormDialogTabTwo(){
        this.titleHeader = '機械名';
        this.popups.isShowFormDialogTabTwo = true;
      },
      openMonthlyItemDialog(id){
        this.testItemId = id;
        this.titleHeader = '点検項目追加';
        this.popups.isShowDialogAddMonthlyItem = true;
        this.setValueInput('test_item', this.listMonthlyItem.filter(x => x.test_item_id === id)[0]['test_item']);
      },
      cloneFormDialog(){
        this.popups.isShowFormDialog = false;
        this.popups.isShowFormDialogTabTwo = false;
        this.popups.isShowDialogEditMachineLevelOne = false;
        this.popups.isShowDialogEditMachineLevelTwo = false;
        this.popups.isShowDialogAddNew = false;
        this.popups.isShowDialogAddMonthlyItem = false;
      },
      resetAction(){
        this.actions.isReset = false;
        this.actions.isShowDelete = false;
        this.actions.isShowAddNew = false;
        this.actions.isShowTemplateOne = false;
        this.actions.isShowTemplateTwo = false;
      },
      activeTab(index, isCallImage = true){
        this.resetActive();
        this.resetAction();
        switch (index) {
          case 1:
            this.actions.isShowDelete = true;
            this.actions.isReset = true;
            this.isShowTab.tabOne = true;
            break;
          case 2:
            this.actions.isShowTemplateOne = true;
            this.actions.isShowDelete = true;
            this.disableActions.disableDelete = true;
            this.isShowTab.tabTwo = true;
            this.getListPrecaution();
            this.getListDailyItem();
            break;
          case 3:
            this.actions.isShowAddNew = true;
            this.actions.isShowTemplateTwo = true;
            this.isShowTab.tabThree = true;
            this.getListMonthlyItem();
            break;
        }
        if (isCallImage && index === 1){
          this.getImageUrl();
        }
      },
      resetActive(){
        this.isShowTab.tabOne = false;
        this.isShowTab.tabTwo = false;
        this.isShowTab.tabThree = false;
      },
      openDialogTemplate(template){
        if (template == 1){
          this.popups.isShowDialogTemplateOne = true;
        }
        if (template == 2){
          this.popups.isShowDialogTemplateTwo = true;
        }
      },
      cloneDialogTemplate(){
        this.popups.isShowDialogTemplateOne = false;
        this.popups.isShowDialogTemplateTwo = false;
        this.getListMonthlyItem();
      },
      openDialogEditMachine(level, id, key = 0){
        this.idMachineEdit = id;
        switch (level) {
          case 1:
            this.titleHeader = '大分類';
            this.popups.isShowDialogEditMachineLevelOne = true;
            this.setValueInput('major_classification_edit', this.listMajor.filter(x => x.major_classification_id === id)[0]['major_classification']);
            this.itemActive.tabOne = key;
            break;
          case 2:
            this.titleHeader = '機械名';
            this.popups.isShowDialogEditMachineLevelTwo = true;
            this.setValueInput('middle_classification_edit', this.listMiddle.filter(x => x.middle_classification_id === id)[0]['middle_classification']);
            this.setValueInput('middle_classification_furigana_edit', this.listMiddle.filter(x => x.middle_classification_id === id)[0]['middle_classification_furigana']);
            this.itemActive.tabTwo = key;
            break
        }
      },
      onDeleteTab(){
        if (this.isShowTab.tabTwo){
          if (this.changeValueCheckBox.precautionAndDailyItem){
            this.openDialogConfirm(this.confirmAction.deletePrecautionAndDailyItem);
          }
          if (this.changeValueCheckBox.precautions){
            this.openDialogConfirm(this.confirmAction.deletePrecautions);
          }
          if(this.changeValueCheckBox.dailyItem){
            this.openDialogConfirm(this.confirmAction.deleteDailyItemItem);
          }
        }
        if (this.isShowTab.tabOne){
          this.openDialogConfirm(this.confirmAction.deleteImage);
          this.popupConfirm.titleDialog = '画像の削除';
          this.popupConfirm.messageDialog = '以下の機械の画像を削除してもよろしいですか？';
          let middle_classification = this.listMiddle.filter(x => x.middle_classification_id === this.middleClassificationId)[0]['middle_classification']
          this.popupConfirm.items = [middle_classification]
        }
      },
      openDialogAddNew(){
        this.titleHeader = '月例点検項目';
        this.popups.isShowDialogAddNew = true;
      },
      activeItem(item, level, id, isCallImage = true){
        this.imageUrl = null;
        this.disableActions.disableReset = true;
        switch (level) {
          case 1:
            this.getListMiddle(id);
            this.activeTab(1, false);
            this.majorClassificationId = id;
            this.itemActive.tabOne = item;
            this.objectMapping.majorTitle = this.listMajor.filter(x => x.major_classification_id === id)[0] == undefined ? '' :
            this.listMajor.filter(x => x.major_classification_id === id)[0]['major_classification']
            this.activeItem(0, this.levelTab.Two, id, false);
            break;
          case 2:
            this.activeTab(1,  false);
            this.middleClassificationId = id;
            this.itemActive.tabTwo = item;
            this.objectMapping.middleTitle = this.listMiddle.filter(x => x.middle_classification_id === id)[0] == undefined ? '' : 
            this.listMiddle.filter(x => x.middle_classification_id === id)[0]['middle_classification']
            break;
        }
        if (level === 2 && isCallImage){
          this.getImageUrl();
        }
      },
      resetDeleteAction(){
        this.statusAction.isMajor = false;
        this.statusAction.deleteMajor = false;
        this.statusAction.isMiddle = false;
        this.statusAction.deleteInspectionDetails = false;
        this.statusAction.deletePrecautions = false;
        this.statusAction.deleteDailyItemItem = false;
        this.statusAction.deleteTestItem = false;
        this.statusAction.deleteImage = false;
        this.statusAction.isMiddle = false;
        this.statusAction.isMiddle = false;
        this.statusAction.isMajor = false;
      },
      resetMessageConfirm(){
        this.popupConfirm.titleDialog = '点検項目削除';
        this.popupConfirm.messageDialog = '点検項目を削除しますか？';
        this.popupConfirm.items = []
      },
      openDialogConfirm(obj){
        this.popupConfirm.items = [];
        if (obj === this.confirmAction.deletePrecautionAndDailyItem){
          this.dailyItemIds.forEach((element) => {
            this.popupConfirm.items.push(this.listDailyItem.filter(x => {
              return x.inspection_item_id === parseInt(element['inspection_item_id']) && x.main_point_id === parseInt(element['main_point_id']);
            })[0]['inspection_item']);
          });
          this.precautionsIds.forEach((element) => {
            this.popupConfirm.items.push(this.listPrecaution.filter(x => x.precautions_id === parseInt(element))[0]['precautions']);
          });
          this.statusAction.deletePrecautionAndDailyItem = true;
        }
        if (obj === this.confirmAction.deleteImage){
          this.statusAction.deleteImage = true;
        }
        if (obj === this.confirmAction.deleteTestItem){
          this.popupConfirm.titleDialog="月例点検項目削除";
          this.popupConfirm.messageDialog="月例点検項目を削除しますか？";
          this.popupConfirm.items.push(this.listMonthlyItem.filter(x => x.test_item_id === this.testItemId)[0]['test_item']);
          this.statusAction.deleteTestItem = true;
        }
        if (obj === this.confirmAction.deleteMajor){
          this.popupConfirm.titleDialog="大分類削除";
          this.popupConfirm.messageDialog = '大分類を削除しますか？';
          this.popupConfirm.items.push(this.listMajor.filter(x => x.major_classification_id === this.majorClassificationId)[0]['major_classification']);
          this.statusAction.deleteMajor = true;
        }
        if (obj === this.confirmAction.deleteDailyItemItem){
          this.dailyItemIds.forEach((element) => {
            this.popupConfirm.items.push(this.listDailyItem.filter(x => {
              return x.inspection_item_id === parseInt(element['inspection_item_id']) && x.main_point_id === parseInt(element['main_point_id']);
            })[0]['inspection_item']);
          });
          this.statusAction.deleteDailyItemItem = true;
        }
        if (obj === this.confirmAction.deletePrecautions){
          this.precautionsIds.forEach((element) => {
            this.popupConfirm.items.push(this.listPrecaution.filter(x => x.precautions_id === parseInt(element))[0]['precautions']);
          });
          this.statusAction.deletePrecautions = true;
        }
        if (obj === this.confirmAction.middle){
          this.popupConfirm.titleDialog="機械名削除";
          this.popupConfirm.messageDialog = '機械名を削除しますか？';
          this.popupConfirm.items.push(this.listMiddle.filter(x => x.middle_classification_id === this.middleClassificationId)[0]['middle_classification']);
          this.statusAction.isMiddle = true;
        }
        if (obj === this.confirmAction.deleteInspectionDetails){
          this.popupConfirm.titleDialog="点検項目削除";
          this.popupConfirm.messageDialog="点検項目を削除しますか？";
          let array = this.listMonthlyItem.filter(x => x.test_item_id === this.testItemId)['0']['inspection_details'];
          this.popupConfirm.items.push(array.filter(x => {
            return x.inspection_item_id === this.inspectionItemId && x.main_point_id === this.mainPointId;
          })[0]['inspection_item']);
          this.statusAction.deleteInspectionDetails = true;
        }
        this.cloneFormDialog();
        this.popups.isShowDialogConfirm = true;
      },
      closeDialogConfirm(){
        this.popups.isShowDialogConfirm = false;
        this.resetDeleteAction();
        this.resetMessageConfirm();
      },
      deleteInspectionDetails(data){
        this.inspectionItemId =  data.inspection_item_id;
        this.mainPointId = data.main_point_id;
        this.testItemId = data.test_item_id;
        this.openDialogConfirm(this.confirmAction.deleteInspectionDetails);
      },
      deleteTestItemDetail(data){
        this.testItemId = data.test_item_id;
        this.openDialogConfirm(this.confirmAction.deleteTestItem);
      },
      actionDialogConfirm(){
        if (this.statusAction.deletePrecautionAndDailyItem){
          this.deleteDailyItem();
          this.deletePrecaution();
        }
        if (this.statusAction.deleteImage){
          this.deleteImage();
        }
        if (this.statusAction.deleteMajor){
          this.deleteMajor();
        }
        if (this.statusAction.deleteDailyItemItem){
          this.deleteDailyItem();
        }
        if (this.statusAction.deletePrecautions){
          this.deletePrecaution();
        }
        if (this.statusAction.deleteTestItem){
          this.deleteTestItem();
        }
        if (this.statusAction.deleteInspectionDetails){
          let array = this.listMonthlyItem.filter(x => x.test_item_id === this.testItemId)['0']['inspection_details'];
          this.listMonthlyItem.filter(x => x.test_item_id === this.testItemId)['0']['inspection_details'] = array.filter(x => {
            return x.inspection_item_id !== this.inspectionItemId && x.main_point_id !== this.mainPointId;
          })
          this.deleteMonthlyItem();
        }
        if (this.statusAction.isMiddle){
          this.deleteMiddle();
        }
        this.resetDeleteAction();
        this.resetMessageConfirm();
        this.popups.isShowDialogConfirm = false;
      }
    }
  };
</script>

<style lang="css" scoped>
    .tab-body{
      position: relative;
    }
    .blank-page{
      background: #fff;
      z-index: 9999;
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
    }
    .noDrop{
      cursor: no-drop !important;
    }
    .h-textarea{
      height: 80px !important;
    }
    .plus-icon{
        color: rgba(0, 0, 0, 0.87) !important;
    }
    .su-title-form{
        padding-top: 15px;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.6);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    .form-group{
        margin-top: 23px;
        padding-left: 20px;
        padding-right: 20px;

    }
    .form-group .form-item-select{
      position: relative;
      margin-bottom: 20px;
    }
    .form-group .form-item-select:after{
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid #756969;
      position: absolute;
      top: 45%;
      right: 10px;
      content: "";
      z-index: 98;
    }
    .form-group .form-item-select select{
      position: relative;
      width: 100%;
      outline: none;
      border-radius: 5px;
      border: solid 1px rgba(0, 0, 0, 0.12);
      height: 52px;
      padding: 14px;
    }
    .form-group .form-item-select .label-item{
      position: absolute;
      top: -12px;
      left: 16px;
      background: #fff;
      padding-left: 5px;
      padding-right: 5px;
      color: rgba(0, 0, 0, 0.6);
    }
    .form-group .form-item-select select:focus ~.label-item{
      color: #00C3AC;
    }
    .form-group .form-item-select select:focus{
      border: solid 2px #00C3AC;
    }
    .form-group .form-item {
        position: relative;
        margin-bottom: 20px;
    }
    .form-group .form-item input{
        border-radius: 5px;
        border: solid 1px rgba(0, 0, 0, 0.12);
        height: 52px;
    }
    .form-group .form-item input:focus ~.label-item{
        color: #00C3AC;
    }
    .form-group .form-item input:focus{
        border: solid 2px #00C3AC !important;
    }
    .form-group .form-item .label-item{
        position: absolute;
        top: -12px;
        left: 16px;
        background: #fff;
        padding-left: 5px;
        padding-right: 5px;
        color: rgba(0, 0, 0, 0.6);
    }
    .form-group .form-item .txt-add-data{
        outline: none;
        width: 100%;
        font-size: 16px;
        padding: 14px;
    }
    .form-group .form-textarea-item {
        position: relative;
        margin-bottom: 20px;
    }
    .form-group .form-textarea-item textarea{
        border-radius: 5px;
        border: solid 1px rgba(0, 0, 0, 0.12);
    }
    .form-group .form-textarea-item textarea:focus{
        border-color: #00C3AC !important;
    }
    .form-group .form-textarea-item .label-item{
        z-index: 9999;
        position: absolute;
        top: -12px;
        left: 16px;
        background: #fff;
        padding-left: 5px;
        padding-right: 5px;
        color: rgba(0, 0, 0, 0.6);;
    }
    .form-group .form-textarea-item .textarea-add-data:focus ~.label-item{
      color: #00C3AC;
    }
    .form-group .form-textarea-item .textarea-add-data{
        outline: none;
        width: 100%;
        font-size: 16px;
        padding: 14px;
        height: 100%;
    }
    .dialog {
        height: auto;
        overflow: hidden !important;
    }
    .dialog .header-dialog {
        padding: 10px;
        border-bottom: 1px solid #ccc;
    }
    .dialog .header-dialog .title{
        color: rgba(0,0,0,.87);
        font-size: 20px!important;
        font-weight: 600;
    }
    .dialog .body{
        padding-top: 0px !important;
        padding: 22px;
        height: auto;
    }
    .dialog .body .text-wrap {
        padding: 16px 0;
        position: relative;
    }
    .dialog .body .text-wrap{
        text-align: center;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 80%;
    }
    .body .container {
        display: flex;
        flex-wrap: nowrap;
    }
    .body .container .w-20{
        width: 20%;
        box-sizing: border-box;
    }
    .body .container .w-60{
        width: 60%;
        box-sizing: border-box;
    }
    .body .container .space-between{
        justify-content: space-between;
        display: flex;
    }
    .body .container .header-list{
        padding: 17px 16px 14px;
        border: solid 1px #ccc;
        border-bottom: none;
        border-top: none;
    }
    .body .container .body-list {
        padding: 10px 5px;
        box-sizing: border-box;
        overflow-y: scroll;
        border: 1px solid #ccc;
        width: 100%;
        margin-bottom: 20px;
    }
    .body .container .body-list .item {
        border-bottom: 1px solid #ccc;
        padding: 14px 0px 14px 16px;
        font-size: 16px;
    }
    .body .container .body-list .item p{
        margin-bottom: 0px !important;
    }
    .body .container .body-list .item .action-icon .icon{
        margin: 0px !important;
    }
    .body .container .body-list .item:hover{
        background: rgba(0, 150, 136, 0.08);
    }
    .body .container .tab-content{
      height: 100%;
      overflow: hidden;
    }
    .body .container .tab-content .tab-header {
        border-bottom: 1px solid #ccc;
    }
    .body .container .tab-content .tab-header .left {
        padding: 5.5px 16px;
    }
    .body .container .tab-content .tab-header .left p{
        font-size: 14px;
        margin-bottom: 0px;
    }
    .body .container .tab-content .tab-header .right {
        display: flex;
    }
    .body .container .tab-content .tab-header .right .tab-item{
        min-width: 80px;
        font-size: 12px!important;
        font-weight: 500;
    }
    .body .container .tab-content .tab-header .right button{
        padding: 5.5px 16px;
        color: #00C3AC;
    }
    .body .container .tab-content .tab-header .right button.active{
        border-bottom: 2.33px solid #00C3AC;
    }
    .body .container .tab-content .tab-body{
      height: auto;
    }
    .body .container .tab-content .tab-body .action {
        margin-top: 16px;
        padding-left: 5px;
        padding-right: 10px;
    }
    .body .container .tab-content .tab-body .action button:disabled {
        opacity: 0.5;
    }
    .body .container .tab-content .tab-body .action .btn-submit{
        background: #1B9C4F;
        width: 83px;
        border-radius: 5px;
        color: #fff;
        vertical-align: middle;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        height: 28px;
    }

    .body .container .tab-content .tab-body .action .pt-px{
        padding-top: 2px;
    }
    .body .container .tab-content .tab-body .tab-one-content .header-tab p{
        line-height: 16px;
    }
    .body .container .tab-content .tab-body .content-tab{
      height: auto;
      margin-bottom: 20px;
    }
    .dialog .btn {
        margin-right: 12px;
    }
    .dialog .scrollbar {
        float: left;
        overflow-y: scroll;
    }
    .dialog .scrollbar::-webkit-scrollbar {
        width: 6px;
        background-color: #F8F8F8;
        border-radius: 5px;
    }
    .dialog .scrollbar::-webkit-scrollbar-thumb {
        background: #C6C6C6;
        border-radius: 10px;
    }
    .dialog .border-right-none{
        border-right: none !important;
    }

    .dialog-template{
        overflow: hidden;
        height: 90% !important;
    }
    .dialog-template .title-template{
        font-size: 24px !important;
    }
    .txt-color{
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.87);
    }
    .f-18{
        font-size: 18px;
        color: rgba(0,0,0,.6);
        font-weight: 600;
    }
    .f-14{
        font-size: 14px;
    }
    .has-focus{
        background: red;
    }
    .active-item{
        background: rgba(0, 150, 136, 0.08);
    }
    .mb-form-machine{
      height: 50px !important;
      display: block;
      width: 100%;
    }
    .su-title-form-add-data{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin-top: 20px;
      padding-left: 15px;
      margin-bottom: 50px;
    }
    .middle-title{
      height: 24px;
    }
    .major-title{
      height: 24px;
    }
    .body .container .body-list{
      height: 545px !important;
    }

</style>
