<template>
    <v-card class="dialog">
        <v-card-title>
            <div class="title">{{ title }}</div>
            <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="body">
            <div class="text-wrap">
                <div class="text">
                    <slot name="bodyCancelPopups">
                    </slot>
                </div>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn depressed class="btn btn-close" @click="$emit('close')"> やめる </v-btn>
            <v-btn depressed class="btn" color="primary" @click="$emit('yes')">
                取消
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    components: {},
    props: {
        text1: {
            type: String,
        },
        text2: {
            type: String,
        },
        title: {
            type: String,
        },
    },
    data: () => ({}),
    computed: {},
    created() { },
    methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
    .title {
        padding-left: 10px;
        color: #083874;
    }

    padding-bottom: 20px;

    .body {
        .text-wrap {
            .text {
                padding-left: 18px;
                width: 80%;
            }
        }
    }
    .btn {
        margin-right: 12px;
    }
    .btn-close{
        background-color: #fff !important;
        border: 1px solid #00C3AC;
        border-radius: 4px;
        color: #00C3AC;
    }
}
</style>
