import { extend, localize } from "vee-validate";
import { required, max, min } from "vee-validate/dist/rules.umd.js";
import ja from "vee-validate/dist/locale/ja.json";

// バリデーションルール
// https://vee-validate.logaretm.com/v3/guide/rules.html

localize("ja", ja);

//必須
extend("required", required);

//メール形式
const email = {
  validate(value) {
    return value.match(/^\w+([\\.-]?\w+)*[.-]?@\w+([\\.-]?\w+)*(\.\w{2,6})+$/);
  },
  message: "正しいメールを入力してください",
};

//パスワード形式
const password = {
  validate(value) {
    return typeof value === "string" && value.length >= 8;
  },
  message: "パスワードを入力してください",
};

//最大文字数
extend("max", max);

//最小文字数
extend("min", min);

//最小&最大文字数
const minmax = {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ["min", "max"],
  message: " {min}文字以上{max}文字以内で入力してください",
};

//郵便番号
const postcode = {
  validate(value) {
    return value.match(/^\d{3}-?\d{4}$/g);
  },
  message: "正しい郵便番号を入力してください",
};

//カタカナ
const kana = {
  validate(value) {
    return value.match(/^[ァ-ヶー]*$/);
  },
  message: "カタカナで入力してください",
};

//電話番号
const phone = {
  validate(value) {
    return value.match(/^0\d{1,3}-\d{1,4}-\d{4}$/);
  },
  message: "正しい電話番号を入力してください",
};

//パスワード比較
const passwordDiff = {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "パスワード欄と同様のものを入力して下さい",
};

//半角数字
const digit = {
  validate(value) {
    return value.match(/^[0-9]*$/);
  },
  message: "半角数字で入力してください",
};

//半角英数記号
const alphanumericHalfSize = {
  validate(value) {
    return value.match(/^[0-9a-zA-Zｧ-ﾝﾞﾟ!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/);
  },
  message: "半角英数記号で入力してください",
};

// 半角数記号
const numberSymbol = {
  validate(value) {
    if (!value) {
      return value.match(/^[0-9-]*$/);
    } else {
      return true;
    }
  },
  message: "半角数記号で入力してください",
};

// file type. ex: jpg, png, jpeg
const fileType = {
  validate(value, { types }) {
    if (typeof value !== "object") {
      return true;
    }
    let ext = "";
    let fileName = "";
    if (Array.isArray(types)) {
      let ret = false;
      for (let type of types) {
        ext = `.${type.trim()}`.toLowerCase();
        fileName = value.name.toLowerCase();
        if (fileName.indexOf(ext, fileName.length - ext.length) !== -1) {
          ret = true;
        }
      }
      return ret;
    } else {
      ext = `.${types.trim()}`.toLowerCase();
      fileName = value.name.toLowerCase();
      return fileName.indexOf(ext, fileName.length - ext.length) !== -1;
    }
  },
  params: ["types"],
  message: "{types}ファイルのみアップロード可能です。",
};

// mix-max pull down
const minmaxPulldown = {
  validate(value, { min, max }) {
    return parseInt(value) >= min && parseInt(value) <= max;
  },
  params: ["min", "max"],
  message: "{min}以上{max}以内で入力してください",
};

const compareToEndDate = {
  validate(value, { endDate }) {
    return value < endDate;
  },
  params: ["endDate"],
  message: "期間（終了）より前の日付を入力してください",
};

const compareToStartDate = {
  validate(value, { startDate }) {
    return value > startDate;
  },
  params: ["startDate"],
  message: "期間（開始）以降の日付を入力してください",
};

//FAX
const fax = {
  validate(value) {
    return value.match(/^0\d{1,3}-\d{1,4}-\d{4}$/);
  },
  message: "正しいFAXを入力してください",
};

const halfSizeText = {
  validate(value) {
    return value.match(/^[0-9a-zA-Z]*$/);
  },
  message: "半角英数字で入力してください",
};

extend("email", email);
extend("password", password);
extend("minmax", minmax);
extend("postcode", postcode);
extend("kana", kana);
extend("phone", phone);
extend("passwordDiff", passwordDiff);
extend("digit", digit);
extend("number-symbol", numberSymbol);
extend("file-type", fileType);
extend("minmax-pulldown", minmaxPulldown);
extend("compare-to-end-date", compareToEndDate);
extend("compare-to-start-date", compareToStartDate);
extend("alphanumericHalfSize", alphanumericHalfSize);
extend("fax", fax);
extend("halfSizeText", halfSizeText);
// test用にexport
export {
  email,
  password,
  minmax,
  postcode,
  kana,
  phone,
  passwordDiff,
  digit,
  numberSymbol,
  fileType,
  minmaxPulldown,
  compareToStartDate,
  compareToEndDate,
  alphanumericHalfSize,
  fax,
  halfSizeText,
};
