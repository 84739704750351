import Vue from "vue";
import Vuex from "vuex";
import { login } from "../../../api/modules/login";
import { resetMail } from "../../../api/modules/resetMail";
import { resetPassword } from "../../../api/modules/resetPassword";
import { LOGIN_FLAG_KEY } from "@/constants/LOGIN";

Vue.use(Vuex);

export const Login = {
  namespaced: true,

  state: {
    api_token: null,
    user: {},
    company_id: null,
  },

  mutations: {
    SET_USER(state, payload) {
      const { data, user } = payload.contents.entries;
      const { company_id } = user;
      state.user = user;
      state.user.login_id = data.login_id;
      state.company_id = company_id ? company_id : null;
      state.api_token = data.api_token;
      state.refresh_token = data.refresh_token;
    },
    LOGOUT(state) {
      state.user = {};
      state.api_token = null;
      state.refresh_token = null;
    },
    SET_RESET_TOKEN(state, payload) {
      state.api_token = payload.reset_token;
    },
    SET_COMPANY(state, payload) {
      state.company = payload;
    },
    DELETE_TOKEN(state) {
      state.api_token = null;
    },
  },

  actions: {
    /**
     * ログイン
     */
    async login({ commit }, payload) {
      const response = await login.post(payload);
      const { data } = response;
      if (data.result) {
        // local storageにフラグを追加
        localStorage.setItem(LOGIN_FLAG_KEY, 1);
        commit("SET_USER", data);
      }
      return data;
    },

    /**
     * ログアウト
     */
    logout({ commit }) {
      // local storageにフラグを削除
      localStorage.removeItem(LOGIN_FLAG_KEY);
      commit("LOGOUT");
    },

    /**
     *会社情報を登録
     */
    setCompany({ commit }, payload) {
      commit("SET_COMPANY", payload);
    },

    /**
     * リセットパスワード
     * リセットトークンを追加
     */
    setResetToken({ commit }, payload) {
      commit("SET_RESET_TOKEN", payload);
    },
    //パスワードリセット
    async resetPassword(context, payload) {
      return await resetPassword.post(payload);
    },

    /**
     * リセットメールの送信
     */
    async resetMail(context, payload) {
      return await resetMail.post(payload);
    },

    /**
     * ログインを保持
     */
    async loginReacqire({ commit, dispatch }) {
      const rs = await login.postReacuire();
      const { data } = rs;
      if (!rs.hasError) {
        commit("SET_USER", data);
        dispatch("GlobalHeader/init", null, { root: true });
      }else {
        commit("LOGOUT");
        localStorage.removeItem(LOGIN_FLAG_KEY);
        sessionStorage.removeItem("COMPANY_USER");
        sessionStorage.removeItem("GRN");
        sessionStorage.removeItem("GRN_HEADERS");
        return rs;
      }
      return data;
    },

    deleteToken({ commit }) {
      commit("DELETE_TOKEN");
    },
  },

  getters: {
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.api_token;
    },
  },
};
