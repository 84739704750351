<template>
  <v-pagination
    v-model="page"
    :length="total"
    :total-visible="7"
    color="primary"
    circle
    @input="update"
    @next="update"
    @previous="update"
  ></v-pagination>
</template>
<script>
export default {
  data: () => {
    return {
      page: 1,
    };
  },
  props: {
    current: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },
  mounted() {
    this.$watch(
      () => this.current,
      (newValue) => {
        this.page = newValue;
      },
      {
        immediate: true,
      }
    );
  },
  methods: {
    update() {
      this.$emit("pageUpdate", this.page);
    },
  },
};
</script>
