<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">
          <slot name="bodyEndPopups">
          </slot>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed outlined :color="getColor" class="btn" @click="$emit('close')">
        やめる
      </v-btn>
      <v-btn depressed class="btn" :color="getColor" @click.once="$emit('yes')">
        終了
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      warning: false
    }
  },
  props: {
    title: {
      type: String,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning = "color:#ff7b52 !important;";
    },
    getColor() {
      return this.warning = "warning";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;

  .title {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .body {
    .text-wrap {
      .text {
        padding-left: 18px;
        width: 80%;
      }
    }
  }
}
</style>
