<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          :disabled="disableBtnEdit"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mr-5"
          depressed
          small
          outlined
          color="success"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          :disabled="!valid"
          depressed
          small
          color="primary"
          :key="keySubmit"
          @click.once="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose" data-testid="btn-close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab
              v-for="(form, key) in FORMS"
              :key="key"
            >
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineFormPage1
              :editable="editable"
              :item="formValues"
              :isNewItem="isNewItem"
              :errors="errors"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              :backData="backData"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineFormPage2
              v-if="!isNewItem"
              :editable="editable"
              :item="formValues"
              :errors="errors"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineFormPage3
              v-if="!isNewItem"
              :editable="editable"
              :item="formValues"
              :errors="errors"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>
      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
          入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
        />
      </Popup>
    </template>
  </FormDialog>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import MachineFormPage1 from "./MachineFormPage1.vue";
import MachineFormPage2 from "./MachineFormPage2.vue";
import MachineFormPage3 from "./MachineFormPage3.vue";
import { OWNER_MACHINE_FLG } from "@/constants/MACHINES";
import { COMPANY_TYPE } from "@/constants/COMMON";
import { 
  MACHINE_INITAL_ITEM,
  FORM_TAB_EDIT,
  FORM_TAB_NEW
  } from "@/constants/MACHINES";
import { Store } from "@/store/Store.js";

const INITIAL_ITEM = MACHINE_INITAL_ITEM;

const TITLE = {
  NEW: "機械情報",
  EDIT: "機械情報",
};

const STORE = "Machines";

export default {
  data: () => {
    return {
      FORMS: {},
      COMPANY_TYPE,
      OWNER_MACHINE_FLG,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      formValues: INITIAL_ITEM,
      errors: {},
      backData:false,
      keySubmit:false
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineFormPage1,
    MachineFormPage2,
    MachineFormPage3,
  },
  props: {
    isNewItem: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) {
          this.editable = true;
          this.FORMS = FORM_TAB_NEW;
        } else {
          this.FORMS = FORM_TAB_EDIT;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    /**
     * mode: detail
     * get Company detail
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getMachineDetail`],
        this.editable,
      ],
      (data) => {
        if (!this.isNewItem) {
          let item = {...this.formValues};
          this.formValues = {...data[0]};
          this.beforeUpdateItem = {...data[0]};
          if(item['temp_id']) {
            this.formValues['temp_id'] = item['temp_id'];
            this.beforeUpdateItem['temp_id'] = item['temp_id'];
          }
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? TITLE.NEW : TITLE.EDIT;
    },
        /**
     * Info company_type of user login
     */
    companyType() {
      return Store.getters[`Login/getUser`]?.company_type;
    },

    /**
     * set condition disable btn edit
     */
    disableBtnEdit() {
      switch (this.companyType) {
        case this.COMPANY_TYPE.OWNER:
          if (this.formValues?.own_machine_flag === this.OWNER_MACHINE_FLG) return false;
          else return true;
        default:
          return false;
      }
    },
  },
  methods: {
    /**
     * formUpdate
     */
    formUpdate(params) {
      this.formValues = { ...this.formValues, ...params };
    },

    /**
     * onEditable
     */
    onEditable() {
      this.editable = true;
    },

    /**
     * onEditable
     */
    onDetail() {
      this.editable = false;
    },

    /**
     * updateValidate
     */
    updateValidate({ valid, errors }) {
      this.valid = valid;
      this.errors = errors;
    },

    /**
     * onSubmit
     */
    async onSubmit() {
      const apiParams = { ... this.formValues};
      const hasId = "id" in this.formValues;
      delete apiParams.temp_id;
      if(hasId) {
        apiParams.machine_inspection_items.forEach((e) => {
          if(e.check_id){
            e.id = null;
          }
          delete e.check_id;
        });
      }
      const result = await Store.dispatch(
        hasId ? `${STORE}/update` : `${STORE}/add`,
        apiParams
      );
      if (result.hasError) {
        this.keySubmit =! this.keySubmit;
        return;
      } else {
        if (this.isNewItem) {
          this.$emit("cancel");
        } else {
          this.getItems(apiParams.id);
          this.onDetail();
        }
        Store.dispatch("Toast/show", {
          status: 200,
          message: hasId ? "更新しました" : "登録しました",
        });
      }
    },


    /**
     * closeForm
     */
    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        this.formValues = { ...this.beforeUpdateItem };
        this.editable = false;
        this.backData = true;
      } else {
        this.$emit("cancel");
      }
    },

    /**
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    async getItems(id) {
      Store.dispatch(`${STORE}/getMachineDetail`, id);
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    }
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
