<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color: #083874;" class="title">ユーザー追加</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body pr-15">
      <div class="pl-4 pt-4">
        <div class="text">招待メールをお送りします。</div>
      </div>
      <div class="pl-4 pt-4">
        <div class="text"> メールアドレスが設定されていないユーザーに 招待メールは送信されません。</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="primary"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn
        depressed class="btn" color="primary" 
        :key="flagError"
        @click.once="$emit('yes')"
      >
        招待
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    title: {
      type: String,
    },
    flagError: Boolean,
  },
  computed: {
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .btn {
    margin-right: 12px;
  }
}
</style>
