import Vue from "vue";
import Vuex from "vuex";
import {machines} from "../../../api/modules/machines";

Vue.use(Vuex);

/**
 * api
 */
const ENTITY = machines;

export const Machines = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    machineDetail: {}
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_MACHINE_DETAIL(state, payload) {
      state.machineDetail = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      return response;
    },
    async getMachineDetail({ commit }, payload) {
      const response = await ENTITY.getMachineDetail(payload);
      if (response.hasError) {
        return response;
      }
      const entries= response.data.contents.entries;
      commit("SET_MACHINE_DETAIL", entries);
      return response;
    },

    async post(_context, payload) {
      return await ENTITY.post(payload);
    },

    async update(_context, payload) {
      return await ENTITY.update(payload);
    },

    async add(_context, payload) {
      return await ENTITY.add(payload);
    },


    async delete(_context, payload) {
      return await ENTITY.delete(payload);
    },

    async getListMajor(_context, payload) {
      return await ENTITY.getListMajor(payload);
    },
    async addMajor(_context, payload) {
      return await ENTITY.addMajor(payload);
    },
    async editMajor(_context, payload) {
      return await ENTITY.editMajor(payload);
    },
    async deleteMajor(_context, payload) {
      return await ENTITY.deleteMajor(payload);
    },

    async getListMiddle(_context, payload) {
      return await ENTITY.getListMiddle(payload);
    },
    async addMiddle(_context, payload) {
      return await ENTITY.addMiddle(payload);
    },
    async editMiddle(_context, payload) {
      return await ENTITY.editMiddle(payload);
    },
    async deleteMiddle(_context, payload) {
      return await ENTITY.deleteMiddle(payload);
    },
    async copyMiddle(_context, payload) {
      return await ENTITY.copyMiddle(payload);
    },

    async getImageUrl(_context, payload) {
      return await ENTITY.getImageUrl(payload);
    },
    async deleteImage(_context, payload) {
      return await ENTITY.deleteImage(payload);
    },
    async uploadImage(_context, payload) {
      return await ENTITY.uploadImage(payload);
    },

    async getListPrecaution(_context, payload) {
      return await ENTITY.getListPrecaution(payload);
    },
    async addPrecaution(_context, payload) {
      return await ENTITY.addPrecaution(payload);
    },
    async deletePrecaution(_context, payload) {
      return await ENTITY.deletePrecaution(payload);
    },

    async getListDailyItem(_context, payload) {
      return await ENTITY.getListDailyItem(payload);
    },
    async addDailyItem(_context, payload) {
      return await ENTITY.addDailyItem(payload);
    },
    async deleteDailyItem(_context, payload) {
      return await ENTITY.deleteDailyItem(payload);
    },

    async getListMonthlyItem(_context, payload) {
      return await ENTITY.getListMonthlyItem(payload);
    },

    async addTestItem(_context, payload) {
      return await ENTITY.addTestItem(payload);
    },
    async deleteTestItem(_context, payload) {
      return await ENTITY.deleteTestItem(payload);
    },
    async addMonthlyItem(_context, payload) {
      return await ENTITY.addMonthlyItem(payload);
    },
    async deleteMonthlyItem(_context, payload) {
      return await ENTITY.deleteMonthlyItem(payload);
    },
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getMachineDetail: (state) => {
      return state.machineDetail;
    }
  },
};
