import Api, { Mock } from "../api";
import mock_machine from "./mock/machines/machines.json";
import mock_machine_detail from "./mock/machines/machines_detail.json"

const MACHINE_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/machines`;
const GET_LIST_URL = `${MACHINE_BASE_URL}/get_list_machines`;
const DELETE_URL = `${MACHINE_BASE_URL}/delete_machines`;
const GET_DETAIL_URL = `${MACHINE_BASE_URL}/get_machine_info`;
const UPDATE_MACHINE_URL = `${MACHINE_BASE_URL}/update_machine_info`;
const CREATE_MACHINE_URL = `${MACHINE_BASE_URL}/add_machine_info`;

const GET_LIST_MAJOR = `${MACHINE_BASE_URL}/get_list_major`;
const ADD_MAJOR = `${MACHINE_BASE_URL}/add_major`;
const EDIT_MAJOR = `${MACHINE_BASE_URL}/edit_major`;
const DELETE_MAJOR = `${MACHINE_BASE_URL}/delete_major`;

const GET_LIST_MIDDLE = `${MACHINE_BASE_URL}/get_list_middle`;
const ADD_MIDDLE = `${MACHINE_BASE_URL}/add_middle`;
const EDIT_MIDDLE = `${MACHINE_BASE_URL}/edit_middle`;
const DELETE_MIDDLE = `${MACHINE_BASE_URL}/delete_middle`;
const COPY_MIDDLE = `${MACHINE_BASE_URL}/copy_middle`;

const GET_IMAGE_URL = `${MACHINE_BASE_URL}/get_image_url`;
const DELETE_IMAGE = `${MACHINE_BASE_URL}/delete_image`;
const UPLOAD_IMAGE = `${MACHINE_BASE_URL}/upload_image`;

const GET_LIST_PRECAUTION = `${MACHINE_BASE_URL}/get_list_precaution`;
const ADD_PRECAUTION = `${MACHINE_BASE_URL}/add_precaution`;
const DELETE_PRECAUTION = `${MACHINE_BASE_URL}/delete_precaution`;

const GET_LIST_DAILY_ITEM = `${MACHINE_BASE_URL}/get_list_daily_item`;
const ADD_DAILY_ITEM = `${MACHINE_BASE_URL}/add_daily_item`;
const DELETE_DAILY_ITEM = `${MACHINE_BASE_URL}/delete_daily_item`;

const GET_LIST_MONTHLY_ITEM = `${MACHINE_BASE_URL}/get_list_monthly_item`;

const ADD_TEST_ITEM = `${MACHINE_BASE_URL}/add_test_item`;
const DELETE_TEST_ITEM = `${MACHINE_BASE_URL}/delete_test_item`;

const ADD_MONTHLY_ITEM = `${MACHINE_BASE_URL}/add_monthly_item`;
const DELETE_MONTHLY_ITEM = `${MACHINE_BASE_URL}/delete_monthly_item`;

if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machine,
  });

  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: mock_machine_detail,
  });

  Mock.onPost(DELETE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPut(MACHINE_BASE_URL).reply(200, {
    status_code: 200,
    message: "",
    contents: {},
  });
  Mock.onPost(UPDATE_MACHINE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(CREATE_MACHINE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const machines = {
  get: (params) => {
    return Api.get(GET_LIST_URL, {params});
  },
  getMachineDetail: async (params) => {
    return Api.get(`${GET_DETAIL_URL}/${params}`);
  },
  delete: async (params) => {
    return Api.post(DELETE_URL, params);
  },
  add: (params) => {
    return Api.post(CREATE_MACHINE_URL, params);
  },
  update: async (params) => {
    return Api.post(UPDATE_MACHINE_URL, params);
  },

  getListMajor: async (params) => {
    return Api.get(GET_LIST_MAJOR, params);
  },
  addMajor: async (params) => {
    return Api.post(ADD_MAJOR, params);
  },
  editMajor: async (params) => {
    return Api.post(EDIT_MAJOR, params);
  },
  deleteMajor: async (params) => {
    return Api.post(DELETE_MAJOR, params);
  },

  getListMiddle: async (params) => {
    return Api.get(GET_LIST_MIDDLE, params);
  },
  addMiddle: async (params) => {
    return Api.post(ADD_MIDDLE, params);
  },
  editMiddle: async (params) => {
    return Api.post(EDIT_MIDDLE, params);
  },
  deleteMiddle: async (params) => {
    return Api.post(DELETE_MIDDLE, params);
  },
  copyMiddle: async (params) => {
    return Api.post(COPY_MIDDLE, params);
  },

  getImageUrl: async (params) => {
    return Api.get(GET_IMAGE_URL, params);
  },
  deleteImage: async (params) => {
    return Api.post(DELETE_IMAGE, params);
  },
  uploadImage: async (params) => {
    return Api.post(UPLOAD_IMAGE, params);
  },

  getListPrecaution: async (params) => {
    return Api.get(GET_LIST_PRECAUTION, params);
  },
  addPrecaution: async (params) => {
    return Api.post(ADD_PRECAUTION, params);
  },
  deletePrecaution: async (params) => {
    return Api.post(DELETE_PRECAUTION, params);
  },

  getListDailyItem: async (params) => {
    return Api.get(GET_LIST_DAILY_ITEM, params);
  },
  addDailyItem: async (params) => {
    return Api.post(ADD_DAILY_ITEM, params);
  },
  deleteDailyItem: async (params) => {
    return Api.post(DELETE_DAILY_ITEM, params);
  },

  getListMonthlyItem: async (params) => {
    return Api.get(GET_LIST_MONTHLY_ITEM, params);
  },

  addTestItem: async (params) => {
    return Api.post(ADD_TEST_ITEM, params);
  },
  deleteTestItem: async (params) => {
    return Api.post(DELETE_TEST_ITEM, params);
  },
  addMonthlyItem: async (params) => {
    return Api.post(ADD_MONTHLY_ITEM, params);
  },
  deleteMonthlyItem: async (params) => {
    return Api.post(DELETE_MONTHLY_ITEM, params);
  },
};
