<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" sm="6">
        <div class="pa-1 manager-edit-table-wrapper">
          <v-toolbar flat>
            <div style="margin-left: 30%">
              <Select
                v-if="editable"
                name="inspectionitems_id"
                :values="inspectionitems"
                :editable="editable"
                item_text="middle_classification"
                item_value="middle_classification_id"
                :items="INSPECTION_ITEMS"
                @onInput="onInput"
              />
            </div>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isInspectionsSelected"
              @click="register"
              color="primary"
              >登録
            </v-btn>
          </v-toolbar>
          <v-data-table
            v-model="selectedInspections"
            :headers="LEFT_TABLE_LABELS"
            :items="inspections"
            hide-default-footer
            :items-per-page="inspections.length"
            disable-sort
            class="elevation-0 v-data-table__wrapper"
            show-select
            item-key="id"
            noDataText="データがありません。"
            outlined
            @toggle-select-all="selectAllToggle"
            :style="`height:${
              mainHeight - 75
            }px;overflow-x: hidden;overflow-y: auto;`"
          >
            <template
              v-slot:[`item.data-table-select`]="{ isSelected }"
              v-if="!editable"
            >
              <v-simple-checkbox
                :value="isSelected"
                :readonly="true"
                :disabled="true"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="pa-1 manager-edit-table-wrapper site-manager-edit">
          <v-toolbar flat>
            <div style="margin-left: 400px">
              <v-btn
                v-if="editable"
                depressed
                small
                outlined
                color="success"
                @click="isShowPopup = true"
              >
                十 フリー入力
              </v-btn>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="isSiteInspectionsSelected"
              @click="remove"
              color="primary"
              >解除
            </v-btn>
          </v-toolbar>
          <div
            :style="`height:${
              mainHeight - 75
            }px;overflow-x: hidden;overflow-y: auto;`"
          >
            <v-data-table
              :id="customInspections.length == 0 ? null : 'top_table'"
              v-if="siteInspections.length > 0"
              v-model="selectedSiteInspections"
              :headers="RIGHT_TABLE_LABELS"
              :items="siteInspections"
              hide-default-footer
              :items-per-page="siteInspections.length"
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              :show-select="true"
              item-key="id"
              noDataText=""
              outlined
              @toggle-select-all="selectAllToggle"
            >
              <template
                v-slot:[`item.data-table-select`]="{ isSelected }"
                v-if="!editable"
              >
                <v-simple-checkbox
                  :value="isSelected"
                  :readonly="true"
                  :disabled="true"
                ></v-simple-checkbox>
              </template>
            </v-data-table>

            <v-data-table
              :headers="RIGHT_TABLE_LABELS_NEW"
              :items="customInspections"
              hide-default-footer
              disable-sort
              :hide-default-header="siteInspections.length > 0 ? true : false"
              :items-per-page="customInspections.length"
              :noDataText="
                siteInspections.length == 0 && customInspections.length == 0
                  ? 'データがありません。'
                  : ''
              "
              class="elevation-0 v-data-table__wrapper"
              item-key="id"
              outlined
            >
              <template
                v-if="editable"
                v-slot:[`item.inspection_items`]="{ item }"
              >
                <v-row>
                  <v-icon class="ml-3" medium @click="deleteItem(item)">
                    mdi-delete
                  </v-icon>
                  <div class="pl-10">{{ item.inspection_items }}</div>
                </v-row>
              </template>
              <template v-if="editable" v-slot:[`item.main_point`]="{ item }">
                <div class="px-5">{{ item.main_point }}</div>
              </template>
            </v-data-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <Popup :dialog="isShowPopup" width="480px">
      <PopupAdd @onCancel="closeItemForm" @onSubmit="submitForm" />
    </Popup>
  </v-container>
</template>
<script>
import {
  RIGHT_TABLE_LABELS,
  LEFT_TABLE_LABELS,
  RIGHT_TABLE_LABELS_NEW,
  INSPECTION_FREQUENCY
} from "@/constants/MACHINES";
import Select from "../elements/Select.vue";
import Popup from "@/components/common/Popup";
import PopupAdd from "./PopupAdd.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash";

export default {
  data() {
    return {
      formValues: {},
      inspections: [],
      siteInspections: [],
      selectedInspections: [],
      selectedSiteInspections: [],
      customInspections: [],
      isShowPopup: false,
      INSPECTION_ITEMS: [],
      inspectionitems: {
        inspectionitems_id: null,
      },
      RIGHT_TABLE_LABELS,
      LEFT_TABLE_LABELS,
      RIGHT_TABLE_LABELS_NEW,
      isFirst : true
    };
  },
  components: {
    Select,
    Popup,
    PopupAdd,
  },
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    this.$watch(
      () => [Store.getters[`CmnMst/getListMediumInspection`]],
      (data) => {
        if(this.isFirst){
          this.INSPECTION_ITEMS = [...data[0]];
          this.INSPECTION_ITEMS.unshift({
              middle_classification_id: null,
              middle_classification: "",
            });
          this.isFirst = false;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.formValues.temp_id,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.inspectionitems.inspectionitems_id = this.formValues.middle_classification_id;
          this.getListInspections();
        } else if (!this.editable) {
          this.inspectionitems.inspectionitems_id = this.formValues.middle_classification_id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.formValues.major_classification_id,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          this.isFirst = true;
          this.getListMediumInspection(newValue);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        this.siteInspections = this.formValues.machine_inspection_items.filter(
          (e) => e.inspection_frequency == INSPECTION_FREQUENCY.DAY && e.modified_flg != 1
        );
        this.customInspections =
          this.formValues.machine_inspection_items.filter(
            (e) => e.inspection_frequency == INSPECTION_FREQUENCY.DAY && e.modified_flg == 1
          );
        if (!this.editable) {
          this.selectedInspections = [];
          this.selectedSiteInspections = [];
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    isInspectionsSelected() {
      return this.selectedInspections.length === 0;
    },

    isSiteInspectionsSelected() {
      return this.selectedSiteInspections.length === 0;
    },
  },

  methods: {
    async getListInspections(param) {
      const params = param ? param : {
        major_classification_id: this.formValues.major_classification_id,
        middle_classification_id:
          this.inspectionitems.inspectionitems_id == null
            ? this.formValues.middle_classification_id
            : this.inspectionitems.inspectionitems_id,
      };
      const rs = await Store.dispatch(`CmnMst/getListInspections`, { params });
      if (!rs.hasError) {
        this.inspections = [...rs.data.contents.entries.daily];
        this.onFiter();
      }
    },
    register() {
      let id_array = [];
      this.siteInspections.forEach((element) => {
        id_array.push(element.inspection_id);
      });
      let _selectedInspections = _.cloneDeep(this.selectedInspections);
      const _formValues = _.cloneDeep(this.formValues);
      const _machineInspectionItems = _formValues.machine_inspection_items;
      _selectedInspections.forEach((e) => {
        let id_check = e.id;
        e.id = id_check + "check";
        e.check_id = true;
        e.inspection_frequency = INSPECTION_FREQUENCY.DAY;
        e.inspection_items = e.inspection_item;
        delete e.inspection_item;
        if (!id_array.includes(id_check)) _machineInspectionItems.push(e);
      });
      _formValues.machine_inspection_items = _machineInspectionItems;
      this.inspections = this.removeArray({
        from: this.inspections,
        remove: _selectedInspections,
      });
      this.$emit("formUpdate", _formValues);
      this.selectedInspections = [];
    },

    remove() {
      let _selectedSiteInspections = [...this.selectedSiteInspections];
      let machine_inspection_items = [...this.formValues.machine_inspection_items];
      _selectedSiteInspections.forEach((e) => {
        let index = machine_inspection_items.indexOf(e);
        if (index > -1) {
          machine_inspection_items.splice(index, 1);
        }
        e.inspection_item = e.inspection_items;
        delete e.inspection_items;
      });
      const _formValues = {...this.formValues};
      _formValues.machine_inspection_items = machine_inspection_items;
      this.inspections = [...this.inspections, ..._selectedSiteInspections];
      this.$emit("formUpdate", _formValues);
      this.selectedSiteInspections = [];
    },

    selectAllToggle() {
      if (!this.editable) {
        this.selectedInspections = [];
        this.selectedSiteInspections = [];
      }
    },
    closeItemForm() {
      this.isShowPopup = false;
    },
    submitForm(value) {
      value.inspection_frequency = INSPECTION_FREQUENCY.DAY;
      value.modified_flg = 1;
      this.closeItemForm();
      const _item = _.cloneDeep(this.formValues);
      _item.machine_inspection_items.push(value);
      this.$emit("formUpdate", _item);
    },
    deleteItem(item) {
      const _item = { ...this.formValues };
      let index = _item.machine_inspection_items.indexOf(item);
      if (index > -1) {
        _item.machine_inspection_items.splice(index, 1);
      }
      this.$emit("formUpdate", _item);
    },
    onInput({ value }) {
      this.inspectionitems.inspectionitems_id = value;
      const params = {
        major_classification_id: this.formValues.major_classification_id,
        middle_classification_id: value,
      };
      this.getListInspections(params);
    },
    onFiter() {
      this.inspections.forEach(item => {
        item['inspection_id'] = item.id;
      });
      this.inspections = this.removeArray({
        from: this.inspections,
        remove: this.siteInspections,
      });
    },
    removeArray(arrs) {
      return [...arrs.from]?.filter((from) => {
        // idが重複しないworkerのみ残す
        return ![...arrs.remove]?.some((remove) => {
          return remove.inspection_id === from.inspection_id;
        });
      });
    },

    async getListMediumInspection(id){
      await Store.dispatch(`CmnMst/getListMediumInspection`,{
        major_classification_id : id
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.manager-edit-table-wrapper.site-manager-edit {
  border-left: none;
}
#top_table {
  border-bottom: medium solid rgba(0, 0, 0, 0.12);
  border-bottom-width: thin;
}
</style>
