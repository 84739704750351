<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <!-- col 1 -->
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="所有会社" :editable="editable" required>
                        <Select
                          name="company_id"
                          :values="formValues"
                          :items="COMPANY_ITEM"
                          :editable="editable && !disableSelectCompany"
                          validation_label="所有会社"
                          validation_rules="required"
                          :autofocus="autofocus"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-row no-gutters>
                    <v-col cols="12" :sm="editable?'10':'12'" :md="editable?'10':'12'">
                      <v-list-item>
                        <v-list-item-content>
                          <Label
                            :label="editable ? '種別（機械名）' : '機械名'"
                            :editable="editable"
                            required
                          >
                            <Select
                              name="temp_id"
                              :values="formValues"
                              :editable="editable"
                              item_text="middle_classification"
                              item_value="temp_id"
                              :items="MEDIUM_ITEM"
                              validation_label="機械名"
                              validation_rules="required"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" sm="2" md="2" style="margin: auto; padding-right: 16px" v-if="editable">
                        <v-btn
                          style="min-width: 100%"
                          depressed
                          color="primary"
                          @click="onClickSearch"
                        >
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-list-item>
                    <v-list-item-content>
                      <Label
                        label="仕様・能力"
                        :editable="editable"
                        required
                      >
                        <Select
                          name="machine_id"
                          :values="formValues"
                          :items="MACHINE_ITEM"
                          :editable="editable"
                          validation_label="仕様・能力"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="formValues['middle_classification_id']">
                    <v-list-item-content>
                      <v-row no-gutters>
                        <v-col>
                          <Label label="機械画像" >
                            <v-img
                              contain
                              :src="url_image_device"
                              max-width="600px"
                              max-height="600px"
                            />
                          </Label>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>

                <!-- col 2 -->
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="管理区分" :editable="editable" required>
                        <RadioGroup
                          v-if="editable"
                          name="type"
                          :values="formValues"
                          :items="MACHINE_FIELDS_TYPE_STATUS"
                          :editable="isNewItem"
                          validation_label="管理区分"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                        <slot v-if="!editable">
                          {{
                            getRadioName(
                              MACHINE_FIELDS_TYPE_STATUS,
                              formValues["type"]
                            )
                          }}</slot
                        >
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <MachineFieldsInput
                    name="machine_fields"
                    :values="formValues"
                    :editable="editable"
                    :acceptant_number="acceptant_number"
                    :isNewItem="isNewItem"
                    :management="formValues.type"
                    @onInput="onInput"
                  />

                  <v-row no-gutters>
                    <v-col>
                      <v-list-item>
                        <v-list-item-content>
                          <Label label="数量" :editable="editable" required>
                            <InputText
                              name="quantity"
                              :values="formValues"
                              :editable="
                                formValues.type == 0 ? false : editable
                              "
                              suffix="個"
                              validation_label="数量"
                              validation_rules="required|digit"
                              @onInput="onInput"
                            />
                          </Label>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col> </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <Popup width="480px" :dialog="isShowSearchDialog">
      <SearchMachineDialog
        title="機械選択"
        :formValues="machineValues"
        @formMachineUpdate="formMachineUpdate"
        @onSearch="searchSite"
        @close="closeSearchMachineForm"
      />
    </Popup>
  </div>
</template>
<script>
import Label from "@/components/forms/elements/Label";
import InputText from "@/components/forms/elements/InputText";
import Select from "@/components/forms/elements/Select.vue";
import RadioGroup from "@/components/forms/elements/RadioGroup.vue";
import { Store } from "@/store/Store.js";
import _ from "lodash";
import {
  MACHINE_FIELDS_TYPE_STATUS,
  MACHINE_SITE_CATEGORY,
} from "@/constants/MACHINE_SITES";
import { COMPANY_TYPE } from "@/constants/COMMON";
import MachineFieldsInput from "./components/MachineFieldsInput.vue";
import Popup from "@/components/common/Popup.vue";
import SearchMachineDialog from "@/components/dialog/SearchMachineDialog.vue";

export default {
  data: () => {
    return {
      formValues: {},
      MACHINE_FIELDS_TYPE_STATUS,
      sequence_number: "",
      MACHINE_ITEM: [],
      MEDIUM_ITEM: [],
      COMPANY_ITEM : [],
      autofocus: false,
      url_image_device: '',
      isShowSearchDialog : false,
      machineValues: {
          items: [],
          keyword: "",
          selected: null,
          major_id : null
      },
      acceptant_number: "",
    };
  },
  components: {
    Label,
    InputText,
    Select,
    RadioGroup,
    MachineFieldsInput,
    Popup,
    SearchMachineDialog
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  async mounted() {
    this.getDataCompany();
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
        if (!this.isNewItem) {
          let _quantity = this.formValues.quantity;
          if (_quantity) this.formValues.quantity = _quantity.toString();
          let machine_fields = [];
          let machine_field_element = {};
          machine_field_element.acceptant_number = this.formValues.acceptant_number;
          machine_field_element.machine_number = this.formValues.machine_number;
          machine_field_element.machine_field_name = this.formValues.machine_field_name;
          machine_field_element.serial_number = this.formValues.serial_number;
          machine_field_element.inspector = this.formValues.inspector;
          machine_field_element.company_inspector = this.formValues.company_inspector;
          machine_field_element.remarks = this.formValues.remarks;
          machine_fields.push(machine_field_element);
          this.formValues.machine_fields = _.cloneDeep(machine_fields);
        }
        // set value default for 所有会社 if company is partner or rental
        if (this.isNewItem && (this.USER_LOGIN.company_type === COMPANY_TYPE.PATNER || this.USER_LOGIN.company_type === COMPANY_TYPE.RENTAL)) {
          this.formValues['company_id'] = this.USER_LOGIN.company_id;
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.editable,
      (flag) => {
        if (!flag) {
          this.getDetail();
        }
      },
      { 
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => this.editable,
      (newValue) => {
        this.autofocus = newValue;
        if (newValue && !this.isNewItem) {
          const companyNameElement = document.getElementsByTagName("input");
          companyNameElement[6].focus();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["Company/getListCompaniesHasMachine"],
      (data) => {
        this.COMPANY_ITEM = [...data];
        if(!this.isNewItem) {
          this.findCompanyPrefix(this.formValues['company_id']);
        }
      },
      { 
        immediate: true,
        deep: true
      }
    );

    this.$watch(
      () => Store.getters["CmnMst/getListMediumInspection"],
      (data) => {
        this.machineValues.items = [...data];
      },
      { 
        deep: true
      }
    );
  },

  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    /**
     * Info user login
     */
    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },
    
    /**
     * set condition disable select [所有会社]
     */
    disableSelectCompany() {
      let companyType = this.USER_LOGIN?.company_type;
      if (companyType ===  COMPANY_TYPE.PATNER || companyType ===  COMPANY_TYPE.RENTAL) return true;
      else return false;
    },
  },
  methods: {
    async getDetail(){
      await this.getListMediumInspection(
        this.formValues["company_id"],
        this.formValues["major_classification_id"]
      );
      this.getListMachine(
        this.formValues["company_id"],
        this.formValues["major_classification_id"],
        this.formValues["middle_classification_id"]
      );
      if(this.formValues["major_classification_id"]){
        this.getImageDevice(this.formValues['major_classification_id'],this.formValues['middle_classification_id']);
      }
      let _formValue = {... this.formValues};
      _formValue['temp_id'] = this.MEDIUM_ITEM.find((e => e.middle_classification_id === this.formValues.middle_classification_id
      && e.major_classification_id === this.formValues.major_classification_id ))?.temp_id;
      this.$emit("formUpdate", _formValue);
    },
    async onInput({ name, value }) {
      const formValues = { ...this.formValues };
      let custommize_field_inspections = formValues.machine_field_inspections.filter(
          (e) => e.modified_flg == 1
        );
      if (name == MACHINE_SITE_CATEGORY.TYPE) {
        if (value == 0) {
          formValues.quantity = "1";
        } else {
          formValues.quantity = null;
          let machine_fields = [...formValues.machine_fields];
          let rs = [];
          rs.push(machine_fields[0]);
          formValues.machine_fields = [...rs];
        }
      }
      if (name == MACHINE_SITE_CATEGORY.MACHINE_FIELDS && !this.isNewItem) {
        let _machine_field = value[0];
        formValues.machine_number = _machine_field.machine_number;
        formValues.machine_field_name = _machine_field.machine_field_name;
        formValues.serial_number = _machine_field.serial_number;
        formValues.inspector = _machine_field.inspector;
        formValues.remarks = _machine_field.remarks;
        formValues.company_inspector = _machine_field.company_inspector;
      }
      if (name == MACHINE_SITE_CATEGORY.QUANTITY) {
        if (parseInt(value) < 1) {
          value = null;
        }
      }
      if (name == MACHINE_SITE_CATEGORY.COMPANY_ID){
        formValues["temp_id"] = null;
        formValues["machine_id"] = null;
        formValues["middle_classification_id"] = null;
        formValues["major_classification_id"] = null;
        this.getListMediumInspection(value,null);
        this.url_image_device = '';
        this.getListMachine(value, formValues["major_classification_id"], formValues["middle_classification_id"]);
        if(!this.isNewItem) {
          this.findCompanyPrefix(value)
        }
      }
      if (name == 'temp_id') {
        formValues['middle_classification_id'] = this.MEDIUM_ITEM.find((e => e.temp_id === value))?.middle_classification_id;
        formValues['major_classification_id'] = this.MEDIUM_ITEM.find((e => e.temp_id === value))?.major_classification_id;
        formValues["machine_id"] = null;
        formValues["machine_field_inspections"] = [...custommize_field_inspections];
        await this.getListMachine(formValues['company_id'],formValues["major_classification_id"], formValues['middle_classification_id']);
        this.getImageDevice(formValues['major_classification_id'],formValues['middle_classification_id']);
      }
      if (name == MACHINE_SITE_CATEGORY.MACHINE_ID) {
        formValues["machine_field_inspections"] =
          await this.getListMachineFieldInspectionsByMachineId(value,[...custommize_field_inspections]);
        let rs = formValues.middle_classification_id && formValues.company_id;
        if (!rs){
          if (!formValues.company_id){
            formValues.company_id = this.MACHINE_ITEM.find((item) => item.id == value)?.company_id;
            await this.getListMediumInspection(formValues.company_id,formValues.major_classification_id);
            formValues['temp_id'] = this.MEDIUM_ITEM.find((e => e.middle_classification_id === formValues.middle_classification_id 
            && e.major_classification_id === formValues.major_classification_id ))?.temp_id;
          }
          if (!formValues.middle_classification_id){
            formValues.major_classification_id = this.MACHINE_ITEM.find((item) => item.id == value)?.major_classification_id;
            formValues.middle_classification_id = this.MACHINE_ITEM.find((item) => item.id == value)?.middle_classification_id;
            formValues['temp_id'] = this.MEDIUM_ITEM.find((e => e.middle_classification_id === formValues.middle_classification_id 
            && e.major_classification_id === formValues.major_classification_id ))?.temp_id;
            this.getImageDevice(formValues['major_classification_id'],formValues.middle_classification_id);
          }
          this.getListMachine(formValues['company_id'],formValues["major_classification_id"], formValues["middle_classification_id"]);
        }
      }
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    //get radio name
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    async getListMediumInspection(company_id,id) {
        const response = await Store.dispatch(
          `CmnMst/getListMediumInspectionHasMachine`,
          {
            major_classification_id : id,
            company_id : company_id,
          }
        );
        if (!response.hasError) {
          this.MEDIUM_ITEM = [...response.data.contents.entries];
        }
    },


    async getListMachine(company_id,major_id, middle_id) {
      let apiParams = {
        company_id : company_id,
        major_classification_id: major_id,
        middle_classification_id: middle_id,
      };
      const response = await Store.dispatch(`Machines/get`, apiParams);
      if (!response.hasError) {
        this.MACHINE_ITEM = [...response.data.contents.entries];
      }
    },

    async getListMachineFieldInspectionsByMachineId(id,custommize_field_inspections) {
      let rs = [];
      const response = await Store.dispatch(`Machines/getMachineDetail`, id);
      if (!response.hasError) {
        rs = [...response.data.contents.entries.machine_inspection_items];
        custommize_field_inspections.forEach((e)=>{
          rs.push(e);
        })
      }
      return rs;
    },
    async getImageDevice(major_id,middle_id) {
      let params = {
        major_classification_id : major_id,
        middle_classification_id : middle_id
      }
      if(major_id && middle_id) {
        const response = await Store.dispatch(`CmnMst/getImageDevice`, { params });
        if(!response.hasError) {
          let image_url = response.data.contents.entries.image_url;
          let be_url = process.env.VUE_APP_API_BASE_URL.replace('/api','');
          this.url_image_device = be_url + image_url;
        }
      }
    },
    async getDataCompany(){
      await Store.dispatch(`Company/getListCompaniesHasMachine`);
    },

    async onClickSearch(){
      await Store.dispatch("CmnMst/getListMediumInspection", {
        major_classification_id : null,
        middle_classification: '',
        company_id : this.formValues['company_id'],
      });
      this.isShowSearchDialog = true;
    },
    async searchSite(){
      let keyword = this.machineValues.keyword;
      await Store.dispatch("CmnMst/getListMediumInspection", {
        major_classification_id : null,
        middle_classification: keyword,
        company_id : this.formValues['company_id'],
      });
    },
    async formMachineUpdate(values) {
      this.machineValues = { ...values };
      if (this.machineValues.selected) {
        this.isShowSearchDialog = false;
        const formValues = {...this.formValues};
        formValues['temp_id'] = this.MEDIUM_ITEM.find((e => e.middle_classification_id === this.machineValues.selected 
        && e.major_classification_id === this.machineValues.major_id ))?.temp_id;
        if (formValues['temp_id'] || formValues['temp_id'] == 0){
          formValues.major_classification_id = this.machineValues.major_id;
          formValues.middle_classification_id = this.machineValues.selected;
          await this.getImageDevice(formValues['major_classification_id'],formValues['middle_classification_id']);
          this.getListMachine(formValues['company_id'],formValues["major_classification_id"], formValues["middle_classification_id"]);
        }else{
          formValues.major_classification_id = null;
          formValues.middle_classification_id = null;
          this.url_image_device = '';
        }
        formValues.machine_id = null;
        this.formValues = formValues;
        const machineValues = {
          items: [],
          middle_classification: "",
          selected: null,
          major_id : null
        };
        this.machineValues = {...machineValues};
        this.$emit("formUpdate", formValues);
      }
    },
    closeSearchMachineForm(){
      this.isShowSearchDialog = false;
      const machineValues = {
          items: [],
          middle_classification: "",
          selected: null,
          major_id : null
        };
      this.machineValues = machineValues;
    },
    findCompanyPrefix(company_id) {
      const arr = [...this.COMPANY_ITEM];
      let company_prefix = "";
      const dataFind = arr.find(item => item.id == company_id);
      if(dataFind) {
        company_prefix = dataFind.company_prefix ? dataFind.company_prefix : '';
      }
      this.acceptant_number = `${company_prefix}${this.formValues.acceptant_number}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../style/forms.scss";
</style>
