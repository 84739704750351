<template>
  <div>
    <v-container>
      <v-main v-for="(item, index) in items" :key="index">
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-col v-if="!isNewItem">
                <v-list-item :class="editable ? 'pt-2' : null">
                  <v-list-item-content>
                    <Label label="持込機械受理番号" :editable="editable">
                      <InputText
                        name="acceptant_number"
                        :values="formValues"
                        :editable="false"
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <Label
                      label="機番（機械所有者管理番号）"
                      :editable="editable"
                    >
                      <InputText
                        name="machine_number"
                        :values="item"
                        :editable="editable"
                        validation_label="機番（機械所有者管理番号）"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <Label label="管理名（呼名）" :editable="editable">
                      <InputText
                        name="machine_field_name"
                        :values="item"
                        :editable="editable"
                        validation_label="管理名（呼名）"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item>
                  <v-list-item-content>
                    <Label label="Serial Number" :editable="editable" >
                      <InputText
                        name="serial_number"
                        :values="item"
                        :editable="editable"
                        validation_label="Serial Number"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <Label label="点検会社"/>
            <v-row no-gutters>
              <v-col>
                <v-list-item>
                  <v-list-item-content class="row-position">
                  <Label label="会社">
                    <SelectWithFilter
                      class="select-position"
                      name="company_inspector"
                      :values="item"
                      :items="COMPANY_INSPECTOR"
                      :editable="editable"
                      validation_label="会社"
                      validation_rules=""
                      @onInput="onInput({ value: $event, index })"
                    />
                  </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item>
                  <v-list-item-content class="row-position">
                    <Label label="担当者">
                      <SelectWithFilter
                        class="select-position"
                        name="inspector"
                        :values="item"
                        :items="INSPECTOR_ITEM[index]"
                        :editable="editable"
                        validation_label="担当者"
                        validation_rules=""
                        @onInput="onInput({ value: $event, index })"
                      />
                    </Label>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-list-item>
              <v-list-item-content>
                <Label label="備考">
                  <InputTextArea
                    name="remarks"
                    :values="item"
                    :editable="editable"
                    validation_label="備考"
                    validation_rules=""
                    @onInput="onInput({ value: $event, index })"
                  />
                </Label>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col
            v-if="index > 0 && editable"
            cols="12"
            sm="1"
            md="1"
            class="pt-0 pb-0"
          >
            <v-btn class="mt-8" icon @click="remove(index)">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-main>
    </v-container>
  </div>
</template>
<script>

import Label from "@/components/forms/elements/Label.vue";
import Select from "@/components/forms/elements/Select.vue";
import InputTextArea from "@/components/forms/elements/InputTextArea.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import { Store } from "@/store/Store.js";
import SelectWithFilter from "@/components/forms/elements/SelectWithFilter.vue";

const INITIAL_VALUES = {
  machine_number: null,
  machine_field_name: null,
  inspector: null,
  company_inspector: null,
  serial_number: null,
  remarks: null,
};

export default {
  data: () => {
    return {
      items: [],
      INSPECTOR_ITEM : [],
      COMPANY_INSPECTOR: [],
      formValues: {
        acceptant_number: ""
      },
    };
  },
  components: {
    Label,
    Select,
    InputTextArea,
    InputText,
    SelectWithFilter
},
  props: {
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    editable: {
      type: Boolean,
    },
    management: {
      type: Number,
    },
    isNewItem: {
      type: Boolean,
    },
    acceptant_number: String,
  },

  computed: {
    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },
    LIST_USER_BY_FIELDS() {
      return Store.getters["FieldUser/getData"];
    }
  },

  mounted() {
    this.getListCompanyByField();
    /**
     * ここは他の共通コンポーネントと
     * 同じようにpropsを監視します
     */
    this.$watch(
      () => this.values.machine_fields,
      (newValue, oldValue) => {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.items = [...newValue];
          if (this.items?.length == 0) {
            this.addEmpty();
          }
          if(!this.editable && this.items?.length > 0) {
            this.getDataSelect(this.items);
          }
        }
      },
      { 
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => Store.getters["Sites/getListCompanyByField"],
      (data) => {
        this.COMPANY_INSPECTOR = [...data];
        this.COMPANY_INSPECTOR.unshift({
          id: null,
          name: "",
        });
      },
      { 
        immediate: true,
        deep: true
      }
    );
    this.$watch(
      () => this.acceptant_number,
      (data) => {
        if(!this.isNewItem) {
          this.formValues['acceptant_number'] = data;
        }
      },
      { 
        immediate: true,
        deep: true
      }
    );
  },

  methods: {
    onInput({ index, value }) {
      let _items = [...this.items];
      if(value.name == "company_inspector") {
        _items[index]["inspector"] = null;
        if(value.value) {
          this.getListInspectorItem(value.value, index);
        }else{
          const arr = [...this.INSPECTOR_ITEM];
          arr[index] = [];
          arr[index].unshift({
            id: null,
            name: "",
          });
          this.INSPECTOR_ITEM = arr;
        }
      }
      _items[index][value.name] = value.value;
      this.$emit("onInput", { name: this.name, value: _items });
    },
    remove(_index) {
      const _items = this.items.filter((_item, index) => {
        return index != _index;
      });
      this.$emit("onInput", { name: this.name, value: _items });
    },
    addEmpty() {
      const items = [...this.items];
      items.push({ ...INITIAL_VALUES });
      this.$emit("onInput", { name: this.name, value: items });
    },
    getDataSelect(items) {
      items.forEach((item, index) => {
        if(item.company_inspector) {
          this.getListInspectorItem(item.company_inspector, index);
        }
      });
    },
    async getListInspectorItem(company_inspector, index){
      let apiParams = {
        field_id: this.CURRENT_SITE.field_id,
        company_id: company_inspector
      }
      const response = await Store.dispatch(`FieldUser/get`,apiParams);
      if(!response.hasError) {
        let rs = [...this.LIST_USER_BY_FIELDS];
        rs.forEach((e)=>{
          e.name = e.name_sei + " " + e.name_mei;
        });
        const arr = [...this.INSPECTOR_ITEM];
        arr[index] = [...rs];
        arr[index].unshift({
          id: null,
          name: "",
        });
        this.INSPECTOR_ITEM = arr;
      }
    },
    async getListCompanyByField(){
      let apiParams = {
        field_id: this.CURRENT_SITE.field_id,
      }
      await Store.dispatch(`Sites/getCompanyByField`,apiParams);
    },
  },
};
</script>
<style scoped>
.row-position {
  margin-top: -30px;
  height: 108px;
  position: relative;
}

.select-position {
  width: 100%;
  position: absolute;
}
</style>