<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :addButtonTitle="TITLE_BTN_ADD"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isSetting="isSetting"
              @openRemoveDialog="openRemoveDialog"
              @openItemForm="openNewItemForm"
              @openDiaLogSetting="openSettingMachineDialog"
            >
              <SearchFormWrapper>
                <Label label="大分類">
                  <Select
                    name="major_classification_id"
                    :items="MAJOR_ITEM"
                    item_text="major_classification"
                    item_value="major_classification_id"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="機械名">
                  <Select
                    name="middle_classification_id"
                    item_text="middle_classification"
                    item_value="middle_classification_id"
                    :items="MEDIUM_ITEM"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label
                  v-if="UserLogin.company_type == COMPANY_TYPE.OWNER || UserLogin.company_type == COMPANY_TYPE.ADMIN"
                  label="所有会社">
                  <Select
                    name="company_id"
                    :items="COMPANY_ITEM"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="仕様・能力">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mr-4" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>
            <TableSortWrapper>
              <TableSort
                      :values="searchParams"
                      :sort_items="SORT_ITEMS"
                      sort_item_text="name"
                      sort_item_value="id"
                      :page_counts_options="PAGE_COUNT_OPTIONS"
                      :sort_order_options="SORT_ORDERS"
                      :total_item="searchParams.total_item"
                      @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
                    v-model="selectedItems"
                    :headers="TABLE_LABELS"
                    :items="machineList"
                    :items-per-page="searchParams.pageCount"
                    :height="tableHeight"
                    fixed-header
                    hide-default-footer
                    disable-sort
                    class="elevation-0 v-data-table__wrapper"
                    sort-by="updatedAt"
                    show-select
                    noDataText="データがありません。"
                    item-key="id"
                    @click:row="openItemForm"
            >
              <template v-slot:[`item.name`]="{ item }">
                <div>
                  {{ convertName(item.name, 60) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template
            #tableFooter
          >
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>
    <Popup :dialog="popups.isShowItemForm">
      <MachineForm
              :isNewItem="isNewItem"
              @cancel="closeItemForm"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>
    <Popup width="90%" :dialog="popups.isShowDialogSettingMachine" >
      <DialogSettingMachine
              @close="closeRemoveDialogSetting()"
              style="overflow: hidden"/>
    </Popup>
    <Popup width="640px" :dialog="popups.isShowNoticeDialog">
      <NotificationDialog @close="closeNoticeDialog" :date="popups.maintainDate" :time="popups.maintainTime" :message="popups.messageDialog" />
    </Popup>
  </div>
</template>

<script>
/**
 * (Common)
 * Table common components and functions
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout";
import TableLayout from "@/components/layout/TableLayout";
import TableHeader from "@/components/masterTable/elements/TableHeader";
import Pagination from "@/components/masterTable/elements/Pagination";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper";
import TableSort from "@/components/masterTable/elements/TableSort";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog";
import NotificationDialog from "@/views/fields/inspections/components/NotificationDialog.vue";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import DialogSettingMachine from "@/components/dialog/DialogSettingMachine";
import Popup from "@/components/common/Popup";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";

/**
 * Components and functions used by machine
 */
import InputText from "@/components/forms/elements/InputText";
import Label from "@/components/forms/elements/Label";
import Select from "@/components/forms/elements/Select";
import {
  MACHINE_TABLE_LABELS,
  MACHINE_SORT_ITEMS,
  MACHINE_INITAL_ITEM,
  OWNER_MACHINE_FLG,
} from "@/constants/MACHINES";
import {
  COMPANY_TYPE,
} from "@/constants/COMMON";
import MachineForm from "@/components/forms/machines/MachineForm.vue";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

// page_title
const PAGE_TITLE = "登録機械一覧";

// Table header label
const TABLE_LABELS = MACHINE_TABLE_LABELS;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// Sort element
const SORT_ITEMS = MACHINE_SORT_ITEMS;

// STORE
const STORE = "Machines";

// Number of tables per page
const PAGE_COUNT = 25;

// initial item
const INITIAL_ITEM = MACHINE_INITAL_ITEM;

// button add tittle
const TITLE_BTN_ADD = "新規作成";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      TITLE_BTN_ADD,
      PAGE_TITLE,
      SORT_ITEMS,
      TABLE_LABELS,
      COMPANY_ITEM: [],
      MEDIUM_ITEM: [],
      MAJOR_ITEM: [],
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      COMPANY_TYPE,
      editedItem: { ...INITIAL_ITEM },
      items: [],
      selectedItems: [],
      // searchParams
      searchParams: {
        pageCount: PAGE_COUNT,
        sort: null,
        asc: true,
        currentPage: 1,
        totalPage: 1,
      },
      // searchInputs
      searchInputs: {
        major_classification_id: null,
        middle_classification_id: null,
        company_id: null,
        word: null,
      },
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
        isShowDialogSettingMachine: false,
        isShowNoticeDialog: false,
        messageDialog: "",
        maintainDate: "",
        maintainTime: "",
      },
      machineForm: {
        editable: false
      },
      isNewItem: false,
      machine_id: null,
    };
  },
  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
    /**
     *
     * API Param
     */
    apiParams() {
      return {
        major_classification_id: this.searchParams.major_classification_id,
        middle_classification_id: this.searchParams.middle_classification_id,
        company_id: (this.UserLogin.company_type != this.COMPANY_TYPE.OWNER && this.UserLogin.company_type != this.COMPANY_TYPE.ADMIN)
                ? this.UserLogin.company_id : this.searchParams.company_id,
        name: this.searchParams.word,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      }
    },
    /**
     * user login
     */
    UserLogin() {
      return Store.state.Login.user;
    },
    isSetting() {
      return Store.state.Login.user.company_type == 9;
    },
    /**
     * format isSelectable for data list machine
     */
    machineList() {
      if (this.UserLogin.company_type === this.COMPANY_TYPE.OWNER) {
        return this.items.map(x => ({...x, isSelectable: x?.own_machine_flg === OWNER_MACHINE_FLG}));
      } else {
        return this.items;
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  components: {
    DialogSettingMachine,
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    InhousePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    Label,
    InputText,
    MachineForm,
    Select,
    NotificationDialog,
  },
  async mounted() {
    this.getNotification();
    Store.dispatch("GlobalHeader/setInHouseMenu", {
      menuId: HEADER_MENU_ITEMS_INHOUSE.MACHINES.id,
    });
    Store.dispatch("GlobalHeader/setSite", {
        field_id: null,
      });
    /**
     * (Common)
     * get data table
     */
    this.getItems();
    this.getListMajorInspection();
    this.getListCompany();
    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * set data list table
         */
        this.items = data[0];
        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters[`Company/getData`]],
      (data) => {
        this.COMPANY_ITEM = [...data[0]];
        this.COMPANY_ITEM.unshift({
          id: null,
          name: "",
        });
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },
  methods: {
    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = {...this.searchParams, ...this.searchInputs};
      this.searchParams['currentPage'] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },
    /**
     *  (Common)
     * open Remove Dialog
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    openSettingMachineDialog() {
      this.popups.isShowDialogSettingMachine = true;
    },
    closeRemoveDialog() {
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.popups.isShowRemoveDialog = false;
    },
    closeRemoveDialogSetting(){
      this.popups.isShowDialogSettingMachine = false;
    },
    /**
     * (Common)
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      if (name == "major_classification_id") {
        this.getListMediumInspection(value);
        searchInputs["middle_classification_id"] = null;
      }
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },
    /**
     * (Common)
     * Open new/detail/edit form
     */
    openNewItemForm() {
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      const machineInfo = await Store.dispatch(
              `${STORE}/getMachineDetail`,
              item.id
      );
      // Show Detail Form
      if (!machineInfo.hasError) {
        this.machine_id = item.id;
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      }
    },
    //close item form
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },
    // Remove
    async removeItems() {
      const machine_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { machine_ids });
      if (!result.hasError) {
        // get items
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      }
      // close dialog
      this.closeRemoveDialog();
    },

    /**
     *  (Common)
     * API get data
     */
    async getItems() {
      Store.dispatch(`${STORE}/get`, this.apiParams);
    },
    /**
     * get data select 大分類
     */
    async getListMajorInspection() {
      const response = await Store.dispatch(`CmnMst/getListMajorInspection`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.MAJOR_ITEM = [...entries];
        this.MAJOR_ITEM.unshift({
          major_classification_id: null,
          major_classification: "",
        });
      }
    },
    /**
     * get data select 中分類
     */
    async getListMediumInspection(id) {
      if (id) {
        const response = await Store.dispatch(
                `CmnMst/getListMediumInspection`,
                { major_classification_id : id }
        );
        if (!response.hasError) {
          const { entries } = response.data.contents;
          this.MEDIUM_ITEM = [...entries];
          this.MEDIUM_ITEM.unshift({
            middle_classification_id: null,
            middle_classification: "",
          });
        }
      } else {
        this.MEDIUM_ITEM = [];
      }
    },
    /**
     * get data select 所有会社
     */
    async getListCompany() {
      await Store.dispatch(`Company/get`);
      const response = await Store.dispatch(`Company/get`);
      if (!response.hasError) {
        const { entries } = response.data.contents;
        this.COMPANY_ITEM = [...entries];
        this.COMPANY_ITEM.unshift({
          id: null,
          name: "",
        });
      }
    },

    convertName(name, length) {
      let result = "";
      if (name) {
        result = name;
        if (name.length > length) {
          result = name.substring(0, length) + "•••";
        }
      }
      return result;
    },

    async getNotification() {
      const STORAGE_KEY = "LOGGED_IN";
      if (this.prevRoute.path=='/') {
        const response = await Store.dispatch(`CmnMst/getNotification`);
        if (!response.hasError) {
          const { entries } = response.data.contents;
          if(entries !== null) {
            this.NOTIFICATION = entries.id;
            let notifyId = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
            if (!notifyId.includes(this.NOTIFICATION)) {
              this.popups.isShowNoticeDialog = true;
              this.popups.maintainDate = entries.date;
              this.popups.maintainTime = entries.time;
              this.popups.messageDialog = entries.content;
            }
          }
        }
      }
    },

    closeNoticeDialog(status) {
      const STORAGE_KEY = "LOGGED_IN";
      if (status) {
        let notifyId = JSON.parse(localStorage.getItem(STORAGE_KEY) || '[]');
        notifyId.push(this.NOTIFICATION);
        localStorage.setItem(STORAGE_KEY, JSON.stringify(notifyId));
      }
      this.popups.isShowNoticeDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
