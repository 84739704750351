<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">現場機械リスト出力</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body">
      <v-radio-group v-model="radioGroup">
        <v-radio label="PDF" color="success" :value="2" ></v-radio>
        <v-radio label="Excel" color="success" :value="1" ></v-radio>
      </v-radio-group>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        :color="getColor"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn depressed class="btn" :color="getColor" @click.once="$emit('yes', radioGroup)">
        出力
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data: () => {
    return {
      radioGroup: 2,
    };
  },
  components: {},
  props: {
    message: {
      type: String,
    },
    title: {
      type: String,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning
        ? "color:#ff7b52 !important;"
        : "color:#1b9c4f !important";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        width: 80%;
      }
    }
  }
}
</style>
