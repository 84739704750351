import Vue from "vue";
import Vuex from "vuex";
import { globalheader } from "../../api/modules/globalheader";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

Vue.use(Vuex);

// Keep in local storage
const STORAGE_KEY = "GRN_HEADERS";
const SessionValues = () => {
  const values = {};
  const set = (key, value) => {
    let _values = { ...values };
    _values[key] = value;
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(_values));
  };
  const get = (key) => {
    const _values = JSON.parse(sessionStorage.getItem(STORAGE_KEY));
    return _values[key];
  };
  return {
    set,
    get,
  };
};

/**
 * global header store
 */

// api name entity
const ENTITY = globalheader;

export const GlobalHeader = {
  namespaced: true,

  state: {
    // master machines selected menu item id
    inhouseMenuId: HEADER_MENU_ITEMS_INHOUSE.MACHINES.id,

    // Site portal Selected menu item id
    menuSite: 0,

    //selected site
    selectedSite: {},

    // Selection list for site portal: Site list
    siteList: [],
  },

  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, payload);
    },
    SET_INHOUSE_MENU(state, payload) {
      state.inhouseMenuId = payload;
    },
    SET_SITE_LIST(state, payload) {
      state.siteList = payload;
    },
    SET_SITE(state, payload) {
      state.selectedSite = payload;
    },
  },

  actions: {
    /**
     * Run on reload ( init )
     */
    async init({ rootState, dispatch }) {

      // Get company information
      const { company } = rootState.Login;

      // Get a list of sites
      await dispatch("searchSites");

      // Get selected site
      const values = SessionValues().get("site");

      if (values) {
        // Site selection
        await dispatch("setSite", { field_id: values });
      }
      console.log(company, values); // console to fix warning
    },

    /**
     * Selected header menu for your portal
     */
    setInHouseMenu({ commit }, { menuId }) {
      commit("SET_INHOUSE_MENU", menuId);
    },

    /**
     * Get a list of sites
     */
    async searchSites({ rootState, commit }, payload) {
      //Store/Login
      const { company_id } = rootState.Login;
      //params to search
      let params = {
        company_id: company_id, 
      };

      // Site name search
      if (payload?.keyword) params.name = payload.keyword;
      const response = await ENTITY.searchSites(params);
      if (response.hasError) {
        return response;
      }
      const { entries } = response.data.contents;
      commit("SET_SITE_LIST", entries);
      return response;
    },

    /**
     * Keep selected site - set to state
     */
    setSite({ commit, state }, { field_id }) {
      const selectedSite = state.siteList.find((item) => {
        return item.field_id === field_id;
      });
      // id を保持
      SessionValues().set("site", field_id);
      commit("SET_SITE", selectedSite ? selectedSite : {field_id: null, name: "-"});
    },
  },

  getters: {
    /**
     * get list of sites
     */
    getSiteList: (state) => {
      return state.siteList;
    },
    /**
     * Selected company portal menu
     */
    getInHouseMenuId: (state) => {
      return state.inhouseMenuId;
    },
    /**
     * Selected site ( current site)
     * @param {*} state
     */
    getCurrentSite: (state) => {
      return state.selectedSite;
    },
  },
};
