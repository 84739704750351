<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ message }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed class="btn" :color="getColor" @click="$emit('OK')">
        OK
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    warning: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color:#FF7B52;" : "color:#1B9C4F";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 15px !important;
  }
  .body {
    .text-wrap {
      padding: 16px 0;
      position: relative;
      .text {
        text-align: left !important;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        width: 90%;
      }
    }
  }
  .btn {
    margin-right: 12px;
  }
}
</style>
