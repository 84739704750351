<template>
  <div></div>
</template>
<script>
export default {
  props: {
    observer: Object,
  },
  mounted() {
    this.$watch(
      () => this.observer,
      (params) => {
        this.$emit("callback", params);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
};
</script>
