<template>
  <v-snackbar
    v-model="isActive"
    :color="color"
    :timeout="timeout"
    :multi-line="true"
    top
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" color="darken-1" icon @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { Store } from "@/store/Store.js";
export default {
  data() {
    return {
      isActive: false,
      status: null,
      text: "",
      color: "primary",
      timeout: 3000,
      timeoutEvent: null,
    };
  },
  mounted() {
    this.$watch(
      () => Store.getters["Toast/getError"],
      (error) => {
        clearTimeout(this.timeoutEvent);
        const { message, isActive, status } = error;
        this.isActive = isActive;
        this.text = message;
        this.status = status;
        this.timeoutEvent = setTimeout(() => {
          Store.dispatch("Toast/destroy");
        }, this.timeout);
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },
  methods: {
    close() {
      Store.dispatch("Toast/destroy");
    },
    clear() {
      clearTimeout(this.timeoutEvent);
    },
  },
};
</script>
