<template>
  <div class="FormMain">
    <div class="FormMainBody" :style="getBodyStyle">
      <v-card flat>
        <v-card-text>
          <v-form autocomplete="off">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <!-- 会社名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="会社名" :editable="editable" required>
                        <InputText
                          name="name"
                          :autofocus="autofocus"
                          :values="formValues"
                          :editable="editable"
                          placeholder="会社名"
                          validation_label="会社名"
                          validation_rules="required|max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- フリガナ -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="フリガナ" :editable="editable" required>
                        <InputText
                          name="name_kana"
                          :values="formValues"
                          :editable="editable"
                          placeholder="フリガナ"
                          validation_label="フリガナ"
                          validation_rules="required|kana|max:255"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <!-- 種別 -->
                      <Label label="種別" :editable="editable" required>
                        <RadioGroup
                          v-if="editable"
                          name="company_type"
                          :values="formValues"
                          :items="COMPANY_TYPE_ARR"
                          :editable="!disableRadioRole"
                          validation_rules="required"
                          @onInput="onInput"
                        />
                        <span v-if="!editable">{{
                          getRadioName(COMPANY_TYPE_ARR, formValues["company_type"])
                        }}</span>
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="郵便番号" :editable="editable">
                        <InputText
                          name="postal_code"
                          :values="formValues"
                          :editable="editable"
                          placeholder="001-0010"
                          validation_label="郵便番号"
                          validation_rules="max:8|postcode"
                          @onInput="onChangePostalcode"
                        />
                      </Label>
                    </v-list-item-content>
                    <v-list-item-content>
                      <!-- 都道府県 -->
                      <Label label="都道府県" :editable="editable">
                        <Select
                          name="prefecture_id"
                          :values="formValues"
                          :items="STATE"
                          item_text="prefecture"
                          :editable="editable"
                          validation_label="都道府県"
                          validation_rules="max:127"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 市区町村 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="市区町村" :editable="editable">
                        <InputText
                          name="city"
                          :values="formValues"
                          :editable="editable"
                          placeholder="千代田区"
                          validation_label="市区町村"
                          validation_rules="max:60"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 番地・建物名 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="番地・建物名" :editable="editable">
                        <InputText
                          name="address"
                          :values="formValues"
                          :editable="editable"
                          placeholder="番地・建物名"
                          validation_label="番地・建物名"
                          validation_rules="max:120"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <!-- 電話番号 -->
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="電話番号" :editable="editable">
                        <InputText
                          name="tel"
                          :values="formValues"
                          :editable="editable"
                          placeholder="030-1234-5678"
                          validation_label="電話番号"
                          validation_rules="phone"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                    <!-- FAX -->
                    <v-list-item-content>
                      <Label label="FAX" :editable="editable">
                        <InputText
                          name="fax"
                          :values="formValues"
                          :editable="editable"
                          validation_label="FAX"
                          validation_rules="fax"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                  <Label label="持込機械受理番号"/>
                  <v-list-item>
                    <v-list-item-content>
                      <Label label="接辞" :editable="editable">
                        <InputText
                          name="company_prefix"
                          :values="formValues"
                          :editable="editable"
                          placeholder="SA"
                          validation_label="接辞"
                          :validation_rules="getRules()"
                          @onInput="onInput"
                        />
                      </Label>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import Label from "../elements/Label";
import InputText from "../elements/InputText";
import Select from "../elements/Select.vue";
import RadioGroup from "../elements/RadioGroup";
import { COMPANY_TYPE_ARR } from "@/constants/COMPANY";
import { COMPANY_TYPE } from "@/constants/COMMON";
import { Store } from "@/store/Store.js";
import { postalCodeSearch } from "@/utils/postalCodeSearch";

export default {
  data: () => {
    return {
      formValues: {},
      COMPANY_TYPE_ARR,
      COMPANY_TYPE,
      STATE: Store.state.CmnMst.constants.entries,
      autofocus: false,
    };
  },
  components: {
    Label,
    InputText,
    Select,
    RadioGroup,
  },
  props: {
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    mainHeight: {
      type: Number,
      default: 0,
    },
    formUpdate: Function,
    isNewItem: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.item,
      (newValue) => {
        this.formValues = { ...newValue };
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => this.editable,
      (newValue) => {
        this.autofocus = newValue;
        if(newValue && !this.isNewItem) {
          const companyNameElement = document.getElementsByTagName("input");
          companyNameElement[6].focus();
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.getters[`Login/getUser`]?.company_type;
    },

    /**
     * set condition disable group radio role
     */
    disableRadioRole() {
      return this.companyType === this.COMPANY_TYPE.PATNER || this.companyType === this.COMPANY_TYPE.RENTAL;
    },
  },
  methods: {
    onInput({ name, value }) {
      const formValues = { ...this.formValues };
      formValues[name] = value;
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRadioName(items, value) {
      return items.find((item) => item.value == value)?.name;
    },
    /**
     * 郵便番号から都道府県、市町村名を検索
     */
    async onChangePostalcode({ value }) {
      const formValues = { ...this.formValues };
      formValues.postal_code = value;
      if (value == "") {
        formValues.prefecture_id = null;
        formValues.city = '';
      } else if (value.match(/^\d{3}-?\d{4}$/g)) {
        const result = await postalCodeSearch.search({ postal_code: value });
        if(result){
          formValues.prefecture_id = result.prefecture.id;
          formValues.city = result.city;
        }
      }
      this.formValues = formValues;
      this.$emit("formUpdate", formValues);
    },
    getRules() {
      let rules = "";
      if(this.formValues.company_prefix) {
        rules = `halfSizeText|min:2|max:2`
      } else {
        rules = "";
      }
      return rules;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../style/forms.scss";
</style>
