export const MESSAGES = {
  PASSWORD_RESETTING_SUCCESS: "パスワードを再設定しました",
  MAIL_SEND_SUCCESS: "メールを送信しました",
};

/**
 * ログインをlocalstorageに保持し、
 * flagがある場合にはlogin_info_reacquireをたたく
 */
export const LOGIN_FLAG_KEY = "is_login_flag";

export const ROLE_LOGIN = {
  ADMINISTRATOR: { id: 1, name: "管理者" },
  INSPECTOR: { id: 0, name: "点検者" },
};
