<template>
  <v-card class="dialog">
    <v-card-title>
      <div :style="getTitleColorStyle" class="title">{{ title }}</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">{{ message }}</div>
      </div>
      <div class="text-wrap">
        <div class="text">{{ message2 }}</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        やめる
      </v-btn>
      <v-btn depressed class="btn" color="warning" @click.once="$emit('yes')">
        削除
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    message2: {
      type: String,
      default: ""
    },
    warning: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getTitleColorStyle() {
      return this.warning ? "color: #FF7B52;" : "";
    },
    getColor() {
      return this.warning ? "warning" : "primary";
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 15px !important;
  }
  .body {
    .text-wrap {
      .text {
        text-align: center;
        margin: auto;
        width: 80%;
      }
    }
  }
}
</style>
