<template>
  <v-card class="dialog">
    <v-card-title>
      <div style="color:#ff7b52 !important;" class="title">承認不可</div>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="body">
      <div class="text-wrap">
        <div class="text">自社が点検会社に設定されていない、点検実績も含まれています。</div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        depressed
        outlined
        color="warning"
        class="btn"
        @click="$emit('close')"
      >
        閉じる
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
.dialog {
  padding-bottom: 20px;
  .title {
    font-size: 12px;
  }
  .body {
    .text-wrap {
      .text {
        width: 80%;
      }
    }
  }
}
</style>
