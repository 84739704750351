<template>
  <ValidationProvider
    v-slot="{ errors, valid }"
    :vid="id"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-select
      v-model="val"
      dense
      :items="items"
      :item-text="item_text"
      :item-value="item_value"
      :disabled="!editable"
      :success="valid"
      :filled="editable"
      :error-messages="errors"
      :label="label"
      @input="$emit('onInput', { name, value: val })"
      :data-testid="testid"
      :autofocus="autofocus"
    ></v-select>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    label: {
       type: String,
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    testid: {
      type: String,
    },
    item_text: {
      type: String,
      default: "name",
    },
    item_value: {
      type: String,
      default: "id",
    },
    id: {
      type: String,
    },
  },
  data: () => {
    return {
      val: "",
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name];
      },
      { immediate: true, deep: true }
    );
  },
};
</script>