<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <InhousePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :addButtonTitle="ADD_BUTTON_TITLE"
              :isInvite="true"
              @openRemoveDialog="openRemoveDialog"
              @openInviteDialog="openInviteEmployeeDialog"
              @openItemForm="openNewItemForm"
            >
              <SearchFormWrapper>
                <Label label="氏名">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="">
                  <TabSelect
                    name="role"
                    :items="USER_ROLE"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mx-2" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              item-key="id"
              @click:row="openItemForm"
            >
              <template v-slot:[`item.user_name`]="{ item }">
                <div>
                  {{ convertName(item) }}
                </div>
              </template>
              <template v-slot:[`item.user_role`]="{ item }">
                <div>
                  {{ convertRoleName(item) }}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div style="display: inline-block;overflow: hidden; text-overflow: ellipsis; max-width:100%;">
                  {{ convertEmail(item.email) }}
                </div>
              </template>
              <template v-slot:[`item.invite_flg`]="{ item }">
                <div>
                  {{ getStatusInvite(item.invite_flg) }}
                </div>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <UserForm
        :isNewItem="isNewItem"
        @cancel="closeItemForm"
        ref="UserForm"
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowRemoveDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="removeItems()"
        title="選択項目の削除"
        text="以下を削除してもよろしいですか？"
        :items="selectedItems"
        warning
      />
    </Popup>

    <Popup width="480px" :dialog="popups.isShowInviteEmployeeDialog">
      <InviteEmployeeDialog
        :flagError="flagError"
        @close="closeInviteDialogForm()"
        @yes="inviteEmployee()"
        :items="selectedItems"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (Common)
 * Table common components and functions
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import TableHeader from "@/components/masterTable/elements/TableHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue";
import InviteEmployeeDialog from "./components/InviteEmployeeDialog.vue";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog.vue";
import InhousePortalHeader from "@/components/globalHeader/InhousePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";

/**
 * Components and functions used by user
 */
import UserForm from "@/components/forms/users/UserForm.vue";
import InputText from "@/components/forms/elements/InputText.vue";
import Label from "@/components/forms/elements/Label.vue";
import TabSelect from "@/components/forms/elements/TabSelect.vue";
import {
  USER_TABLE_LABELS,
  USER_SORT_ITEMS,
  USER_ROLE,
  INVITE_STATUS,
} from "@/constants/USER";
import { HEADER_MENU_ITEMS_INHOUSE } from "@/constants/GLOBALHEADER";

// page_title
const PAGE_TITLE = "ユーザー";

// Number of tables per page default
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// STORE
const STORE = "Users";

// Table header label
const TABLE_LABELS = USER_TABLE_LABELS;

// Sort element
const SORT_ITEMS = USER_SORT_ITEMS;

// Button add title
const ADD_BUTTON_TITLE = "新規作成";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: PAGE_TITLE };
    },
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      ADD_BUTTON_TITLE,
      INVITE_STATUS,

      /**
       * (common)
       * List data
       */
      items: [],

      /**
       * (Common)
       * list data checked
       */
      selectedItems: [],

      /**
       * (Common)
       * flag check create new user
       */
      isNewItem: false,

      // search param
      searchParams: {
        word: "",
        created_at: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
        role: [],
      },

      /**
       * (Common)
       * Popup
       */
      popups: {
        isShowItemForm: false,
        isShowRemoveDialog: false,
        isShowInviteEmployeeDialog: false,
      },
      searchInputs: {
        word: "",
        role: [],
      },
      flagError: false,
    };
  },

  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    InhousePortalHeader,
    Popup,
    ConfirmRemoveDialog,
    InputText,
    Label,
    TabSelect,
    UserForm,
    InviteEmployeeDialog,
  },

  async mounted() {
    Store.dispatch("GlobalHeader/setInHouseMenu", 
      {menuId: HEADER_MENU_ITEMS_INHOUSE.USERS.id});
    Store.dispatch("GlobalHeader/setSite", {
      field_id: null,
    });
    /**
     * (Common)
     * get data table
     */
    this.getItems();

    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * Set data list table
         */
        this.items = data[0];

        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },

    apiParams() {
      return {
        company_id: null,
        role: this.searchParams.role,
        user_name: this.searchParams.word,
        sort_value: this.searchParams.sort,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
      };
    },

    USER_ROLE() {
      return Object.keys(USER_ROLE).map((key) => {
        return USER_ROLE[key];
      });
    },
  },

  methods: {
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = {...this.searchParams, ...this.searchInputs};
      this.searchParams['currentPage'] = 1;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * (Common))
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * Open new/detail/edit form
     */
    openNewItemForm() {
      this.isNewItem = true;
      this.popups.isShowItemForm = true;
    },
    async openItemForm(item) {
      const result = await Store.dispatch(`${STORE}/getUserDetail`, item.id);
      if (!result.hasError) {
        this.isNewItem = false;
        this.popups.isShowItemForm = true;
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
      this.getItems();
    },

    /**
     *  (Common)
     * Open remove dialog
     */
    openRemoveDialog() {
      this.popups.isShowRemoveDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowRemoveDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },

    /**
     * (Common)
     * API get data
     */
    async getItems() {
      await Store.dispatch(`${STORE}/get`, this.apiParams);
    },

    // Remove
    async removeItems() {
      const user_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/delete`, { user_ids });

      if (!result.hasError) {
        // get items
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "削除しました",
        });
      } else {
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
      // close dialog
      this.closeRemoveDialog();
    },

    /**
     * convertName
     */
    convertName(item) {
      return `${item.name_sei} ${item.name_mei}`;
    },

    /**
     * convert Role name
     */
    convertRoleName(item) {
      return item.role == 0 ? `${USER_ROLE[1].name}` : `${USER_ROLE[0].name}`;
    },

    convertEmail(email) {
      return email?.length > 60 ? email.substring(0, 60) + "•••" : email;
    },

    /**
     * Get status invite of user
     */
    getStatusInvite(inviteFlg) {
      return this.INVITE_STATUS[Object.keys(this.INVITE_STATUS).find(key => this.INVITE_STATUS[key].value === inviteFlg)]?.name;
    },

    /**
     * open invite users dialog
     */
    openInviteEmployeeDialog() {
      this.popups.isShowInviteEmployeeDialog = true;
    },

    /**
     * Close invite users dialog
     */
    closeInviteDialogForm() {
      this.popups.isShowInviteEmployeeDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
      });
    },
    
    /**
     * Call API invite users
     */
    async inviteEmployee() {
      const user_ids = this.selectedItems.map((items) => items.id);
      const result = await Store.dispatch(`${STORE}/inviteUsers`, { user_ids });

      if (!result.hasError) {
        //ダイアログ閉じる
        this.closeInviteDialogForm();
        //成功したら値を更新
        this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "招待しました。",
        });
      } else {
        this.flagError = !this.flagError;
      }
    },
  },
};
</script>
