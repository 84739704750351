<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          :disabled="disableBtnEdit"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="success"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          :disabled="!valid"
          depressed
          small
          color="primary"
          :key="keyButton"
          @click.once="onSubmit"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <SitesFormPage1
              :editable="editable"
              :item="item"
              :isNewItem="isNewItem"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
        />
      </Popup>
    </template>
  </FormDialog>
</template>
<script>

import { ValidationObserver } from "vee-validate";
import ValidationCallback from "../elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import SitesFormPage1 from "@/components/forms/sites/SitesFormPage1.vue";
import _ from "lodash";
import { Store } from "@/store/Store.js";
import { ASIGNED_FIELD_FLAG } from "@/constants/SITES";
import { COMPANY_TYPE } from "@/constants/COMMON";

const TITLE = {
  NEW: "現場情報",
  EDIT: "現場情報",
};
const STORE = "Sites";
const FORMS = {
  SitesFormPage1: { id: 1, title: "基本情報" },
};

export default {
  data: () => {
    return {
      FORMS,
      COMPANY_TYPE,
      ASIGNED_FIELD_FLAG,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      beforeUpdateItem: {},
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    SitesFormPage1,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    keyButton: Boolean
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters[`${STORE}/getSitesDetail`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? TITLE.NEW : TITLE.EDIT;
    },

    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.getters[`Login/getUser`]?.company_type;
    },

    /**
     * set condition disable btn edit
     */
    disableBtnEdit() {
      switch (this.companyType) {
        case this.COMPANY_TYPE.PATNER:
        case this.COMPANY_TYPE.RENTAL:
          return true;
        case this.COMPANY_TYPE.OWNER:
          if (this.item?.asigned_field_flg === this.ASIGNED_FIELD_FLAG) return false;
          else return true;
        default:
          return false;
      }
    },
  },
  methods: {
    formUpdate(params) {
      this.$emit("formUpdate", params);
    },

    onEditable() {
      this.editable = true;
    },
    onDetail() {
      this.editable = false;
    },

    onSubmit() {
      this.$emit("submit");
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     * 
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    }
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
