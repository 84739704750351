import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const Drawer = {
  namespaced: true,

  state: {
    isActive: false,
  },

  mutations: {
    ACTIVE(state) {
      state.isActive = true;
    },
    DEACTIVE(state) {
      state.isActive = false;
    },
  },

  actions: {
    show({ commit }) {
      commit("ACTIVE");
    },
    close({ commit }) {
      commit("DEACTIVE");
    },
  },

  getters: {
    getState: (state) => {
      return state;
    },
  },
};
