import Api, { Mock } from "../api";
import field_user_list from "./mock/field_user_list.json";
import field_user from "./mock/field_user.json";

const FIELD_USER_BASE_URL = `${process.env.VUE_APP_API_BASE_URL}/fields`;
const GET_LIST_URL = `${FIELD_USER_BASE_URL}/get_list_user_fields`;
const GET_DETAIL_URL = `${FIELD_USER_BASE_URL}/get_field_user_detail`;
const UPDATE_URL = `${FIELD_USER_BASE_URL}/update_user_fields`;
const GET_LIST_LEAD_APPROVED = `${FIELD_USER_BASE_URL}/get_list_lead_approved`;
const REPRESENTATIVE = `${FIELD_USER_BASE_URL}/edit_representative_flag`;
const CHECK_REPRESENTATIVE = `${FIELD_USER_BASE_URL}/is_user_field`;
const UPDATE_CHECK_PAST = `${FIELD_USER_BASE_URL}/update_check_past`;
// .envのVUE_APP_NODE_ENV = local の場合はモックを返す
if (Mock) {
  Mock.onGet(GET_LIST_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: field_user_list,
  });
  Mock.onGet(new RegExp(`${GET_DETAIL_URL}/[\\d]+`)).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: field_user,
  });
  Mock.onPost(UPDATE_URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onPost(REPRESENTATIVE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  Mock.onGet(CHECK_REPRESENTATIVE).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
}

export const fieldUser = {
  get: (params) => {
    return Api.get(GET_LIST_URL, { params });
  },
  getFieldUserDetail: async (params) => {
    return Api.get(`${GET_DETAIL_URL}/${params}`);
  },
  update: async (params) => {
    return Api.post(UPDATE_URL, params);
  },
  getListLeadApproved: (params) => {
    return Api.get(GET_LIST_LEAD_APPROVED, { params });
  },
  representative: async (params) => {
    return Api.post(REPRESENTATIVE, params);
  },
  checkRepresentative: async (params) => {
    return Api.get(CHECK_REPRESENTATIVE, { params });
  },
  updateCheckPast: async (params) => {
    return Api.post(UPDATE_CHECK_PAST, params);
  },
};
