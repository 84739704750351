import { render, staticRenderFns } from "./GlobalDrawer.vue?vue&type=template&id=68a78f86&scoped=true&"
import script from "./GlobalDrawer.vue?vue&type=script&lang=js&"
export * from "./GlobalDrawer.vue?vue&type=script&lang=js&"
import style0 from "./GlobalDrawer.vue?vue&type=style&index=0&id=68a78f86&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68a78f86",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VIcon,VNavigationDrawer,VRow})
