import Vue from "vue";
import Vuex from "vuex";
import { file } from "@/api/modules/file";

Vue.use(Vuex);

/**
 * apiを指定
 */
const ENTITY = file;

export const File = {
  namespaced: true,

  state: {
    data: Blob,
    typeDoc: Number,
    dataTemplate: Blob,
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_TYPE_DOC(state, payload) {
      state.typeDoc = payload;
    },
    SET_DATA_TEMPLATE(state, payload) {
      state.dataTemplate = payload;
    },
  },

  actions: {
    async download({ commit }, payload) {
      const response = await ENTITY.download(payload);
      if (response.hasError) {
        return response;
      }
      const data = response.data;
      commit("SET_DATA", data);
      commit("SET_TYPE_DOC", payload.params?.file_code);
      return response;
    },
    async downloadTemplate({ commit }, payload) {
      const response = await ENTITY.download(payload);
      if (response.hasError) {
        return response;
      }
      const data = response.data;
      commit("SET_DATA_TEMPLATE", data);
      return response;
    },
    clearData({ commit }) {
      commit("SET_DATA", null);
      commit("SET_TYPE_DOC", 0);
      commit("SET_DATA_TEMPLATE", null);
    },

    async readFile( _context, payload) {
      return await ENTITY.readFile(payload);
    },

  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getTypeDoc: (state) => {
      return state.typeDoc;
    },
    getDataTemplate: (state) => {
      return state.dataTemplate;
    },
  },
};
