<template>
  <div>
    <DefaultLayout>
      <template #mainHeader>
        <SitePortalHeader />
      </template>
      <template #page="{ layoutParams }">
        <TableLayout :layoutParams="layoutParams" :hideFooter="searchParams.pageCount >= searchParams.total_item">
          <template #tableHeader="{ updateHeader }">
            <TableHeader
              ref="tableHeader"
              :isAddNew="false"
              :isUpdate="true"
              :isDelete="false"
              :isRepresentative="true"
              :pageTitle="PAGE_TITLE"
              :multiRemoveStatus="disableRemoveBtn"
              :updateHeader="updateHeader"
              :isRuleRepresentative="IS_USER_FIELD"
              @onApprove="onRepresentative"
              @onReject="onRepresentative"
              @openUpdateForm="openUpdateForm"
            >
              <SearchFormWrapper>
                <Label label="氏名">
                  <InputText
                    name="word"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="所属">
                  <Select
                    name="company_id"
                    :editable="true"
                    :values="searchInputs"
                    :items="COMPANY_LIST"
                    item_text="name"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="">
                  <TabSelect
                    name="role"
                    :items="FIELD_USER_ROLE"
                    :editable="true"
                    :values="searchInputs"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <Label label="">
                  <SwitchInput
                    name="representative_flag"
                    label="代表者のみ表示"
                    :values="searchInputs"
                    :editable="true"
                    @onInput="onChangeSearchInputs"
                  />
                </Label>
                <v-spacer></v-spacer>
                <v-btn class="mx-2" color="primary" depressed @click="onSearch">
                  検索
                </v-btn>
              </SearchFormWrapper>
            </TableHeader>
            <TableSortWrapper>
              <TableSort
                :values="searchParams"
                :sort_items="SORT_ITEMS"
                sort_item_text="name"
                sort_item_value="id"
                :page_counts_options="PAGE_COUNT_OPTIONS"
                :sort_order_options="SORT_ORDERS"
                :total_item="searchParams.total_item"
                @onInput="onChangeSortParams"
              />
            </TableSortWrapper>
          </template>
          <template #tableBody="{ tableHeight }">
            <v-data-table
              v-model="selectedItems"
              :headers="TABLE_LABELS"
              :items="items"
              :items-per-page="searchParams.pageCount"
              :height="tableHeight"
              fixed-header
              hide-default-footer
              disable-sort
              class="elevation-0 v-data-table__wrapper"
              sort-by="updatedAt"
              show-select
              noDataText="データがありません。"
              item-key="id"
            >
              <template v-slot:[`item.role`]="{ item }">
                {{ handleRole(item.role) }}
              </template>
              <template v-slot:[`item.name`]="{ item }" >
                <div class="cst-overflow-td">
                {{ handleNameUser(item) }}
                </div>
              </template>
              <template v-slot:[`item.company_name`]="{ item }" >
                <div class="cst-overflow-td">
                  {{ item.company_name }}
                </div>
              </template>
              <template v-slot:[`item.email`]="{ item }">
                <div class="cst-overflow-td-email">
                  {{ item.email }}
                </div>
              </template>
              <template v-slot:[`item.representative_flag`]="{ item }">
                {{ handleRepresentative(item.representative_flag) }}
              </template>
              <template v-slot:[`item.flag_check_past`]="{ item }">
                {{ getFlagCheckPast(item.flag_check_past) }}
              </template>
              <template v-slot:[`item.btn_dot`]="{ item }">
                <v-menu v-if="IS_MACHINE_MANAGER" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="menu-item">
                    <SwitchInput
                      name="flag_check_past"
                      label="過去日の点検を許可"
                      :values="item"
                      :editable="true"
                      @onInput="onChangeFlagCheckPast($event, item)"
                    />
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </template>
          <template #tableFooter>
            <Pagination
              :current="searchParams.currentPage"
              :total="searchParams.totalPage"
              @pageUpdate="pageUpdate"
            />
          </template>
        </TableLayout>
      </template>
    </DefaultLayout>

    <Popup :dialog="popups.isShowItemForm">
      <UpdateUserFieldForm
        @updateChecked="updateChecked"
        @cancel="closeItemForm"
        ref="UpdateUserFieldForm"
      />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowConfirmDialog">
      <ConfirmRemoveDialog
        @close="closeRemoveDialog()"
        @yes="onConfirmRepresentative"
        :message="message"
      />
    </Popup>
  </div>
</template>
<script>
/**
 * (Common)
 * Table common components and functions
 */
import { Store } from "@/store/Store.js";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import TableLayout from "@/components/layout/TableLayout.vue";
import TableHeader from "@/components/masterTable/elements/TableHeader.vue";
import Pagination from "@/components/masterTable/elements/Pagination.vue";
import SearchFormWrapper from "@/components/masterTable/elements/SearchFormWrapper.vue";
import TableSortWrapper from "@/components/masterTable/elements/TableSortWrapper.vue";
import TableSort from "@/components/masterTable/elements/TableSort.vue";
import Popup from "@/components/common/Popup.vue";
import SitePortalHeader from "@/components/globalHeader/SitePortalHeader";
import { TABLES_PER_PAGE, TABLE_SORT_ORDERS } from "@/constants/COMMON";

/**
 * Components and functions used by fieldUsers
 */
import InputText from "@/components/forms/elements/InputText.vue"; 
import Label from "@/components/forms/elements/Label.vue";
import TabSelect from "@/components/forms/elements/TabSelect";
import Select from "@/components/forms/elements/Select";
import SwitchInput from "@/components/forms/elements/SwitchInput";
import UpdateUserFieldForm from "@/components/forms/fields/fieldUsers/index";
import {
  FIELD_USER_TABLE_LABELS,
  FIELD_USER_SORT_ITEMS,
  FIELD_USER_ROLE,
  FIELD_USER_ROLE_ARRAY,
  MESSAGE_DIALOG,
  INIT_MESSAGE,
  FLAG_CHECK_PAST,
} from "@/constants/FIELD_USER";
import { HEADER_MENU_ITEMS_FIELD } from "@/constants/GLOBALHEADER";
import ConfirmRemoveDialog from "./components/ConfirmRemoveDialog.vue";

// page_title
const PAGE_TITLE = "ユーザー";

// Number of tables per page
const PAGE_COUNT = 25;

// Number of tables per page option
const PAGE_COUNT_OPTIONS = TABLES_PER_PAGE;

// sort orders
const SORT_ORDERS = TABLE_SORT_ORDERS;

// STORE
const STORE = "FieldUser";

// Table header label
const TABLE_LABELS = FIELD_USER_TABLE_LABELS;

// Sort element
const SORT_ITEMS = FIELD_USER_SORT_ITEMS;

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "現場ユーザー" };
    },
  },
  data() {
    return {
      PAGE_TITLE,
      TABLE_LABELS,
      SORT_ITEMS,
      SORT_ORDERS,
      PAGE_COUNT_OPTIONS,
      COMPANY_LIST: [],
      items: [],
      field_id: null,
      selectedItems: [],
      // searchParams
      searchParams: {
        created_at: null,
        pageCount: PAGE_COUNT,
        currentPage: 1,
        totalPage: 1,
        sort: null,
        asc: true,
      },
      popups: {
        isShowItemForm: false,
        isShowConfirmDialog: false,
      },
      message: {...INIT_MESSAGE},
      // searchInputs
      searchInputs: {
        word: null,
        company_id: null,
        role: [],
        representative_flag: 0,
      }
    };
  },

  components: {
    DefaultLayout,
    TableLayout,
    TableHeader,
    Pagination,
    SearchFormWrapper,
    TableSortWrapper,
    TableSort,
    SitePortalHeader,
    Popup,
    InputText,
    Label,
    TabSelect,
    Select,
    UpdateUserFieldForm,
    SwitchInput,
    ConfirmRemoveDialog,
  },

  async mounted() {
    Store.dispatch("GlobalHeader/setInHouseMenu", 
      {menuId: HEADER_MENU_ITEMS_FIELD.USER_FIELDS.id});
    /**
     * get data company
     */
    this.getListCompanies();

    /**
     * (Common)
     * Get data and pagination
     */
    this.$watch(
      () => [
        Store.getters[`${STORE}/getData`],
        Store.getters[`${STORE}/getPagination`],
      ],
      (data) => {
        /**
         *  (Common)
         * set data list table
         */
        this.items = data[0];

        /**
         *  (Common)
         * Pagination update
         */
        let searchParams = { ...this.searchParams };
        searchParams.totalPage = data[1].total;
        searchParams.currentPage = data[1].current;
        searchParams.total_item = data[1].total_item;
        this.searchParams = searchParams;
      },
      {
        immidiate: true,
        deep: true,
      }
    );
    
    this.$watch(
      () => [Store.getters[`Company/getData`]],
      (data) => {
        this.COMPANY_LIST = [...data[0]];
        this.COMPANY_LIST.unshift({
            id: null,
            name: "",
          });
      },
      {
        immidiate: true,
        deep: true,
      }
    );

    this.$watch(
      () => Store.getters["GlobalHeader/getCurrentSite"],
      (newValue, oldValue) => {
        if(JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.checkRepresentative();
          this.getItems();
        }
      },
      { 
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * (Common)
     * Delete button active / inactive
     * selectedItems.length === 0
     */
    disableRemoveBtn() {
      return this.selectedItems.length === 0;
    },
  
    FIELD_USER_ROLE() {
      return Object.keys(FIELD_USER_ROLE).map((key) => {
        return FIELD_USER_ROLE[key];
      });
    },

    CURRENT_SITE() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },

    IS_USER_FIELD() {
      return Store.getters[`${STORE}/getIsUserField`];
    },

    apiParams() {
      return {
        field_id: this.CURRENT_SITE.field_id,
        company_id: this.searchParams.company_id,
        user_name: this.searchParams.word,
        role: this.searchParams.role,
        sort_by: this.searchParams.asc ? 1 : 0,
        page_number: this.searchParams.currentPage,
        page_size: this.searchParams.pageCount,
        sort_value:  this.searchParams.sort,
        representative_flag: this.searchParams.representative_flag,
      };
    },

    IS_MACHINE_MANAGER() {
      return this.CURRENT_SITE.machine_manager_id == this.USER_LOGIN.id;
    }
  },

  methods: {
    /**
     * (Common)
     * Pagination event
     * @param Number
     */
    pageUpdate(n) {
      let searchParams = { ...this.searchParams };
      searchParams.currentPage = n;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * onSearch
     */
    onSearch() {
      this.searchParams = {...this.searchParams, ...this.searchInputs};
      this.searchParams['currentPage'] = 1;
      this.selectedItems = [];
      this.getItems();
    },

    /**
     * (Common)
     * onChangeSearchInputs
     * @param {name:String,value:String}
     */
    onChangeSearchInputs({ name, value }) {
      let searchInputs = { ...this.searchInputs };
      searchInputs[name] = value;
      this.searchInputs = searchInputs;
    },

    /**
     * (Common)
     * onChangeSortParams
     * @param {name:String,value:String}
     */
    onChangeSortParams({ name, value }) {
      let searchParams = { ...this.searchParams };
      searchParams[name] = value;
      this.searchParams = searchParams;
      this.getItems();
    },

    /**
     * (Common)
     * Open 編集 form
     */
    openUpdateForm() {
      this.popups.isShowItemForm = true;
    },
    closeItemForm() {
      this.popups.isShowItemForm = false;
      this.getItems();
      this.checkRepresentative();
    },

    /**
     *  (Common)
     * API get data
     */
    async getItems() {
      if(this.CURRENT_SITE.field_id) { 
        await Store.dispatch(`${STORE}/get`, this.apiParams);
      }
    },
    
    /**
     * API get list companies
     */
    async getListCompanies() {
      await Store.dispatch("Company/get");
    },

    /**
     * convert role id -> name
     */
    handleRole(role) {
      return role == FIELD_USER_ROLE_ARRAY[0].value ? "" :  FIELD_USER_ROLE_ARRAY[1].name;
    },

    /**
     * get full name
     * name_sei + name_mei
     */
    handleNameUser(item) {
      return `${item.name_sei} ${item.name_mei}`;
    },
    handleRepresentative(representative_flag) {
      return representative_flag == 1 ? "代表者" : "";
    },
    onRepresentative(flg) {
      this.message.flg = flg;
      this.message.warning = flg;
      if(flg) {
        this.message.title = MESSAGE_DIALOG.TITLE_REJECT;
        this.message.title_button = MESSAGE_DIALOG.TITLE_REJECT_BUTTON;
        this.message.text = MESSAGE_DIALOG.TITLE_REJECT_TEXT;
      } else {
        this.message.title = MESSAGE_DIALOG.TITLE_APPROVE;
        this.message.title_button = MESSAGE_DIALOG.TITLE_APPROVE_BUTTON;
        this.message.text = MESSAGE_DIALOG.TITLE_APPROVE_TEXT;
      }
      this.popups.isShowConfirmDialog = true;
    },
    closeRemoveDialog() {
      this.popups.isShowConfirmDialog = false;
      this.$nextTick(() => {
        this.selectedItems = [];
        this.message = {...INIT_MESSAGE};
      });
    },
    async onConfirmRepresentative() {
      const user_id = this.selectedItems.map((items) => items.id);
      const params = {
        field_id : this.CURRENT_SITE.field_id,
        user_id,
        representative_flag : this.message.flg ? 0 : 1
      }
      const rs = await Store.dispatch(`${STORE}/representative`, params);
      if(!rs.hasError) {
        this.getItems();
        Store.dispatch("Toast/show", {
          status: rs.status_code,
          message: "更新しました",
        });
      } else {
        Store.dispatch("Error/show", {
          status: rs.status_code,
          message: rs.message,
        });
      }
      this.closeRemoveDialog();
    },
    async checkRepresentative() {
      if(this.CURRENT_SITE.field_id) {
          const params = {
          field_id: this.CURRENT_SITE.field_id,
          user_id: this.USER_LOGIN.id
        };
        await Store.dispatch(`${STORE}/checkRepresentative`, params);
      }
    },
    updateChecked(ids){
      this.selectedItems = this.selectedItems.filter((e) =>{
        return ids.includes(e.id);
      });
    },
    async onChangeFlagCheckPast({value}, item) {
      const params = {
        user_field_id: item.user_fields_id,
        flag_check_past: value
      }
      const result = await Store.dispatch(`${STORE}/updateCheckPast`, params);
      if(!result.hasError) {
        await this.getItems();
        Store.dispatch("Toast/show", {
          status: 200,
          message: "更新しました",
        });
      }
    },
    getFlagCheckPast(id) {
      return id == FLAG_CHECK_PAST.PAST.id ? FLAG_CHECK_PAST.PAST.name : FLAG_CHECK_PAST.UN_PAST.name;
    }
  },
};
</script>
<style scoped>

.menu-item {
  height: 51px;
  width: 173px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding-left: 11px;
}

.cst-overflow-td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 20vw;
}

.cst-overflow-td-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 15vw;
}
</style>