<template>
  <div>
    <SingleLayout>
      <v-card class="mx-auto my-12 mt-0 mb-0" max-width="374" outlined>
        <div class="login-header">
          <img src="@/assets/rakuraku.png" />
        </div>
        <ValidationObserver v-slot="{ invalid }">
          <v-form ref="form" v-model="valid" lazy-validation autocomplete="off">
            <v-list-item>
              <v-list-item-content>
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="メール"
                  rules="required|email"
                >
                  <v-text-field
                    v-model="formValue.login_id"
                    :error-messages="errors"
                    :success="valid"
                    autofocus
                    dense
                    height="48px"
                    placeholder="ユーザーID"
                  ></v-text-field>
                </ValidationProvider>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="パスワード"
                  rules="required|password"
                >
                  <v-text-field
                    v-model="formValue.password"
                    :error-messages="errors"
                    :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShow ? 'text' : 'password'"
                    dense
                    height="48px"
                    placeholder="パスワード"
                    @click:append="passwordShow = !passwordShow"
                  ></v-text-field>
                </ValidationProvider>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-checkbox
                  label="ログイン情報を保存する"
                  v-model="formValue.save_info"
                  dense
                ></v-checkbox>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn
                  height="48px"
                  color="primary"
                  depressed
                  :disabled="invalid"
                  @click="Login()"
                  >ログイン</v-btn
                >
              </v-list-item-content>
            </v-list-item>
            <div class="links pt-0">
              <v-btn
                style="color: #083874"
                text
                @click="isShowResetPasswordDialog = true"
              >
                パスワードを忘れた場合はこちら</v-btn
              >
            </div>
          </v-form>
        </ValidationObserver>
      </v-card>
    </SingleLayout>
    <!-- メールリセット -->
    <Popup width="372px" :dialog="isShowResetPasswordDialog">
      <ResetMail @close="isShowResetPasswordDialog = false" />
    </Popup>
  </div>
</template>
<script>
import { Store } from "@/store/Store.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SingleLayout from "../../components/layout/SingleLayout";
import ResetMail from "./resetMail/index.vue";
import Popup from "@/components/common/Popup.vue";
import { ROLE_LOGIN } from "@/constants/LOGIN.js";

export default {
  head: {
    title() {
      return { inner: "", separator: " ", complement: "ログイン" };
    },
  },
  components: {
    SingleLayout,
    ValidationProvider,
    ValidationObserver,
    ResetMail,
    Popup,
  },
  data: () => {
    return {
      formValue: {
        login_id: "",
        password: "",
        role: ROLE_LOGIN.ADMINISTRATOR.id, // 管理者
        save_info: false,
      },
      passwordShow: false,
      isShowResetPasswordDialog: false,
    };
  },
  methods: {
    /**
     * ログイン
     */
    async Login() {
      const res = await Store.dispatch("Login/login", this.formValue);
      if ("result" in res && res.result) {
        if (res.contents.entries.user.first_fields_id){
          const field_id = res.contents.entries.user.first_fields_id;
          await Store.dispatch("GlobalHeader/searchSites", {
            keyword: "",
          });
          Store.dispatch("GlobalHeader/setSite", {
            field_id: field_id,
          });
          this.$router.push(`/field/inspections`);
        }else{
          this.$router.push(`/master/machines`);
        }

        if(this.formValue.save_info){
          localStorage.login_id=this.formValue.login_id;
          localStorage.password=this.formValue.password;
          localStorage.save_info=this.formValue.save_info;
        }
        else{
          localStorage.removeItem('login_id');
          localStorage.removeItem('password');
          localStorage.removeItem('save_info');
        }

      } else {
        Store.dispatch("Error/show", {
          status: 200,
          message: res.message,
        });
      }
    },
  },
  async mounted() {
    if (localStorage.getItem('save_info')) {
      this.formValue.save_info=localStorage.getItem('save_info');
      this.formValue.login_id=localStorage.getItem('login_id');
      this.formValue.password=localStorage.getItem('password');
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";

.login-header {
  font-size: 24px;
  font-weight: bold;
  padding: 16px;
  padding-top: 32px;
  text-align: center;
  color: #00c3ac;

  img {
    width: 240px;
  }
}

.links {
  padding: 8px;
  text-align: center;
}
</style>
