<template>
  <div :style="getBodyStyle">
    <div class="action">
      <v-btn v-if="editable" class="mx-2 mt-4" depressed small outlined color="success" @click="isShowPopup = true">
        十 フリー入力
      </v-btn>
      <v-btn :disabled="isSelectedItems" v-if="editable" class="mx-2 mt-4" depressed small outlined color="success"
        @click="deleteMachine">
        <v-icon> mdi-delete </v-icon>
      </v-btn>
    </div>
      <template>
        <v-data-table v-model="selectedItems" :headers="TABLE_LABELS" :items="items" :items-per-page="items.length"
          fixed-header hide-default-footer disable-sort :show-select="editable" @click:row="selectedRow"
          class="elevation-0 v-data-table__wrapper" :style="`height:${
          mainHeight - 75
        }px;overflow-x: hidden;overflow-y: auto;`" sort-by="updatedAt" noDataText="データがありません。"
          @toggle-select-all="selectAllToggle">
          <template v-slot:[`item.inspection_items`]="{ item }">
            <v-row style="flex-wrap: nowrap; margin-top: 0px; margin-bottom: 0px;">
              <!-- <v-icon
              class="ml-3"
              medium
              v-if="item.modified_flg == 1 && editable"
              @click="deleteItem(item)"
            >
              mdi-delete-outline
            </v-icon> -->
              <div class="ml-3">{{ convertName(item.inspection_items, 30) }}</div>
            </v-row>
          </template>
          <template v-slot:[`item.main_point`]="{ item }">
            <v-row>
              <div class="ml-3">{{ convertName(item.main_point, 60) }}</div>
            </v-row>
          </template>
        </v-data-table>
      </template>
    <Popup :dialog="isShowPopup" width="480px" :key="isShowPopup">
      <PopupAddTest :data="objectValue" @onCancel="closeItemForm" @onSubmit="submitForm" />
    </Popup>
    <Popup :dialog="isShowPopupEdit" width="480px">
      <PopupEdit :data="objectValue" @onCancel="closeItemFormEdit" @onSubmit="submitFormEdit" />
    </Popup>
  </div>
</template>
<script>
import Popup from "@/components/common/Popup";
import PopupAddTest from "@/views/fields/fieldMachines/components/PopupAddTest.vue";
import {
  INSPECTION_FREQUENCY_TABLE_LABELS,
  INSPECTION_FREQUENCY,
} from "@/constants/MACHINE_SITES";
import _ from "lodash";
import PopupEdit from "../../../../views/fields/fieldMachines/components/PopupEdit.vue";

const TABLE_LABELS = INSPECTION_FREQUENCY_TABLE_LABELS;

export default {
  data() {
    return {
      items: [],
      TABLE_LABELS,
      isShowPopup: false,
      isShowPopupEdit: false,
      selectedItems: [],
      objectValue: {
        id: null,
        inspection_items: "",
        main_point: "",
      },
    };
  },

  components: {
    Popup,
    PopupAddTest,
    PopupEdit
},
  props: {
    mainHeight: {
      type: Number,
      default: 0,
    },
    item: Object,
    editable: {
      type: Boolean,
      default: false,
    },
    itemsValues: Object,
  },
  created(){
    this.index = 0;
  },
  async mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.items = data.machine_field_inspections.filter(
          (e) => e.inspection_frequency == INSPECTION_FREQUENCY.DAY
        );
      },
      {
        immediate: true,
        deep: true,
      }
    );
    
    this.$watch(
      ()=> this.editable,
      () => {
        if (this.editable == false) this.selectedItems = [];
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },

  computed: {
    isSelectedItems() {
      return this.selectedItems.length === 0;
    },
    getBodyStyle() {
      let style = `height:${this.mainHeight}px;`;
      return style;
    },
  },

  methods: {
    closeItemForm() {
      this.isShowPopup = false;
    },
    closeItemFormEdit() {
      this.isShowPopupEdit = false;
    },
    submitForm(value) {
      value.id = 'newForm2' + this.index++;
      value.inspection_frequency = INSPECTION_FREQUENCY.DAY;
      value.modified_flg = 1;
      this.closeItemForm();
      const _item = _.cloneDeep(this.item);
      _item.machine_field_inspections.push(value);
      this.$emit("formUpdate", _item);
    },
    submitFormEdit(value) {
      this.objectValue.inspection_items = value.inspection_items;
      this.objectValue.main_point = value.main_point;
      this.closeItemFormEdit();
      const _item = _.cloneDeep(this.item);
      _item.machine_field_inspections = _item.machine_field_inspections.map((item)=>{
        if (item.id == this.objectValue.id){
          item.inspection_items = this.objectValue.inspection_items;
          item.main_point = this.objectValue.main_point;
        }
        return item;
      })
      this.$emit("formUpdate", _item);
    },
    deleteItem(item) {
      if (this.editable) {
        const _item = _.cloneDeep(this.item);
        let arr = _item.machine_field_inspections.filter(
          (e) => JSON.stringify(e) !== JSON.stringify(item)
        );
        _item.machine_field_inspections = [...arr];
        this.$emit("formUpdate", _item);
      } else {
        return;
      }
    },
    convertName(name, length) {
      return name?.length > length ? name.substring(0, length) + "•••" : name;
    },
    selectAllToggle(){

    },
    deleteMachine(){
      let array_id = [];
      let _selectedSiteInspections = _.cloneDeep(this.selectedItems);
      _selectedSiteInspections.forEach((e) => {
        array_id.push(e.id);
      });
      // this.items = this.removeArray({
      //   from: this.items,
      //   remove: _selectedSiteInspections,
      // });
      let _item = _.cloneDeep(this.item);
      array_id.forEach(id => {
        _item.machine_field_inspections = _item.machine_field_inspections.filter(item => {
          return item.id != id;
        });
      })
      this.selectedItems = [];
      this.$emit("formUpdate", _item);
    },
    removeArray(arrs) {
      return [...arrs.from]?.filter((from) => {
        // idが重複しないworkerのみ残す
        return ![...arrs.remove]?.some((remove) => {
          return remove.id === from.id;
        });
      });
    },
    selectedRow(item){
      this.objectValue = {
        id : item.id,
        inspection_items: item.inspection_items,
        main_point: item.main_point,
      }
      if (this.editable) {
        this.isShowPopupEdit = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../style/forms.scss";
</style>
