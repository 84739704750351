<template>
  <v-card style="overflow-y: hidden; height: 750px">
    <v-toolbar>
      <div style="color: green; font-size: 20px">{{ title }}</div>
      <v-spacer></v-spacer>
      <v-btn color="warning" @click="rejectConfirm" :disabled="isDisableBtnEditAprrove">承認解除</v-btn>
      <v-btn color="primary" class="ml-4" @click="approveConfirm" :disabled="isDisableBtnEditAprrove">承認</v-btn>
      <v-btn icon @click="onClickBtnClose">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-tabs v-model="tab" align-with-title>
              <v-tabs-slider color="primary"></v-tabs-slider>
              <v-tab v-for="(form, key) in INSPECTION_RESULT_TAB" :key="key">
                {{ form }}
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col>
            <h4 style="margin-right: 50px;text-align:right;margin-top : 10px">{{ CHECK_STATUS_MESSAGE.INSPECTION2 }}
            </h4>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <div>
          <v-row>
            <v-col cols="12" sm="4" md="4" style="padding-left: 80px; padding-right: 0px !important">
              <v-data-table :headers="REMARKS_LABELS" :items="dataRemark" hide-default-footer fixed-header disable-sort
                class="elevation-0 v-data-table__wrapper" noDataText="" outlined>
                <template v-slot:[`item.remarks`]="{ item }">
                  <div style="display: inline-block;overflow: hidden; text-overflow: ellipsis; max-width:100%;">
                    {{ convertString(item.remarks) }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" sm="8" md="8" style="padding-left: 0px !important">
              <v-data-table :headers="INSPECTION_FORM2_LABELS" :items="dataTable" hide-default-footer disable-sort
                fixed-header height="638px" :items-per-page="dataTable.length" class="elevation-0 v-data-table__wrapper"
                noDataText="データがありません。" outlined>
                <template v-slot:[`item.status`]="{ item }">
                  <v-icon v-if="item.status == 0" color="green">
                    mdi-check-circle</v-icon>
                  <v-icon v-if="item.status == 1" color="green">
                    mdi-checkbox-blank-circle-outline</v-icon>
                  <v-icon v-if="item.status == 2" color="green">
                    mdi-close-circle</v-icon>
                </template>
                <template v-slot:[`item.main_point`]="{ item }">
                  <div style="white-space: nowrap;overflow: hidden; text-overflow: ellipsis; max-width:550px">
                    {{ item.main_point }}
                  </div>
                </template>
                <template v-slot:[`item.inspection_items`]="{ item }">
                  <div style="white-space: nowrap;overflow: hidden; text-overflow: ellipsis; max-width:250px">
                    {{ item.inspection_items }}
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div v-if="item.inspection_result_images.length > 0"
          style="margin-left:50px;margin-top:50px;overflow:auto;height:590px">
          <div v-for="image in item.inspection_result_images" :key="image.image">
            <v-img contain :src="image.image" max-width="600px" max-height="600px" style="margin-bottom: 10px;" />
          </div>
        </div>
        <div v-if="item.inspection_result_images.length == 0" class="no-data">
          データがありません。
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="tab-3">
          <div class="form-left">
            <span class="owner-title">
              対応者
            </span>
            <div style="padding-top: 10px;">
              <input type="text" class="owner-input" 
              v-model="fullName" :disabled="true">
            </div>
            <div class="content" style="padding-bottom: 10px">
              <span class="content-title">
                内容
              </span>
              <div style="margin-top: 10px; min-height: 100%;">
                <textarea rows="10" class="content-area"
                  v-model="comment" :disabled="true">
                </textarea>
              </div>
            </div>
          </div>
          <div class="form-right" v-if="correction_images">
            <div class="left-icon" v-on:click="onePreviewImg">
              <img :src="require('../../../assets/chervon-left.svg')" class="img-left">
            </div>
            <div class="slider-img">
              <v-img contain v-bind:src="imgUrl" class="img-main"/>
            </div>
            <div class="slider-img-thmb">
              <div class="list-img">
                <img class="img-item" contain v-for="(image,key) in correction_images"
                  :key="image.image" :src="image" v-bind:data-key="key" 
                  :class="choseItemImg(key)" @click="onClickImg(image, key)"/>
              </div>
            </div>
            <div class="right-icon" v-on:click="oneNextImg">
              <img :src="require('../../../assets/chervon-right.svg')" class="img-right">
            </div>
          </div>
        </div>
      </v-tab-item>
    </v-tabs-items>
    <Popup width="480px" :dialog="isShowConfirmDialog">
      <ConfirmCloseDialog title="フォームを閉じる確認" text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。" text2="このページから移動してもよろしいですか？" @close="isShowConfirmDialog = false" @yes="closeForm" />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowDialog">
      <ConfirmDialog @close="closeDialogApproval" @yes="actionDialog" :title="popups.titleDialog"
        :message="popups.messageDialog"
        :warning="popups.nameAction == INSPECTION_RESULT_CATEGORY.REJECT_APPROVE ? true : false" />
    </Popup>
    <Popup width="480px" :dialog="popups.isShowUpdateErrorDialog">
      <UpdateStatusErrorDialog @close="closeDialogUpdateError" />
    </Popup>
  </v-card>
</template>

<script>
import ConfirmDialog from "@/views/fields/inspections/components/ConfirmDialog.vue";
import Popup from "@/components/common/Popup.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import UpdateStatusErrorDialog from "@/views/fields/inspections/components/UpdateStatusErrorDialog.vue";
import {
  INSPECTION_FORM2_LABELS,
  REMARKS_LABELS,
  ATTRIBUTE_DIALOG_ARRRAY,
  INSPECTION_RESULT_CATEGORY,
  CHECK_STATUS_MESSAGE,
  INSPECTION_RESULT_TAB,
} from "@/constants/INSPECTION";
import {
  FLAG_RELATE_FIELD,
} from "@/constants/INSPECTION";
import { Store } from "@/store/Store.js";

const STORE = "Inspections";
const URL = `${process.env.VUE_APP_API_BASE_URL}/file/read_file/`;

export default {
  data() {
    return {
      ids: [],
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      dataTable: [],
      dataRemark: [],
      tab: null,
      INSPECTION_FORM2_LABELS,
      INSPECTION_RESULT_CATEGORY,
      REMARKS_LABELS,
      imageIndex: this.imgIndex,
      imgUrl: this.imageUrl,
      comment: '',
      correction_images: [],
      fullName: '',
      popups: {
        titleDialog: "",
        messageDialog: "",
        nameAction: "",
        isShowInspecForm2: false,
        isShowDialog: false,
        isShowUpdateErrorDialog : false
      },
      CHECK_STATUS_MESSAGE,
      INSPECTION_RESULT_TAB,
      FLAG_RELATE_FIELD
    };
  },
  components: {
    Popup,
    ConfirmCloseDialog,
    ConfirmDialog,
    UpdateStatusErrorDialog
  },
  props: {
    item: Object,
    isRelateField: Number,
    isDisableBtnEditAprrove: Boolean,
    inspection_frequency: Number,
    correctionItemDetail: Object,
    imgIndex: Number | null,
    imageUrl: String | Object
  },
  mounted() {
    this.$watch(
      () => this.item,
      (data) => {
        this.dataTable = [...data.inspection_result_details];
        this.dataRemark = [{ remarks: data.remarks }];
        this.ids.push(data.id);
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  created() {
    if (this.correctionItemDetail) {
      this.comment = this.correctionItemDetail.comment;
      this.correction_images = this.correctionItemDetail.correction_images;
      if (this.correction_images) {
        this.correction_images = this.correction_images.map(item => {
          return URL + item.image;
        })
      }
      if (!this.correctionItemDetail.name_sei) this.correctionItemDetail.name_sei = '';
      if (!this.correctionItemDetail.name_mei) this.correctionItemDetail.name_mei = '';
      this.fullName = this.correctionItemDetail.name_sei + this.correctionItemDetail.name_mei;
    } else {
      this.comment = '';
      this.correction_images = [];
      this.fullName = '';
    }
    if (!this.correction_images?.length) this.correction_images = false 
  },

  computed: {
    title() {
      const textCenter = "　／　";
      return `${this.item.middle_classification ? this.item.middle_classification : ""}
      ${this.item.machine_name ? `${textCenter} ${this.item.machine_name}`: ""} 
      ${this.item.machine_field_name ?`${textCenter} ${this.item.machine_field_name}` : ""}`;
    }
  },

  methods: {
    // close form
    closeForm() {
      if (!this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
      } else {
        this.$emit("cancel");
      }
    },
    onClickBtnClose() {
      this.isClickCloseBtn = true;
      this.isShowConfirmDialog = true;
    },

    // show dialog reject item
    rejectConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.CANCEL_APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.REJECT_APPROVE;
    },
    // show dialog approve item
    approveConfirm() {
      this.popups.isShowDialog = true;
      this.popups.messageDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.message;
      this.popups.titleDialog = ATTRIBUTE_DIALOG_ARRRAY.APPROVAL.name;
      this.popups.nameAction = INSPECTION_RESULT_CATEGORY.APPROVE_ITEMS;
    },
    // close dialog reject item
    closeDialogApproval() {
      this.popups.isShowDialog = false;
    },
    // action reject or approve item
    actionDialog() {
      let action = this.popups.nameAction;
      if (action == INSPECTION_RESULT_CATEGORY.REJECT_APPROVE) {
        this.changeApproveItems(0);
      } else {
        this.changeApproveItems(1);
      }
    },

    /**
     * change approve items
     */
    async changeApproveItems(approved_status) {
      const ids = this.ids;
      const result = await Store.dispatch(`${STORE}/updateStatusByGroup`, {
        ids,
        approved_status,
        inspection_frequency: this.inspection_frequency
      });

      if (!result.hasError) {
        if(result.data.contents.entries.update_status){
          // success
          this.closeDialogApproval();
          this.$emit(approved_status == 1 ? "approveResultDetail" : "rejectResultDetail", this.ids[0], approved_status);
          Store.dispatch("Toast/show", {
            status: 200,
            message: "登録しました",
          });
        }else{
          this.closeDialogApproval();
          this.popups.isShowUpdateErrorDialog = true;
        }
      } else {
        // error
        this.closeDialogApproval();
        const { data } = result.response;
        Store.dispatch("Error/show", {
          status: data.status_code,
          message: data.message,
        });
      }
    },
    convertString(text) {
      return text?.length > 20 ? text.substring(0, 20) + "•••" : text;
    },
    closeDialogUpdateError(){
      this.popups.isShowUpdateErrorDialog = false;
    },
    oneNextImg() {
      if (this.correction_images.length) {
        this.imageIndex == this.correction_images.length - 1 ? this.imageIndex = 0 : this.imageIndex++;
        this.imgUrl = this.correction_images[this.imageIndex];
      }
    },
    onePreviewImg() {
      if (this.correction_images.length) {
        this.imageIndex == 0 ? this.imageIndex = this.correction_images.length - 1 : this.imageIndex--;
        this.imgUrl = this.correction_images[this.imageIndex];
      }
    },
    onClickImg(img, key) {
      this.imgUrl = img;
      this.imageIndex = key;
    },
    choseItemImg(key) {
      console.log(key);
      return this.imageIndex == key ? 'img-item-chose' : '';
    },
    
  },
};
</script>
<style scoped>






.no-data {
  margin-left: 42%;
  margin-top: 33px;
}
.tab-3 {
  position: relative;
  height: 100%;
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-wrap: wrap;
}

.form-left {
  margin-left: 9%;
  width: 30%;
  margin-bottom: 20px;
}

.owner-title {
  width: 49px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;

}

.owner-input {
  width: 100%;
  height: 54px;
  border-radius: 3.5px;
  border: solid 1px #CCC;
  outline: none;
  padding-left: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.content {
  padding-top: 20px;
}

.content-title {
  width: 33px;
  height: 24px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  letter-spacing: 0.15px;
}

.content-area {
  width: 100%;
  outline: none;
  border-radius: 3.5px;
  border: solid 1px #CCC;
  padding: 10px 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.form-right {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: -10px;
}

.left-icon {
  position: absolute;
  z-index: 9999;
  left: 70px;
  top: 30%;
  cursor: pointer;
}

.right-icon {
  position: absolute;
  z-index: 9999;
  right: 70px;
  top: 30%;
  cursor: pointer;
}

.slider-img {
  width: 100%;
  margin-bottom: 10px;
}

.img-main {
  height: 400px !important;
  width: 533.33px !important;
  margin: 0 auto;
}

.list-img {
 width: 533px;
 text-align: center;
 margin: 0 auto;
}
.slider-img-thmb{
  width: 100%;
}

.img-item {
  width: 20%;
  height: 100px;
  width: 100px;
  margin-left: 10px;
  cursor: pointer;
}

.img-item-chose {
  border: 5px solid #32C36C;
}
</style>
