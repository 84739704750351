<template>
  <FormDialog>
    <template #header>
      <v-app-bar flat height="48px" color="rgba(0, 0, 0, 0)">
        <v-toolbar-title color="primary">
          {{ formtitle }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!editable"
          depressed
          small
          color="primary"
          @click="onEditable"
        >
          編集
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          depressed
          small
          outlined
          color="success"
          @click="isShowConfirmDialog = true"
        >
          やめる
        </v-btn>
        <v-btn
          v-if="editable && isNewItem"
          class="mx-2"
          :disabled="!valid"
          depressed
          small
          color="primary"
          @click="onSubmit(false)"
        >
          続けて登録
        </v-btn>
        <v-btn
          v-if="editable"
          class="mx-2"
          :disabled="!valid"
          depressed
          small
          color="primary"
          :key="keyButton"
          @click.once="onSubmit(true)"
        >
          保存
        </v-btn>
        <v-btn icon @click="onClickBtnClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="(form, key) in MACHINE_SITES_FORMS" :key="key">
              {{ form.title }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>
    </template>
    <template #main="{ params }">
      <ValidationObserver ref="observer" v-slot="observer">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <MachineSitesFormPage1
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              :isNewItem="isNewItem"
              @formUpdate="formUpdate"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineSitesFormPage2
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @deleteMachine="deleteMachine"
            />
          </v-tab-item>
          <v-tab-item>
            <MachineSitesFormPage3
              :editable="editable"
              :item="item"
              :mainHeight="params.mainHeight"
              @formUpdate="formUpdate"
              @deleteMachine="deleteMachine"
            />
          </v-tab-item>
        </v-tabs-items>
        <ValidationCallback :observer="observer" @callback="updateValidate" />
      </ValidationObserver>

      <Popup width="480px" :dialog="isShowConfirmDialog">
        <ConfirmCloseDialog
          title="フォームを閉じる確認"
          text1="フォームを閉じますがよろしいですか？
入力内容は保存されません。"
          text2="このページから移動してもよろしいですか？"
          @close="onClickBtnCancelForm"
          @yes="closeForm"
        />
      </Popup>
    </template>
  </FormDialog>
</template>
<script>
import { ValidationObserver } from "vee-validate";
import ValidationCallback from "@/components/forms/elements/ValidationCallback.vue";
import Popup from "@/components/common/Popup.vue";
import FormDialog from "@/components/dialog/FormDialog.vue";
import ConfirmCloseDialog from "@/components/dialog/ConfirmCloseDialog.vue";
import MachineSitesFormPage1 from "@/components/forms/fields/fieldMachines/MachineSitesFormPage1.vue";
import MachineSitesFormPage2 from "@/components/forms/fields/fieldMachines/MachineSitesFormPage2.vue";
import MachineSitesFormPage3 from "@/components/forms/fields/fieldMachines/MachineSitesFormPage3.vue";
import _ from "lodash";
import { Store } from "@/store/Store.js";

import {
  MACHINE_SITES_TITLE_FORM,
  MACHINE_SITES_FORMS,
} from "@/constants/MACHINE_SITES";

const STORE = "MachineSites";

export default {
  data: () => {
    return {
      MACHINE_SITES_TITLE_FORM,
      MACHINE_SITES_FORMS,
      valid: false,
      tab: null,
      editable: false,
      isShowConfirmDialog: false,
      isClickCloseBtn: false,
      beforeUpdateItem: {},
    };
  },
  components: {
    ValidationObserver,
    ValidationCallback,
    Popup,
    FormDialog,
    ConfirmCloseDialog,
    MachineSitesFormPage1,
    MachineSitesFormPage2,
    MachineSitesFormPage3,
  },
  props: {
    item: Object,
    isNewItem: Boolean,
    keyButton: Boolean,
  },
  mounted() {
    this.$watch(
      () => this.isNewItem,
      (flag) => {
        if (flag) this.editable = true;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    this.$watch(
      () => [Store.getters[`${STORE}/getMachineSitesDetail`], this.editable],
      (data) => {
        if (!this.isNewItem) {
          this.beforeUpdateItem = _.cloneDeep(data[0]);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );
  },
  computed: {
    formtitle() {
      return this.isNewItem ? MACHINE_SITES_TITLE_FORM.NEW : MACHINE_SITES_TITLE_FORM.EDIT;
    },
  },
  methods: {
    formUpdate(params) {
      let _params = _.cloneDeep(params);
      let beforeUpdateItem = _.cloneDeep(this.beforeUpdateItem);
      if(_params.machine_id == beforeUpdateItem.machine_id){
        _params.machine_field_inspections.forEach((element,index) => {
          if (element.id && element.modified_flg != 1 ){
            element.id = beforeUpdateItem.machine_field_inspections[index].id;
          }
        });
      }
      this.$emit("formUpdate", _params);
    },

    onEditable() {
      this.editable = true;
    },

    onSubmit(flag) {
      this.$emit("submit", flag);
    },

    updateValidate({ valid }) {
      this.valid = valid;
    },

    closeForm() {
      if (this.editable && !this.isNewItem && !this.isClickCloseBtn) {
        this.isShowConfirmDialog = false;
        const item = _.cloneDeep(this.beforeUpdateItem);
        this.formUpdate(item);
        this.editable = false;
      } else {
        this.$emit("cancel");
      }
    },
    /**
     *
     * click button Close (X)
     */
    onClickBtnClose() {
      if (!this.editable && !this.isNewItem) {
        this.$emit("cancel");
      } else {
        this.isClickCloseBtn = true;
        this.isShowConfirmDialog = true;
      }
    },
    onClickBtnCancelForm() {
      this.isShowConfirmDialog = false;
      this.isClickCloseBtn = false;
    },
    deleteMachine(array_id) {
      this.$emit("deleteIds", array_id);
    }
  },
};
</script>

<style lang="sass" scoped>
.from-close-btn
  float: right
</style>
