<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="validation_label"
    :rules="validation_rules"
  >
    <v-switch
      v-model="val"
      :label="label"
      :disabled="!editable"
      :error-messages="errors"
      @change="$emit('onInput', { name, value: val ? 1 : 0 })"
    ></v-switch>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    values: {
      type: Object,
    },
    items: {
      type: Array,
    },
    validation_rules: {
      type: String,
      default: "",
    },
    validation_label: {
      type: String,
      default: "",
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    }
  },
  data: () => {
    return {
      val: null,
    };
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name) this.val = formValues[name] == 1 ? true : false;
      },
      { immediate: true, deep: true }
    );
  },
};
</script>
