import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const Error = {
  namespaced: true,

  state: {
    message: "",
    status: null,
    isActive: false,
  },

  mutations: {
    SET_ERROR_MESSAGE(state, payload) {
      state.message = payload.message;
      state.status = payload.status_code;
    },
    ACTIVE(state) {
      state.isActive = true;
    },
    DEACTIVE(state) {
      state.message = "";
      state.isActive = false;
    },
  },

  actions: {
    show({ commit }, payload) {
      commit("SET_ERROR_MESSAGE", payload);
      commit("ACTIVE");
    },
    destroy({ commit }) {
      commit("DEACTIVE");
    },
  },

  getters: {
    getError: (state) => {
      return state;
    },
  },
};
