import Api, { Mock } from "../api";

const PATH = "password_reset";
const URL = `${process.env.VUE_APP_API_BASE_URL}/${PATH}`;

if (Mock) {
  Mock.onPost(URL).reply(200, {
    status_code: 200,
    message: "",
    result: true,
    contents: {},
  });
  // Mock.onPost(URL).reply(200, {
  //   status_code: 500,
  //   message: "パスワード再設定ができませんでした",
  //   result: false,
  //   contents: {},
  // });
}

export const resetPassword = {
  post: (params) => {
    return Api.post(URL, params);
  },
};
