import Api from "../apiFile";

export const file = {
  download: (param) => {
    const { url, params } = param;
    return Api.get(url, { params });
  },
  readFile: (params) => {
    return Api.get(params.url, { params: params.objectParams });
  },
};
