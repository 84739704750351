const MACHINE_TABLE_LABELS = [
  {
    text: "大分類",
    align: "left",
    value: "major_classification",
    width: "20%",
  },
  {
    text: "機械名",
    value: "middle_classification",
    align: "left",
    width: "20%",
  },
  {
    text: "仕様・能力",
    align: "left",
    value: "name",
    width: "30%",
  },
  {
    text: "所有会社",
    align: "left",
    value: "company_name",
    width: "30%",
  },
];

const INSPECTION_FREQUENCY =  {
  DAY : 0 ,
  MONTH : 1
}

const MACHINE_SORT_ITEMS = [
  { id: "major_classification", name: "大分類" },
  { id: "middle_classification", name: "機械名" },
  { id: "name", name: "仕様・能力" },
  { id: "companies.name", name: "所有会社" },
];

const MACHINE_INITAL_ITEM = {
  name: null,
  company_id: null,
  user_id: null,
  major_classification_id: null,
  middle_classification_id: null,
  remarks: null,
};

const MACHINE_COMPANY_ITEM = [
  { id: 1, name: "東日本ロボティクスセンター 1" },
  { id: 2, name: "東日本ロボティクスセンター 2" },
];

const MACHINE_MEDIUM_ITEM = [
  { id: 1, name: "ブルドーザ 1" },
  { id: 2, name: "ブルドーザ 2" },
];

const MACHINE_MAJOR_ITEM = [
  { id: 1, name: "株式会社xx機械 1" },
  { id: 2, name: "株式会社xx機械 2" },
];

const MACHINE_INSPECTION_ITEMS = [
  { id: 1, name: "電線(中分類)" },
  { id: 2, name: "電線(大分類)" },
];

export const RIGHT_TABLE_LABELS = [
  { text: "点検事項", value: "inspection_items", align: "left"},
  { text: "主眼点", value: "main_point", align: "left"},
];

export const RIGHT_TABLE_LABELS_NEW = [
  { text: "点検事項", value: "inspection_items", align: "left"},
  { text: "主眼点", value: "main_point", align: "left"},
];

export const LEFT_TABLE_LABELS = [
  { text: "点検事項", value: "inspection_item", align: "left" },
  { text: "主眼点", value: "main_point", align: "left" },
];

export const FORM_TAB_NEW = {
  MachineFormPage1: { id: 1, title: "基本情報" },
};

export const FORM_TAB_EDIT = {
  MachineFormPage1: { id: 1, title: "基本情報" },
  MachineFormPage2: { id: 2, title: "作業前点検項目" },
  MachineFormPage3: { id: 3, title: "月例点検項目" },
};

export const OWNER_MACHINE_FLG = 1;

export {
  MACHINE_TABLE_LABELS,
  MACHINE_SORT_ITEMS,
  MACHINE_INITAL_ITEM,
  MACHINE_COMPANY_ITEM,
  MACHINE_MEDIUM_ITEM,
  MACHINE_MAJOR_ITEM,
  MACHINE_INSPECTION_ITEMS,
  INSPECTION_FREQUENCY
};
