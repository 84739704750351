<template>
  <div ref="self">
    <v-toolbar flat>
      <v-toolbar-title class="worksHeader-title" v-if="isTitle">
        {{ pageTitle }}
      </v-toolbar-title>
      <v-btn class="mx-3" :values="searchParams" small icon>
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
      <div v-if="isGuidanceMode" class="inputDatepicker mt-5 mr-3">
        <InputDateMonthPicker name="lead_approved_month" :editable="editable" :values="searchParams"
          @onInput="onChangeSearchParams" />
      </div>
      <div v-if="!isGuidanceMode && !isTabDate2" class="inputDatepicker mt-5 mr-3">
        <InputDatepicker name="inspection_date_from" :editable="editable" :values="searchParams"
          @onInput="onChangeSearchParams" />
      </div>
      <div v-if="isTabDate2" class="inputDatepicker mt-5 mr-3">
        <InputDateMonthPicker name="inspection_date_from" :editable="editable" :values="searchParams"
          @onInput="onChangeSearchParams" />
      </div>
      <span v-if="!isGuidanceMode" style="font-size: 25px">~</span>
      <v-btn v-if="!isGuidanceMode" class="mx-3" :values="searchParams" small icon>
        <v-icon>mdi-calendar-today</v-icon>
      </v-btn>
      <div v-if="!isGuidanceMode && !isTabDate2" class="inputDatepicker mt-5">
        <InputDatepicker name="inspection_date_to" :editable="editable" :values="searchParams"
          @onInput="onChangeSearchParams" />
      </div>
      <div v-if="isTabDate2" class="inputDatepicker mt-5">
        <InputDateMonthPicker name="inspection_date_to" :editable="editable" :values="searchParams"
          @onInput="onChangeSearchParams" />
      </div>

      <v-spacer></v-spacer>
      <v-btn class="mx-2 TableHeader__menu__icon" small icon @click="openSearch">
        <v-icon class="icon_large"> mdi-magnify </v-icon>
      </v-btn>
      <v-btn class="mx-2 mr-5 TableHeader__menu__icon" :disabled="multiSelectStatus" small icon @click="openPrint">
        <v-icon class="icon_large"> mdi-printer </v-icon>
      </v-btn>

      <v-btn v-if="!isGuidanceMode && !isTabDate2" class="ma-2 mr-5" depressed color="warning"
        :disabled="multiSelectStatus || this.isDisableBtnEditAprrove" @click="$emit('onReject')">
        承認解除
      </v-btn>
      <v-btn v-if="!isGuidanceMode && !isTabDate2" depressed class="ma-2 mr-5" color="primary"
        :disabled="multiSelectStatus || this.isDisableBtnEditAprrove" @click="$emit('onApprove')">
        承認
      </v-btn>
      <v-btn v-if="!isGuidanceMode && !isTabDate2" depressed :disabled="disableBtnAll || this.isDisableBtnEditAprrove"
        class="ma-2" color="primary" @click="$emit('onApproveAll')">
        全件承認
      </v-btn>
      <v-btn v-if="isTabDate2 && isLoginUserMain" depressed class="ma-2" color="primary" @click="$emit('onGuidance')">
        全件指導
      </v-btn>
    </v-toolbar>
    <div v-if="isShowSearch">
      <slot />
    </div>
  </div>
</template>

<script>
import InputDatepicker from "@/components/forms/elements/InputDatepicker";
import InputDateMonthPicker from "@/components/forms/elements/InputDateMonthPicker";
import { COMPANY_TYPE } from "@/constants/COMMON";
import {
  FLAG_RELATE_FIELD,
  FLAG_MACHINE_FIELD_MANAGER,
} from "@/constants/INSPECTION";
import { Store } from "@/store/Store.js";
export default {
  data: () => {
    return {
      isShowSearch: false,
      FLAG_RELATE_FIELD,
      FLAG_MACHINE_FIELD_MANAGER,
    };
  },
  props: {
    pageTitle: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    multiSelectStatus: {
      type: Boolean,
      default: false,
    },
    updateHeader: {
      type: Object,
    },
    isTitle: {
      type: Boolean,
      default: true,
    },
    searchParams: {
      type: Object,
      defauly: null,
    },
    disableBtnAll: {
      type: Boolean,
      default: true,
    },
    isRelateField: {
      type: Number,
      default: FLAG_RELATE_FIELD.NOT_RELATE_FIELD,
    },
    isMachineFieldManager: {
      type: Number,
      default: FLAG_MACHINE_FIELD_MANAGER.NOT_FLAG,
    },
  },
  computed: {
    isGuidanceMode() {
      return this.searchParams?.inspection_frequency == 2 ? true : false;
    },
    isReportDay() {
      return this.searchParams?.inspection_frequency == 0 ? true : false;
    },
    isTabDate2() {
      return this.searchParams?.inspection_frequency === 1 ? true : false;
    },
    USER_LOGIN() {
      return Store.getters[`Login/getUser`];
    },
    isLoginUserMain() {
      return this.USER_LOGIN.company_type == COMPANY_TYPE.OWNER;
    },
    isDisableBtnEditAprrove() {
      if (this.isReportDay) {
        return this.isRelateField == this.FLAG_RELATE_FIELD.NOT_RELATE_FIELD;
      } else {
        return (
          this.isMachineFieldManager == this.FLAG_MACHINE_FIELD_MANAGER.NOT_FLAG
        );
      }
    },
  },
  components: {
    InputDatepicker,
    InputDateMonthPicker,
  },
  mounted() {
    setTimeout(() => {
      this.updateHeaderHeight();
    }, 200);
  },
  methods: {
    onChangeSearchParams({ name, value }) {
      this.$emit("onInput", { name, value });
    },
    openSearch() {
      this.isShowSearch = !this.isShowSearch;
      this.$nextTick(() => {
        this.updateHeaderHeight();
      });
    },
    updateHeaderHeight() {
      const height = this.$refs.self.getBoundingClientRect().height;
      this.updateHeader.setHeaderHeight(height);
    },
    openPrint() {
      this.$emit("openPrint");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/TableHeader.scss";

.v-application--is-ltr .v-btn__content .v-icon--right,
.v-application--is-rtl .v-btn__content .v-icon--left {
  margin-left: 0px;
  margin-right: 0px;
}

.v-toolbar__title.worksHeader-title {
  font-size: 24px !important;
  font-weight: 600 !important;
}
</style>
