import Vue from "vue";
import Vuex from "vuex";
import { cmnMst } from "../../../api/modules/cmnMst";

Vue.use(Vuex);

export const CmnMst = {
  namespaced: true,

  state: {
    constants: {},
    listMajorInspection: [],
    listMediumInspection: [],
    listMajorInspectionHasMachine: [],
    listMediumInspectionHasMachine: [],
    notification: {},
  },

  mutations: {
    SET_CONSTANTS(state, payload) {
      state.constants = { ...payload };
    },
    SET_LIST_MAJOR_INSPECTION(state, payload) {
      state.listMajorInspection = [ ...payload ];
    },
    SET_LIST_MEDIUM_INSPECTION(state, payload) {
      state.listMediumInspection = [ ...payload ];
    },
    SET_LIST_MAJOR_INSPECTION_HAS_MACHINE(state, payload) {
      state.listMajorInspectionHasMachine = [ ...payload ];
    },
    SET_LIST_MEDIUM_INSPECTION_HAS_MACHINE(state, payload) {
      state.listMediumInspectionHasMachine = [ ...payload ];
    },
    SET_NOTIFICATION(state, payload) {
      state.notification = { ...payload };
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await cmnMst.get(payload);
      commit("SET_CONSTANTS", response.data.contents);
      return response;
    },
    async getListInspections(_context, payload) {
      return await cmnMst.getListInspections(payload);
    },
    async getListMajorInspection({ commit }) {
      const response = await cmnMst.getListMajorInspection();
      if(!response.hasError) {
        const { entries } = response.data.contents;
        commit("SET_LIST_MAJOR_INSPECTION", entries);
      }
      return response;
    },
    async getListMediumInspection({ commit }, payload) {
      const response = await cmnMst.getListMediumInspection(payload);
      if(!response.hasError) {
        const { entries } = response.data.contents;
        commit("SET_LIST_MEDIUM_INSPECTION", entries);
      }
      return response;
    },
    async getImageDevice(_context, payload) {
      return await cmnMst.getImageDevice(payload);
    },
    async getListMajorInspectionHasMachine({ commit }, payload) {
      const response = await cmnMst.getListMajorInspectionHasMachine(payload);
      if(!response.hasError) {
        const { entries } = response.data.contents;
        commit("SET_LIST_MAJOR_INSPECTION_HAS_MACHINE", entries);
      }
      return response;
    },
    async getListMediumInspectionHasMachine({ commit }, payload) {
      const response = await cmnMst.getListMediumInspectionHasMachine(payload);
      if(!response.hasError) {
        const { entries } = response.data.contents;
        commit("SET_LIST_MEDIUM_INSPECTION_HAS_MACHINE", entries);
      }
      return response;
    },
    async getNotification({ commit }, payload) {
      const response = await cmnMst.getNotification(payload);
      if(!response.hasError) {
        const { entries } = response.data.contents;
        commit("SET_NOTIFICATION", entries);
      }
      return response;
    },
  },

  getters: {
    get: (state) => {
      return state.constants;
    },
    getListMediumInspection: (state) => {
      return state.listMediumInspection;
    },
    getListMajorInspection : (state) => {
      return state.listMajorInspection;
    },
    getListMediumInspectionHasMachine: (state) => {
      return state.listMediumInspectionHasMachine;
    },
    getListMajorInspectionHasMachine: (state) => {
      return state.listMajorInspectionHasMachine;
    },
    getNotification: (state) => {
      return state.notification;
    }
  },
};
