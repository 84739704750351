<template>
  <div>
    <v-app-bar class="portalHeader" dense>
      <!-- 自社ポータルグローバルヘッダー -->
      <!-- drawer -->
      <v-btn icon @click="openDrawer">
        <v-app-bar-nav-icon></v-app-bar-nav-icon>
      </v-btn>
          <!-- 現場選択ダイアログ -->
          <div class="portalHeader-selectSite">
            <v-btn dense filled depressed @click="openSearchDialog">
              <!-- 現場名 -->
              <div class="sites_name_wrapper">
                {{ CurrentSite ? CurrentSite.name : "-" }}
              </div>
              <v-spacer></v-spacer>
              <v-icon dark right> mdi-menu-down </v-icon>
            </v-btn>
          </div>
          <!-- タイトル -->
          <span class="portalHeader-title">
            <img class="portalHeader-logo" src="@/assets/rakuraku.png"  @click="onClickLogo"/>
            <!-- 日付 -->
            <div class="portalHeader-date">
              {{ getFormattedDate() }}
            </div>
            <v-spacer></v-spacer>
            <div class="portalHeader-label">（マスター登録）</div>
          </span>
          <v-spacer></v-spacer>
          <!-- 自社ポータル タブメニュー -->
          <v-tabs right v-model="active_tab">
            <v-tab
              v-for="val in HEADER_MENU_ITEMS_INHOUSE_ARRAY"
              :key="val.id"
              @change="(menu = val), onChange(val.id)"
              >{{ val.name }}</v-tab
            >
          </v-tabs>
    </v-app-bar>
    <Popup width="480px" :dialog="isShowSearchDialog">
      <FieldsSelectDialog
        title="現場選択"
        :formValues="formValues"
        @formUpdate="formUpdate"
        @onSearch="searchSite"
        @close="isShowSearchDialog = false"
      />
    </Popup>
  </div>
</template>

<script>
import { Store } from "@/store/Store.js";
import { getDateJp } from "@/utils/timeUtil"; //日付フォーマット変換で使用
import { HEADER_MENU_ITEMS_INHOUSE_ARRAY } from "@/constants/GLOBALHEADER";
import FieldsSelectDialog from "./components/FieldsSelectDialog";
import Popup from "@/components/common/Popup.vue";
import { COMPANY_TYPE } from "@/constants/COMMON";
const ASIGNED_FIELD_FLG = 1;

export default {
  data() {
    return {
      title: "RAKU2TENKEN",

      // タブメニュー表示項目
      HEADER_MENU_ITEMS_INHOUSE_ARRAY,

      // 選択中のタブメニューid
      active_tab: 0,

      // 検索データ
      formValues: {
        // 検索items
        items: [],
        // 検索ワード
        keyword: "",
        // 選択項目id
        selected: null,
      },

      // 検索ダイアログ表示
      isShowSearchDialog: false,
    };
  },

  components: {
    FieldsSelectDialog,
    Popup,
  },

  props: {
    companyId: Number,
  },

  async mounted() {
    /**
     * 選択しているメニューを取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getInHouseMenuId`],
      (menuId) => {
        this.active_tab = menuId;
      },
      {
        immediate: true,
      }
    );

    /**
     * 現場一覧の取得
     */
    this.$watch(
      () => Store.getters[`GlobalHeader/getSiteList`],
      (data) => {
        const siteList = [...data];
        this.formValues.items = this.getSiteByRole(siteList);
      },
      {
        immidiate: true,
        deep: true,
      }
    );
  },

  computed: {
    /**
     * 現在の現場を取得
     */
    CurrentSite() {
      return Store.getters["GlobalHeader/getCurrentSite"];
    },

    /**
     * Info company_type of user login
     */
    companyType() {
      return Store.getters[`Login/getUser`]?.company_type;
    },
  },

  methods: {
    /**
     * タブメニューの変更
     */
    onChange(menuId) {
      const selectedMenu = HEADER_MENU_ITEMS_INHOUSE_ARRAY.find((item) => {
        return item.id === menuId;
      });
      Store.dispatch("GlobalHeader/setInHouseMenu", { menuId: menuId });
      this.$router.push(selectedMenu.path);
    },

    // ドロワー
    openDrawer() {
      Store.dispatch("Drawer/show");
    },

    /**
     * 現場選択ダイアログを開く
     */
    async openSearchDialog() {
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword: "",
      });
      const { entries } = result.data.contents;
      if (result.hasError || entries.length === 0) {
        Store.dispatch("Error/show", {
          status: 200,
          message: "現場情報がありません",
        });
        return;
      }
      this.isShowSearchDialog = true;
    },

    /**
     * 現場検索
     */
    async searchSite() {
      const { keyword } = this.formValues;
      const result = await Store.dispatch("GlobalHeader/searchSites", {
        keyword,
      });
      if (result.hasError) {
        Store.dispatch("Error/show", {
          status: 200,
          message: result.data.message,
        });
      }
    },

    // フォームの変更を受け取る
    formUpdate(values) {
      this.formValues = { ...values };

      /**
       * 現場選択
       */
      if (this.formValues.selected) {
        // 現場idが選択されたらset
        Store.dispatch("GlobalHeader/setSite", {
          field_id: this.formValues.selected,
        });

        // ダイアログclose
        this.isShowSearchDialog = false;

        //リセット
        const formValues = {
          items: [],
          keyword: "",
          selected: null,
        };
        this.formValues = formValues;

        // 現場ポータルに遷移
        this.$router.push("../field/inspections");
      }
    },

    /**
     * 日付変換
     * @param date:yyyy/mm/dd（day）
     */
    getFormattedDate() {
      return getDateJp(new Date()).date;
    },
    /**
     * on click logo
     */
    onClickLogo() {
      this.$router.push("../master/machines");
    },

    /**
     * filter sites by role company
     */
    getSiteByRole(siteList) {
      if (this.companyType === COMPANY_TYPE.OWNER) {
        return siteList.filter(e => e?.asigned_field_flg === ASIGNED_FIELD_FLG);
      }
      return siteList;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/themes.scss";
@import "@/assets/scss/responsive.scss";

.portalHeader {
  .portalHeader-siteSelect {
    width: 224px;
    @include sm {
      width: 100%;
    }
  }
  .portalHeader-title {
    margin-left: 5%;
    display: flex;
    align-items: center;
    @include md {
      flex-direction: row;
      justify-content: center;
    }
    @include sm {
      flex-direction: row;
      justify-content: center;
    }
    @include xs {
      flex-direction: row;
      justify-content: center;
    }
  }
  .portalHeader-logo {
    height: 32px;
    @include md {
      height: 24px;
    }
    @include sm {
      height: 24px;
    }
    @include xs {
      height: 24px;
    }
  }
  .portalHeader-date {
    width: 120px;
    margin: 0 8px;
    font-size: 14px;
    white-space: nowrap;
    color: $color_primary_sub;
    @include sm {
      display: none;
    }
    @include xs {
      display: none;
    }
  }
  .portalHeader-selectSite {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .portalHeader-label{
    width: 150px;
    font-size : 18px;
    font-weight : bold;
    color: $color_primary_sub;
    @include sm {
      font-size: 12px;
      text-align: center;
    }
  }
}

.v-badge__badge {
  font-size: 9px !important;
}
.theme--light.v-badge .v-badge__badge:after {
  border-color: none !important;
}
.sites_name_wrapper {
  width: 400px;
  min-width: 72px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  text-indent: 4px;
  @include sm {
    width: 100%;
  }
}
</style>
