<template>
  <v-menu
    ref="menu"
    v-model="isShow"
    :close-on-content-click="false"
    transition="scroll-y-transition"
    offset-y
    min-width="auto"
    dense
  >
    <template v-slot:activator="{ on, attrs }">
      <v-row>
        <v-col>
          <ValidationProvider
            v-slot="{ errors, valid }"
            :vid="id"
            :name="validation_label"
            :rules="validation_rules"
          >
            <v-text-field
              v-model="computedDateFormatted"
              placeholder="日付を選択"
              readonly
              :disabled="!editable"
              v-bind="attrs"
              v-on="on"
              filled
              dense
              item-text="name"
              :success="valid"
              :error-messages="errors"
              :data-testid="testid"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
    <v-date-picker
      v-model="val"
      color="green"
      locale="ja"
      type="month"
      :day-format="(val) => new Date(val).getDate()"
      :first-day-of-week="0"
      no-title
      scrollable
      dense
      @change="onInput(val)"
    >
      <v-spacer></v-spacer>
      <v-btn text color="black" @click="onInput(new Date())">クリア</v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import { format, parseISO } from "date-fns";

export default {
  data: () => {
    return {
      val: "",
      isShow: false,
    };
  },
  components: {
    ValidationProvider,
  },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
    name: {
      type: String,
    },
    validation_rules: {
      type: String,
    },
    validation_label: {
      type: String,
    },
    testid: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  computed: {
    //表示用にyyyy/MM/ddに変換
    computedDateFormatted() {
      return this.val ? format(parseISO(this.val), "yyyy/MM") : "";
    },
  },
  mounted() {
    this.$watch(
      () => [this.values, this.name],
      (newValue) => {
        const formValues = newValue[0];
        const name = newValue[1];
        if (formValues && name && formValues[name]) {
          //v-datepickerがyyyy-MM形式で処理する
          const _date = new Date(formValues[name]);
          this.val = format(_date, "yyyy-MM");
        }
      },
      { immediate: true, deep: true }
    );
  },
  methods: {
    onInput(val) {
      this.$refs.menu.save(val);
      //保存用にyyyy/MMに変換
      const _val = format(new Date(val), "yyyy/MM");
      this.$emit("onInput", { name: this.name, value: _val });
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.check {
  min-width: 96px;
  flex-grow: 0;
}
</style>
