var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DefaultLayout',{scopedSlots:_vm._u([{key:"mainHeader",fn:function(){return [_c('SitePortalHeader')]},proxy:true},{key:"page",fn:function(ref){
var layoutParams = ref.layoutParams;
return [_c('TableLayout',{attrs:{"layoutParams":layoutParams,"hideFooter":_vm.searchParams.pageCount >= _vm.searchParams.total_item},scopedSlots:_vm._u([{key:"tableHeader",fn:function(ref){
var updateHeader = ref.updateHeader;
return [_c('TableHeader',{ref:"tableHeader",attrs:{"isAddNew":false,"isUpdate":true,"isDelete":false,"isRepresentative":true,"pageTitle":_vm.PAGE_TITLE,"multiRemoveStatus":_vm.disableRemoveBtn,"updateHeader":updateHeader,"isRuleRepresentative":_vm.IS_USER_FIELD},on:{"onApprove":_vm.onRepresentative,"onReject":_vm.onRepresentative,"openUpdateForm":_vm.openUpdateForm}},[_c('SearchFormWrapper',[_c('Label',{attrs:{"label":"氏名"}},[_c('InputText',{attrs:{"name":"word","editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}})],1),_c('Label',{attrs:{"label":"所属"}},[_c('Select',{attrs:{"name":"company_id","editable":true,"values":_vm.searchInputs,"items":_vm.COMPANY_LIST,"item_text":"name"},on:{"onInput":_vm.onChangeSearchInputs}})],1),_c('Label',{attrs:{"label":""}},[_c('TabSelect',{attrs:{"name":"role","items":_vm.FIELD_USER_ROLE,"editable":true,"values":_vm.searchInputs},on:{"onInput":_vm.onChangeSearchInputs}})],1),_c('Label',{attrs:{"label":""}},[_c('SwitchInput',{attrs:{"name":"representative_flag","label":"代表者のみ表示","values":_vm.searchInputs,"editable":true},on:{"onInput":_vm.onChangeSearchInputs}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"primary","depressed":""},on:{"click":_vm.onSearch}},[_vm._v(" 検索 ")])],1)],1),_c('TableSortWrapper',[_c('TableSort',{attrs:{"values":_vm.searchParams,"sort_items":_vm.SORT_ITEMS,"sort_item_text":"name","sort_item_value":"id","page_counts_options":_vm.PAGE_COUNT_OPTIONS,"sort_order_options":_vm.SORT_ORDERS,"total_item":_vm.searchParams.total_item},on:{"onInput":_vm.onChangeSortParams}})],1)]}},{key:"tableBody",fn:function(ref){
var tableHeight = ref.tableHeight;
return [_c('v-data-table',{staticClass:"elevation-0 v-data-table__wrapper",attrs:{"headers":_vm.TABLE_LABELS,"items":_vm.items,"items-per-page":_vm.searchParams.pageCount,"height":tableHeight,"fixed-header":"","hide-default-footer":"","disable-sort":"","sort-by":"updatedAt","show-select":"","noDataText":"データがありません。","item-key":"id"},scopedSlots:_vm._u([{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.handleRole(item.role))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td"},[_vm._v(" "+_vm._s(_vm.handleNameUser(item))+" ")])]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td"},[_vm._v(" "+_vm._s(item.company_name)+" ")])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cst-overflow-td-email"},[_vm._v(" "+_vm._s(item.email)+" ")])]}},{key:"item.representative_flag",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.handleRepresentative(item.representative_flag))+" ")]}},{key:"item.flag_check_past",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFlagCheckPast(item.flag_check_past))+" ")]}},{key:"item.btn_dot",fn:function(ref){
var item = ref.item;
return [(_vm.IS_MACHINE_MANAGER)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"menu-item"},[_c('SwitchInput',{attrs:{"name":"flag_check_past","label":"過去日の点検を許可","values":item,"editable":true},on:{"onInput":function($event){return _vm.onChangeFlagCheckPast($event, item)}}})],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})]}},{key:"tableFooter",fn:function(){return [_c('Pagination',{attrs:{"current":_vm.searchParams.currentPage,"total":_vm.searchParams.totalPage},on:{"pageUpdate":_vm.pageUpdate}})]},proxy:true}],null,true)})]}}])}),_c('Popup',{attrs:{"dialog":_vm.popups.isShowItemForm}},[_c('UpdateUserFieldForm',{ref:"UpdateUserFieldForm",on:{"updateChecked":_vm.updateChecked,"cancel":_vm.closeItemForm}})],1),_c('Popup',{attrs:{"width":"480px","dialog":_vm.popups.isShowConfirmDialog}},[_c('ConfirmRemoveDialog',{attrs:{"message":_vm.message},on:{"close":function($event){return _vm.closeRemoveDialog()},"yes":_vm.onConfirmRepresentative}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }