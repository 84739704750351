import Vue from "vue";
import Vuex from "vuex";
import { inspections } from "../../../api/modules/inspections";

Vue.use(Vuex);

const ENTITY = inspections;

export const Inspections = {
  namespaced: true,

  state: {
    data: [],
    pagination: {},
    isRelateField: 0,
    isMachineFieldManager: 0,
    // inspection_frequency: 0,
  },

  mutations: {
    SET_DATA(state, payload) {
      state.data = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    // SET_INSPECTION_FRE(state, payload) {
    //   state.inspection_frequency = payload
    // },
    SET_FLAG_RELATE_FIELD(state, payload) {
      state.isRelateField = payload;
    },
    SET_FLAG_MACHINE_FIELD_MANAGER(state, payload) {
      state.isMachineFieldManager = payload;
    },
  },

  actions: {
    async get({ commit }, payload) {
      const response = await ENTITY.get(payload);
      if (response.hasError) {
        return response;
      }
      const { entries, pagination, is_related_field, machine_field_manager_flg } = response.data.contents;
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      commit("SET_FLAG_RELATE_FIELD", is_related_field);
      commit("SET_FLAG_MACHINE_FIELD_MANAGER", machine_field_manager_flg);
      // commit("SET_INSPECTION_FRE", payload.inspection_frequency);
      return response;
    },

    async getListOtherCompany({ commit }, payload) {
      const response = await ENTITY.getListOtherCompany({ ...payload, inspection_frequency: 0 });
      if (response.hasError) {
        return response;
      }
      const { entries, pagination, is_related_field, machine_field_manager_flg } = response.data.contents;
      console.log(entries, pagination, is_related_field, machine_field_manager_flg)
      commit("SET_DATA", entries);
      commit("SET_PAGINATION", pagination);
      commit("SET_FLAG_RELATE_FIELD", is_related_field);
      commit("SET_FLAG_MACHINE_FIELD_MANAGER", machine_field_manager_flg);
      // commit("SET_INSPECTION_FRE", 3);
      return response;
    },
    async getByIds(_context, payload) {
      return await ENTITY.getByIds(payload);
    },
    async updateStatusByGroup(_context, payload) {
      return await ENTITY.updateStatusByGroup(payload);
    },
    async approvalSeries(_context, payload) {
      return await ENTITY.approvalSeries(payload);
    },
    async getResultDetail(_context, payload) {
      return await ENTITY.getResultDetail(payload);
    },
    async addLeadApproved(_context, payload) {
      return await ENTITY.addLeadApproved(payload);
    },
    async deleteInspector(_context, payload) {
      return await ENTITY.deleteInspector(payload);
    }
  },

  getters: {
    getData: (state) => {
      return state.data;
    },
    getPagination: (state) => {
      return state.pagination;
    },
    getFlagRelateField: (state) => {
      return state.isRelateField;
    },
    getFlagMachineFieldManager: (state) => {
      return state.isMachineFieldManager;
    },
    // getInspectionFrequency: (state) => {
    //   return state.inspection_frequency;
    // },
  },
};
